import React from 'react';

import { Button, CircularProgress, Typography } from '@material-ui/core';
import { Publish } from '@material-ui/icons';
import classNames from 'classnames';
import { pipe, withState } from 'commity-rehook-fork';
import { connect } from 'react-redux';
import { omit } from 'ramda';

import { withStylesAsClasses } from '../rehooks';
import { importFromCsv } from '../surveyModel/exportImportCsv';
import { getSurvey } from '../state/modules/survey/overview';
import { updateTranslation } from '../state/modules/survey/translations/index';
import { InfoDialog } from './dialogs/InfoDialog';

const styles = theme => ({
  root: {},
  toolbarIcons: {
    marginRight: theme.spacing.unit,
  },
  circularProgress: {
    textAlign: 'center',
    overflowX: 'hidden',
  },

  dialogContentMessages: {
    whiteSpace: 'pre-wrap',
  },

  menuItem: {
    textTransform: 'none',
    paddingRight: '12px',

    '&:hover': {
      borderRadius: 0,
    },
  },
});

const ImportCsvPure = ({ classes, survey, onTranslationChange, dialog, setDialog, ...others }) => {
  function handleChange(e) {
    setDialog({
      open: true,
      message: (
        <div className={classes.circularProgress}>
          <CircularProgress disableShrink />
        </div>
      ),
      showButton: false,
    });

    const file = e.target.files[0];
    if (!window.FileReader) return;

    const reader = new FileReader();

    reader.onload = evt => {
      if (evt.target.error || evt.target.readyState !== 2) {
        // eslint-disable-next-line no-alert
        alert('Unexpected import error');
        console.error(evt.target.error);
        return;
      }

      const message = importFromCsv(onTranslationChange, survey, evt.target.result);

      setDialog({
        open: true,
        message: (
          <div className={classes.dialogContent}>
            Import is complete
            <br />
            <br />
            {message && (
              <fieldset className={classes.dialogContentMessages}>
                <legend>Errors:</legend>
                <Typography variant="body2">{message}</Typography>
              </fieldset>
            )}
          </div>
        ),
        showButton: true,
      });
    };

    reader.readAsText(file);
  }

  return (
    <div {...others} className={classNames(classes.root, others.className)}>
      <input id="importCsv" type="file" accept=".csv" onChange={handleChange} style={{ display: 'none' }} />
      <label htmlFor="importCsv">
        <Button
          color="inherit"
          fullWidth
          className={classes.menuItem}
          component="span"
          aria-label="Import csv"
          title="Import csv">
          <Publish style={{ marginRight: '8px' }} /> Import translations
        </Button>
      </label>
      <InfoDialog
        dialogOpen={dialog.open}
        onDialogClose={() => setDialog({ open: false })}
        dialogTitle={'Import csv'}
        dialogContent={dialog.message}
        showButton={dialog.showButton}
      />
    </div>
  );
};

const mapStateToProps = state => ({
  survey: getSurvey(state),
});
const mapDispatchToProps = dispatch => {
  return {
    onTranslationChange: ({ path, value }) => {
      dispatch(updateTranslation(path, value));
    },
  };
};
const EditorWithStyles = pipe(
  withStylesAsClasses(styles),
  withState('dialog', 'setDialog', { open: false }),
  omit(['dispatch']),
  ImportCsvPure
);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditorWithStyles);
