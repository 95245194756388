import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import classNames from 'classnames';
import { withHandlers } from 'commity-rehook-fork';
import * as PropTypes from 'prop-types';
import { omit, pipe } from 'ramda';
import React from 'react';
import { withStylesAsClasses } from '../../rehooks';

const styles = theme => ({
  root: {},

  dialogTitle: {
    cursor: 'default',
    '& h6': {
      color: theme.palette.primary.main,
    },
  },

  dialogTitleContentText: {
    cursor: 'default',
  },
});

const InfoDialogPure = ({
  classes,
  handleClick,
  onDialogClose,
  dialogTitle,
  dialogContent,
  dialogOpen,
  dialogButtonText = 'OK',
  showButton = true,

  ...others
}) => {
  return (
    <div {...others} className={classNames(classes.root, others.className)}>
      <Dialog
        open={dialogOpen}
        onClose={onDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title" className={classes.dialogTitle}>
          {dialogTitle}
        </DialogTitle>
        <DialogContent>{dialogContent}</DialogContent>
        <DialogActions>
          {showButton && (
            <Button onClick={handleClick} color="primary">
              {dialogButtonText}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export const InfoDialog = pipe(
  withHandlers({
    handleClick: ({ onClick, onDialogClose }) => () => {
      onClick && onClick();
      onDialogClose();
    },
  }),
  withStylesAsClasses(styles),
  omit(['onClick']),
  InfoDialogPure
);

InfoDialog.propTypes = {
  dialogTitle: PropTypes.string,
  dialogContentText: PropTypes.string,
  dialogButtonText: PropTypes.string,
  onClick: PropTypes.func,
  dialogOpen: PropTypes.bool,
  onDialogClose: PropTypes.func.isRequired,
  showButton: PropTypes.bool,
};

InfoDialog.defaultProps = {
  dialogOpen: false,
  dialogTitle: 'Dialog title',
  dialogContent: '',
};
