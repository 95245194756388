import classNames from 'classnames';
import { pipe } from 'commity-rehook-fork';
import * as PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { Grid, IconButton, TextField, Typography } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { Rows } from '../../../../objectInspector/conditions/ConditionsAsString';
import { withStylesAsClasses } from '../../../../../rehooks';
import { omit } from 'ramda';
import { DraggableTypes, getDroppableClassName } from '../../../../dndHelper';
import { DropTarget, DragSource } from 'react-dnd';

const styles = (theme) => ({
  root: {
    border: '2px solid #4ba82e',
    borderRadius: 4,
    padding: 8,
    marginBottom: 4,
  },
  cursor: {
    cursor: 'move',
  },
  deleteButton: {
    float: 'right',
  },
  canDrop: {
    backgroundColor: theme.palette.background.dndCanDrop,
  },
  cannotDrop: {
    backgroundColor: theme.palette.background.dndCannotDrop,
  },
});

const ConditionRowPure = ({
  transformForEditor,
  condition,
  conditionalTextTranslate,
  text = '',
  index,
  openConditionModal,
  classes,
  connectDropTarget,
  connectDragSource,
  isOver,
  canDrop,
  removeConditionalText,
  ...others
}) => {
  const dropTargetClassName = getDroppableClassName(classes, isOver, canDrop);
  const onConditionalTextRemove = useCallback(() => removeConditionalText(index), [index, removeConditionalText]);

  return connectDropTarget(
    <div>
      <Grid
        container
        direction="row"
        alignItems="center"
        {...others}
        className={classNames(classes.root, others.className, dropTargetClassName)}>
        <Grid item xs>
          {connectDragSource(<div className={classes.cursor}>:::</div>)}
        </Grid>

        <Grid item xs={3}>
          {index + 1}: {index === 0 ? 'Check' : 'else check'} if{' '}
          {condition ? (
            <Rows
              conditions={transformForEditor({
                visibleIf: condition,
              })}
            />
          ) : (
            <Typography>No conditions</Typography>
          )}
        </Grid>
        <Grid item xs>
          <IconButton
            aria-label="Edit condition text"
            title="Edit condition text"
            onClick={openConditionModal(
              {
                visibleIf: condition,
              },
              index
            )}>
            <EditIcon />
          </IconButton>
        </Grid>
        <Grid item xs={2}>
          and show text
        </Grid>
        <Grid item xs={5}>
          <TextField
            label="Text"
            placeholder="... insert outro text"
            value={text}
            onChange={conditionalTextTranslate(index)}
            fullWidth
            multiline
          />
        </Grid>
        <Grid item xs>
          <IconButton
            className={classes.deleteButton}
            aria-label="Delete condition text"
            title="Delete condition text"
            onClick={onConditionalTextRemove}>
            <DeleteIcon />
          </IconButton>
        </Grid>
      </Grid>
    </div>
  );
};

const droppableTarget = {
  drop: (props, monitor) => {
    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.index;

    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      return;
    }

    props.moveConditionalText(dragIndex, hoverIndex);
  },
};

const droppableCollect = (dndConnect, monitor) => {
  return {
    connectDropTarget: dndConnect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop(),
  };
};

const draggableSource = {
  beginDrag({ index, draggableRoot, item }) {
    return {
      index,
      draggableRoot,
      item,
    };
  },
};

const draggableCollect = (dndConnect, monitor) => {
  return {
    connectDragSource: dndConnect.dragSource(),
    isDragging: monitor.isDragging(),
  };
};

const StyledConditionRow = pipe(
  withStylesAsClasses(styles),
  omit(['draggableRoot', 'isDragging', 'moveConditionalText']),
  ConditionRowPure
);

export const ConditionRow = DropTarget(
  DraggableTypes.CONDITION_ROW,
  droppableTarget,
  droppableCollect
)(DragSource(DraggableTypes.CONDITION_ROW, draggableSource, draggableCollect)(StyledConditionRow));

ConditionRow.propTypes = {
  condition: PropTypes.shape({}),
  text: PropTypes.string,
  index: PropTypes.number,
  conditionalTextTranslate: PropTypes.func,
  transformForEditor: PropTypes.func,
  openConditionModal: PropTypes.func,
  moveConditionalText: PropTypes.func,
  removeConditionalText: PropTypes.func,
};
