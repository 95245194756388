import { Switch, TextField, Typography } from '@material-ui/core';
import classNames from 'classnames';
import { mapProps, pipe, withHandlers } from 'commity-rehook-fork';
import * as PropTypes from 'prop-types';
import { map, omit, path, pathOr, propOr } from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { withStylesAsClasses } from '../../../rehooks';
import { getSelectedItemPath } from '../../../state/modules/editor';
import {
  getBeforeExitQuestion,
  getFlatSurvey,
  getSelectedItem,
  toggleBeforeExitQuestion,
  updateItem,
} from '../../../state/modules/survey/overview';
import { PATHS } from '../../../state/modules/survey/paths';
import {
  getSelectedTranslationItem,
  getTranslationPathForItem,
  updateTranslation,
} from '../../../state/modules/survey/translations';
import { createTranslationOnChangeHandler } from '../../handlerHelper';
import { getQuestionsAfterQuestionId } from '../../questionHelper';
import { QuestionSelect } from '../../QuestionSelect';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },

  componentPadding: {
    padding: theme.spacing.unit,
  },

  beforeExitQuestionContainer: {
    display: 'flex',
    padding: theme.spacing.unit,
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  questionContainer: {
    paddingBottom: theme.spacing.unit * 2,
  },

  agreementTitle: {
    marginTop: theme.spacing.unit * 2,
  },

  questionSelect: {
    paddingRight: theme.spacing.unit,
    flex: 1,
  },
});

const PersonalAgreementOIPure = ({
  item,
  translationItem,
  onContinueTextChange,
  onTerminateTextChange,
  onContinueButtonTextChange,
  onTerminateButtonTextChange,
  onAgreementTextChange,
  onAgreementErrorMessageChange,
  modelPath,
  classes,
  readOnly,
  onBeforeExitToggle,
  beforeExitQuestion,
  onBeforeExitQuestionSelect,
  options,
  ...others
}) => {
  return (
    <div {...others} className={classNames(classes.root, others.className)}>
      <TextField
        id="continueText"
        label="Continue Text"
        placeholder="..."
        multiline
        value={propOr('', 'continueText', translationItem)}
        onChange={onContinueTextChange}
        margin="normal"
        disabled={readOnly}
      />
      <TextField
        id="continueButtonText"
        label="Continue Button Text"
        placeholder="..."
        multiline
        value={propOr('', 'continueButtonText', translationItem)}
        onChange={onContinueButtonTextChange}
        margin="normal"
        disabled={readOnly}
      />
      <TextField
        id="terminateButtonText"
        label="Terminate Button Text"
        placeholder="..."
        multiline
        value={propOr('', 'terminateButtonText', translationItem)}
        onChange={onTerminateButtonTextChange}
        margin="normal"
        disabled={readOnly}
      />

      <Typography variant="h6" className={classes.agreementTitle}>
        Agreement
      </Typography>
      <TextField
        id="agreementText"
        label="Agreement Text"
        placeholder="..."
        multiline
        value={pathOr('', ['agreement', 'text'], translationItem)}
        onChange={onAgreementTextChange}
        margin="normal"
        disabled={readOnly}
      />
      <TextField
        id="agreementErrorMessage"
        label="Agreement Error Message"
        placeholder="..."
        multiline
        value={pathOr('', ['agreement', 'errorMessage'], translationItem)}
        onChange={onAgreementErrorMessageChange}
        margin="normal"
        disabled={readOnly}
      />
      <div className={classes.beforeExitQuestionContainer}>
        <Typography variant="h6">Before exit question</Typography>
        <Switch title="Before exit question" checked={beforeExitQuestion} onChange={onBeforeExitToggle(modelPath)} />
      </div>
      <div className={classes.questionContainer}>
        <Typography variant="subtitle2" className={classes.componentPadding}>
          Set Before Exit Question:
        </Typography>
        <QuestionSelect
          onSelect={onBeforeExitQuestionSelect(modelPath)}
          options={options}
          disabled={!beforeExitQuestion}
          value={createSelectedBeforeQuestion({ id: path(PATHS.beforeExitQuestion, item) })}
          className={classes.questionSelect}
        />
      </div>
    </div>
  );
};
const questionsWithoutSelectedItem = state =>
  getQuestionsAfterQuestionId(getSelectedItem(state).id, getFlatSurvey(state));
const createSelectedBeforeQuestion = question => ({ label: question.id, value: question.id, type: 'value' });

const PersonalAgreementOIWithStyles = pipe(
  mapProps(({ questions, ...others }) => ({
    options: map(createSelectedBeforeQuestion, questions),
    ...others,
  })),

  withHandlers({
    onContinueTextChange: createTranslationOnChangeHandler(['continueText']),
    onContinueButtonTextChange: createTranslationOnChangeHandler(['continueButtonText']),
    onTerminateButtonTextChange: createTranslationOnChangeHandler(['terminateButtonText']),
    onAgreementTextChange: createTranslationOnChangeHandler(['agreement', 'text']),
    onAgreementErrorMessageChange: createTranslationOnChangeHandler(['agreement', 'errorMessage']),
  }),
  withStylesAsClasses(styles),
  omit(['translationPath', 'onTranslationChange']),
  PersonalAgreementOIPure
);

const mapStateToProps = (state, { item }) => {
  return {
    beforeExitQuestion: getBeforeExitQuestion(state),
    modelPath: getSelectedItemPath(state),
    translationItem: getSelectedTranslationItem(state),
    translationPath: getTranslationPathForItem(state, item),
    questions: questionsWithoutSelectedItem(state),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onBeforeExitToggle: modelPath => (event, checked) => {
      dispatch(toggleBeforeExitQuestion(checked, modelPath));
    },
    onBeforeExitQuestionSelect: modelPath => ({ value }) => {
      dispatch(updateItem([...modelPath, ...PATHS.beforeExitQuestion], value));
    },
    onTranslationChange: ({ translationPath, value }) => {
      dispatch(updateTranslation(translationPath, value));
    },
  };
};

export const PersonalAgreementOI = connect(
  mapStateToProps,
  mapDispatchToProps
)(PersonalAgreementOIWithStyles);

PersonalAgreementOI.propTypes = {
  readOnly: PropTypes.bool,
};
