import { Typography } from '@material-ui/core';
import { pipe } from 'commity-rehook-fork';
import React from 'react';
import classNames from 'classnames';
import { memoizeWithEquals, withStylesAsClasses } from '../../rehooks';

const styles = (theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    fontStyle: 'italic',
    cursor: 'default',
  },
});

const QuestionFromMasterNotePure = ({ classes, ...others }) => {
  return (
    <div className={classNames(classes.root, others.className)}>
      <Typography variant="subtitle2" color="error">
        note: Question is from Master. You can change texts in Translation View.
      </Typography>
    </div>
  );
};

export const QuestionFromMasterNote = pipe(withStylesAsClasses(styles), memoizeWithEquals(QuestionFromMasterNotePure));
