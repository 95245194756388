import { Typography } from '@material-ui/core';
import classNames from 'classnames';
import { pipe } from 'commity-rehook-fork';
import * as PropTypes from 'prop-types';
import React from 'react';
import { memoize, withStylesAsClasses } from '../../../rehooks';
import { TranslationProperty } from './TranslationProperty';

const styles = theme => ({
  root: {
    border: theme.primaryBorder.border,
    borderRadius: theme.primaryBorder.borderRadius,
    margin: theme.spacing.unit,
    padding: theme.spacing.unit,
    display: 'flex',
    flexDirection: 'column',
  },
});

/*
redirect: {
    text: "I ${seconds} seconds you will be redirected to google.com ${linkText}", // text
    linkText: "click here to redirect to google.com" // text
  },
*/

const TranslationRedirectPure = ({
  propertyLabel,
  translationPath,
  sourcePropertyValue: sourceRedirect = {},
  targetPropertyValue: targetRedirect = {},
  classes,
  ...others
}) => {
  return (
    <div {...others} className={classNames(classes.root, others.className)}>
      <Typography variant="subtitle1">{propertyLabel}</Typography>
      <TranslationProperty
        propertyLabel="Redirect Text"
        translationPath={[...translationPath, 'text']}
        sourcePropertyValue={sourceRedirect.text}
        targetPropertyValue={targetRedirect.text}
      />
      <TranslationProperty
        propertyLabel="Redirect link text"
        translationPath={[...translationPath, 'linkText']}
        sourcePropertyValue={sourceRedirect.linkText}
        targetPropertyValue={targetRedirect.linkText}
      />
    </div>
  );
};

export const TranslationRedirect = pipe(
  withStylesAsClasses(styles),
  memoize(TranslationRedirectPure)
);

TranslationRedirect.propTypes = {
  propertyLabel: PropTypes.string.isRequired,
  translationPath: PropTypes.array.isRequired,
  sourcePropertyValue: PropTypes.object,
  targetPropertyValue: PropTypes.object,
};
