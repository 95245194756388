import { cond, F, filter, lensPath, lensProp, map, over, pipe, propOr, reduce, T, view, when } from 'ramda';
import { flatQuestionTreeWithPaths } from 'xperience-model-management';

const definitionLens = lensProp('definition');

const namesToFix = {
  lessThen: 'lessThan',
  lessThenOrEqual: 'lessThanOrEqual',
  greaterThen: 'greaterThan',
  greaterThenOrEqual: 'greaterThanOrEqual',
  olderThen: 'olderThan',
};

const hasVisibleIf = propOr(false, 'visibleIf');
const hasHideIf = propOr(false, 'hideIf');

const getConditionPath = cond([
  [hasVisibleIf, ({ path }) => [...path, 'visibleIf']],
  [hasHideIf, ({ path }) => [...path, 'hideIf']],
  [T, F],
]);

const getPathsToProcess = pipe(
  flatQuestionTreeWithPaths,
  map(getConditionPath),
  filter(Boolean)
);

const operationLens = lensProp('operation');
const argumentsLens = lensProp('arguments');

const fixNameIsNeeded = operation => namesToFix[operation] || operation;

const processCondition = condition =>
  pipe(
    over(operationLens, fixNameIsNeeded),
    when(view(argumentsLens), over(argumentsLens, map(processCondition)))
  )(condition);

const processOperations = (definition, path) => over(lensPath(path), processCondition, definition);

export const processConditions = definition => {
  const paths = getPathsToProcess(definition);

  return reduce(processOperations, definition, paths);
};

export const fixConditionOperationNames = survey => over(definitionLens, processConditions, survey);
