import { SnackbarContent } from '@material-ui/core';
import amber from '@material-ui/core/colors/amber';
import green from '@material-ui/core/colors/green';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
import classNames from 'classnames';
import * as PropTypes from 'prop-types';
import { pipe } from 'ramda';
import React from 'react';
import { withStylesAsClasses } from '../rehooks';

// todo do thema

const styles = theme => ({
  root: {},
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.dark,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing.unit,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
});

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const AlertSnackbarContentPure = ({ classes, variant, message, onClose }) => {
  const Icon = variantIcon[variant];
  return (
    <>
      <SnackbarContent
        className={classes[variant]}
        aria-describedby="client-snackbar"
        message={
          <span id="client-snackbar" className={classes.message}>
            <Icon className={classNames(classes.icon, classes.iconVariant)} />
            {message}
          </span>
        }
        action={[
          <IconButton key="close" aria-label="Close" color="inherit" className={classes.close} onClick={onClose}>
            <CloseIcon className={classes.icon} />
          </IconButton>,
        ]}
      />
    </>
  );
};

const AlertSnackbarContent = pipe(
  withStylesAsClasses(styles),
  AlertSnackbarContentPure
);

const AlertSnackbarPure = ({ classes, anchorOrigin, message, variant, snackbarOpen, closeSnackbar, ...others }) => {
  return (
    <div {...others} className={classNames(classes.root, others.className)}>
      <Snackbar
        anchorOrigin={anchorOrigin}
        open={snackbarOpen}
        ClickAwayListenerProps={{ onClickAway: () => false }}
        autoHideDuration={5000}
        onClose={closeSnackbar}>
        <AlertSnackbarContent variant={variant} onClose={closeSnackbar} message={message} />
      </Snackbar>
    </div>
  );
};

export const AlertSnackbar = pipe(
  withStylesAsClasses(styles),
  AlertSnackbarPure
);

AlertSnackbar.propTypes = {
  closeSnackbar: PropTypes.func.isRequired,
  snackbarOpen: PropTypes.bool.isRequired,
};
