import createReducer from 'create-reducer';
import { assoc, dissoc, hasPath, isEmpty, path, pathOr, pipe } from 'ramda';
import { combineEpics, ofType } from 'redux-observable';
import { map } from 'rxjs/operators';
import { flatQuestionTreeWithPaths } from 'xperience-model-management';
import { getSelectedCurrentLanguage, getSelectedItemPath, selectItem } from '../../editor';
import { getPathWithRoot, PATHS } from '../paths';
import {
  addNewChoiceHandler,
  addNewQuestionHandler,
  addNewQuestionToPageHandler,
  addQuestionFromMaster,
  deleteItemHandler,
  changeQuestionTypeHandler,
  initializeHandler,
  initializeMasterHandler,
  initializeSurveyHandler,
  moveChoiceHandler,
  moveQuestionHandler,
  showLanguageSwitchHandler,
  toggleAutofocusHandler,
  toggleAutoNextNavigationHandler,
  toggleBeforeExitQuestionHandler,
  toggleHiddenIntroHandler,
  toggleMandatoryHandler,
  updateDynamicSquareCountValueHandler,
  updateChoiceValueHandler,
  updateItemHandler,
  updateItemIdHandler,
  updateOptionalQuestionHandler,
  updateTextFieldRowsValueHandler,
  toggleSetupOfRedirect,
  appendConditionalTextHandler,
  updateConditionalTextHandler,
  moveConditionalTextHandler,
  removeConditionalTextHandler,
} from './handlers';

export const INITIALIZE = 'survey/overview/initialize';
export const INITIALIZE_SURVEY = 'survey/overview/initializeSurvey';
export const INITIALIZE_MASTER = 'survey/overview/initializeMaster';
export const UPDATE_ITEM = 'survey/overview/updateItem';
const CHANGE_QUESTION_TYPE = 'survey/overview/changeQuestionType';
const DELETE_ITEM = 'survey/overview/deleteItem';
const ADD_NEW_CHOICE = 'survey/overview/addNewChoice';
const ADD_NEW_QUESTION_TO_PAGE = 'survey/overview/addNewQuestionToPage';
const ADD_QUESTION_FROM_MASTER = 'survey/overview/addNewQuestionFromMaster';
const ADD_NEW_QUESTION = 'survey/overview/addNewQuestion';
const MOVE_QUESTION = 'survey/overview/moveQuestion';
const MOVE_CHOICE = 'survey/overview/moveChoice';
const TOGGLE_MANDATORY = 'survey/toggleMandatory';
const TOGGLE_BEFORE_EXIT_QUESTION = 'survey/toggleBeforeExitQuestion';
const UPDATE_ITEM_ID = 'survey/updateItemId';
const UPDATE_CHOICE_VALUE = 'survey/updateChoiceValue';
const TOGGLE_AUTO_NEXT_NAVIGATION = 'survey/toggleAutoNextNavigation';
const TOGGLE_AUTOFOCUS = 'survey/toggleAutofocus';
const UPDATE_TEXT_FIELD_ROWS_VALUE = 'survey/updateTextFieldRowsValue';
const UPDATE_OPTIONAL_QUESTION = 'survey/updateOptionalQuestion';
const UPDATE_DYNAMIC_SQUARE_COUNT_VALUE = 'survey/updateDynamicSquareCountValue';
const TOGGLE_HIDDEN_INTRO = 'survey/hiddenIntro';
const CHANGE_SHOW_LANGUAGE_SWITCH = 'survey/changeShowLanguageSwitch';
const TOGGLE_REDIRECT_OUTRO = 'survey/toggleRedirectOutro';
const UPDATE_REDIRECT_INPUT = 'survay/updateRedirectInput';
const APPEND_CONDITIONAL_TEXT = 'survey/appendConditionalText';
const UPDATE_CONDITIONAL_TEXT = 'survey/updateConditionalText';
const MOVE_CONDITIONAL_TEXT = 'survey/moveConditionalText';
const REMOVE_CONDITIONAL_TEXT = 'survey/removeConditionalText';
const UPDATE_VISUAL_TYPE = 'survey/updateVisualType';

export const initialize = (surveyDefinition, masterDefinition, placeholders, guid) => ({
  type: INITIALIZE,
  surveyDefinition,
  masterDefinition,
  placeholders,
  guid,
});

export const initializeMaster = (placeholders, guid) => ({
  type: INITIALIZE_MASTER,
  surveyDefinition: {
    definition: [
      {
        id: 'intro',
        type: 'intro',
        imagePath: {
          type: 'variable',
          value: 'carImage',
        },
        optional: true,
      },
      { id: 'outro', type: 'outro' },
    ],
    translations: {
      en_US: {
        intro: {
          text: '',
          agreement: {
            text: '',
            errorMessage: '',
          },
          introduction: '',
          startButtonText: 'START!',
        },
        outro: { text: '' },
      },
    },
  },
  placeholders,
  guid,
});

export const initializeSurvey = (surveyDefinition, masterDefinition, placeholders, guid, language) => ({
  type: INITIALIZE_SURVEY,
  surveyDefinition: {
    definition: [],
    translations: {},
  },
  masterDefinition,
  placeholders,
  guid,
  language,
});

export const updateItem = (itemPath, value) => ({
  type: UPDATE_ITEM,
  itemPath,
  value,
});

export const updateItemId = (itemPath, newValue) => ({
  type: UPDATE_ITEM_ID,
  itemPath,
  newValue,
});

export const updateChoiceValue = (modelPath, itemId, newValue) => ({
  type: UPDATE_CHOICE_VALUE,
  modelPath,
  itemId,
  newValue,
});

export const changeQuestionType = (itemPath, newType, currentLanguage) => ({
  type: CHANGE_QUESTION_TYPE,
  itemPath,
  newType,
  currentLanguage,
});

export const deleteItem = (itemPath, translationPath, parentPageQuestionPath) => {
  return {
    type: DELETE_ITEM,
    itemPath,
    translationPath,
    parentPageQuestionPath,
  };
};

export const addNewChoice = (modelPath, value) => ({
  type: ADD_NEW_CHOICE,
  modelPath,
  value,
});

export const addNewQuestion = (questionType, isMaster) => {
  return {
    type: ADD_NEW_QUESTION,
    questionType,
    isMaster,
  };
};

export const addNewQuestionFromMaster = (question, masterTranslations) => ({
  type: ADD_QUESTION_FROM_MASTER,
  question,
  masterTranslations,
});

export const moveQuestion = (result) => ({
  type: MOVE_QUESTION,
  result,
});

export const moveChoice = (result) => ({
  type: MOVE_CHOICE,
  result,
});

export const addNewQuestionToPage = (modelPath, parentPageQuestionPath, isMaster) => ({
  type: ADD_NEW_QUESTION_TO_PAGE,
  modelPath,
  parentPageQuestionPath,
  isMaster,
});

export const toggleMandatory = (item, selectedItemPath) => {
  return {
    type: TOGGLE_MANDATORY,
    item,
    selectedItemPath,
  };
};

export const toggleBeforeExitQuestion = (beforeExitQuestion, itemPath) => {
  return {
    type: TOGGLE_BEFORE_EXIT_QUESTION,
    beforeExitQuestion,
    itemPath,
  };
};

export const toggleHiddenIntro = (hiddenIntro, itemPath) => {
  return {
    type: TOGGLE_HIDDEN_INTRO,
    hiddenIntro,
    itemPath,
  };
};

export const toggleAutoNextNavigation = (navigationAutoNext) => {
  return {
    type: TOGGLE_AUTO_NEXT_NAVIGATION,
    navigationAutoNext,
  };
};
export const changeShowLanguageSwitch = (showLanguageSwitch) => {
  return {
    type: CHANGE_SHOW_LANGUAGE_SWITCH,
    showLanguageSwitch,
  };
};

export const toggleAutofocus = (autofocus, itemPath) => {
  return {
    type: TOGGLE_AUTOFOCUS,
    autofocus,
    itemPath,
  };
};

export const updateTextFieldRowsValue = (rowsValue, itemPath) => {
  return {
    type: UPDATE_TEXT_FIELD_ROWS_VALUE,
    rowsValue,
    itemPath,
  };
};

export const updateDynamicSquareCountValue = (rowsValue, itemPath) => {
  return {
    type: UPDATE_DYNAMIC_SQUARE_COUNT_VALUE,
    rowsValue,
    itemPath,
  };
};

export const updateOptionalQuestion = (checked, itemPath) => {
  return {
    type: UPDATE_OPTIONAL_QUESTION,
    checked,
    itemPath,
  };
};

export const updateVisualType = (value, itemPath) => {
  return {
    type: UPDATE_VISUAL_TYPE,
    value,
    itemPath,
  };
};

export const setRedirect = (isRedirect, itemPath) => {
  return {
    type: TOGGLE_REDIRECT_OUTRO,
    isRedirect,
    itemPath,
  };
};

export const updateRedirectInputs = (value, itemPath) => {
  return {
    type: UPDATE_REDIRECT_INPUT,
    value,
    itemPath,
  };
};

export const appendConditionalText = (itemPath) => {
  return {
    type: APPEND_CONDITIONAL_TEXT,
    itemPath,
  };
};

export const updateConditionalText = (itemPath, index, condition) => {
  return {
    type: UPDATE_CONDITIONAL_TEXT,
    itemPath,
    index,
    condition,
  };
};

export const moveConditionalText = (itemPath, dragIndex, hoverIndex) => {
  return {
    type: MOVE_CONDITIONAL_TEXT,
    itemPath,
    dragIndex,
    hoverIndex,
  };
};
export const removeConditionalText = (itemPath, index) => {
  return {
    type: REMOVE_CONDITIONAL_TEXT,
    itemPath,
    index,
  };
};

const handlers = {
  [INITIALIZE]: initializeHandler,
  [INITIALIZE_SURVEY]: initializeSurveyHandler,
  [INITIALIZE_MASTER]: initializeMasterHandler,
  [UPDATE_ITEM]: updateItemHandler,
  [CHANGE_QUESTION_TYPE]: changeQuestionTypeHandler,
  [DELETE_ITEM]: deleteItemHandler,
  [ADD_NEW_CHOICE]: addNewChoiceHandler,
  [ADD_NEW_QUESTION]: addNewQuestionHandler,
  [ADD_NEW_QUESTION_TO_PAGE]: addNewQuestionToPageHandler,
  [ADD_QUESTION_FROM_MASTER]: addQuestionFromMaster,
  [MOVE_QUESTION]: moveQuestionHandler,
  [MOVE_CHOICE]: moveChoiceHandler,
  [TOGGLE_MANDATORY]: toggleMandatoryHandler,
  [UPDATE_ITEM_ID]: updateItemIdHandler,
  [UPDATE_CHOICE_VALUE]: updateChoiceValueHandler,
  [TOGGLE_AUTO_NEXT_NAVIGATION]: toggleAutoNextNavigationHandler,
  [TOGGLE_BEFORE_EXIT_QUESTION]: toggleBeforeExitQuestionHandler,
  [TOGGLE_AUTOFOCUS]: toggleAutofocusHandler,
  [UPDATE_TEXT_FIELD_ROWS_VALUE]: updateTextFieldRowsValueHandler,
  [UPDATE_OPTIONAL_QUESTION]: updateOptionalQuestionHandler,
  [UPDATE_DYNAMIC_SQUARE_COUNT_VALUE]: updateDynamicSquareCountValueHandler,
  [TOGGLE_HIDDEN_INTRO]: toggleHiddenIntroHandler,
  [CHANGE_SHOW_LANGUAGE_SWITCH]: showLanguageSwitchHandler,
  [TOGGLE_REDIRECT_OUTRO]: toggleSetupOfRedirect,
  [UPDATE_REDIRECT_INPUT]: updateItemHandler,
  [APPEND_CONDITIONAL_TEXT]: appendConditionalTextHandler,
  [UPDATE_CONDITIONAL_TEXT]: updateConditionalTextHandler,
  [MOVE_CONDITIONAL_TEXT]: moveConditionalTextHandler,
  [REMOVE_CONDITIONAL_TEXT]: removeConditionalTextHandler,
  [UPDATE_VISUAL_TYPE]: updateItemHandler,
};

export default createReducer(handlers, () => []);

const selectItemEpic = (action$, state$) =>
  action$.pipe(
    ofType(ADD_NEW_QUESTION),
    // TODO refactor -> new item -> new with new id -> select by id
    map(() => selectItem([getSurveyDefinition(state$.value).length - 2]))
  );

export const epics = combineEpics(selectItemEpic);

export const getSurveyDefinition = path(getPathWithRoot(PATHS.surveyDefinition));

export const getSurvey = path(getPathWithRoot([]));

export const getSurveyWithSelectedTranslations = (state) => {
  const selectedTranslation = getSelectedCurrentLanguage(state);
  const createTranslation = (survey) => {
    return assoc('currentTranslation', path(['translations', selectedTranslation], survey), survey);
  };

  return pipe(getSurvey, createTranslation, dissoc('translations'))(state);
};

export const getFlatSurvey = (state) => flatQuestionTreeWithPaths(path(getPathWithRoot(PATHS.surveyDefinition), state));

export const getSelectedItem = (state) => {
  const selectedItemPath = getSelectedItemPath(state);
  if (isEmpty(selectedItemPath)) {
    return null;
  }
  return path(getPathWithRoot([...PATHS.surveyDefinition, ...selectedItemPath]), state);
};

export const getAutoNextNavigation = (state) => !hasPath(getPathWithRoot(PATHS.navigationAutoNext), state);
export const getShowLanguageSwitch = (state) => path(getPathWithRoot(PATHS.showLanguageSwitch), state);
export const getBeforeExitQuestion = (state) => {
  const selectedItemPath = getSelectedItemPath(state);
  if (isEmpty(selectedItemPath)) {
    return false;
  }
  return hasPath(getPathWithRoot([...PATHS.surveyDefinition, ...selectedItemPath, ...PATHS.beforeExitQuestion]), state);
};
export const getHiddenIntro = (state) => {
  const selectedItemPath = getSelectedItemPath(state);
  if (isEmpty(selectedItemPath)) {
    return false;
  }
  return hasPath(getPathWithRoot([...PATHS.surveyDefinition, ...selectedItemPath, ...PATHS.hiddenIntro]), state);
};

export const getIsRedirect = (state) => {
  const selectedItemPath = getSelectedItemPath(state);
  if (isEmpty(selectedItemPath)) {
    return false;
  }
  return hasPath(getPathWithRoot([...PATHS.surveyDefinition, ...selectedItemPath, ...PATHS.isRedirect]), state);
};

export const getAutofocus = (state) => {
  const selectedItemPath = getSelectedItemPath(state);
  return pathOr(true, getPathWithRoot([...PATHS.surveyDefinition, ...selectedItemPath, ...PATHS.autofocus]), state);
};

export const getOptionalQuestion = (state) => {
  const selectedItemPath = getSelectedItemPath(state);
  return pathOr(
    false,
    getPathWithRoot([...PATHS.surveyDefinition, ...selectedItemPath, ...PATHS.optionalQuestion]),
    state
  );
};

export const getTextFieldRowsValue = (state) => {
  const selectedItemPath = getSelectedItemPath(state);
  return pathOr(0, getPathWithRoot([...PATHS.surveyDefinition, ...selectedItemPath, ...PATHS.textFieldRows]), state);
};

export const getDynamicSquareCountValue = (state) => {
  const selectedItemPath = getSelectedItemPath(state);
  return pathOr(
    0,
    getPathWithRoot([...PATHS.surveyDefinition, ...selectedItemPath, ...PATHS.dynamicSquareCount]),
    state
  );
};
