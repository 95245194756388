import classNames from 'classnames';
import { pipe } from 'ramda';
import React from 'react';
import { memoize, withStylesAsClasses } from '../rehooks';
import { TranslationOverview } from './translations/TranslationOverview';
import { TranslationToolbar } from './translations/TranslationToolbar';

const styles = theme => ({
  root: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    height: '100%',
  },

  translationOverview: {
    display: 'flex',
    flexDirection: 'column',
    height: `calc(100% - ${theme.translationToolbarHeight}px)`,
  },
});

const TranslationEditorPure = ({ classes, ...others }) => {
  return (
    <div {...others} className={classNames(classes.root, others.className)}>
      <TranslationToolbar />
      <TranslationOverview className={classes.translationOverview} />
    </div>
  );
};

export const TranslationEditor = pipe(
  withStylesAsClasses(styles),
  memoize(TranslationEditorPure)
);

TranslationEditor.propTypes = {};
