import { IconButton, Paper, Typography } from '@material-ui/core';
import { Delete } from '@material-ui/icons/index';
import classNames from 'classnames';
import { pipe, withHandlers, withStateHandlers } from 'commity-rehook-fork';
import { AlphaMcircleOutline } from 'mdi-material-ui';
import * as PropTypes from 'prop-types';
import { omit } from 'ramda';
import React from 'react';
import { DragSource, DropTarget } from 'react-dnd';
import { connect } from 'react-redux';
import { isEscape, isIntro, isOutro, isPage } from 'xperience-model-management';
import { memoize, withStylesAsClasses } from '../../rehooks';
import { getFlattenMasterQuestions, selectItem } from '../../state/modules/editor';
import { deleteItem, moveQuestion } from '../../state/modules/survey/overview';
import { getTranslationPathForItem } from '../../state/modules/survey/translations';
import { DraggableTypes, getDroppableClassName } from '../dndHelper';
import { createOnDeleteHandler } from '../handlerHelper';
import { isQuestionInSurvey } from '../questionHelper';

const styles = (theme) => ({
  root: {},

  dndPaper: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing.unit,
    minHeight: 42,
    backgroundColor: theme.palette.background.primary,
    cursor: 'pointer',
    marginTop: theme.spacing.unit / 2,
  },

  hover: {
    '&:hover': {
      backgroundColor: theme.palette.background.highlight,
    },
  },

  dragHandleContainer: {
    marginRight: theme.spacing.unit / 2,
    userSelect: 'none',
    width: 20,
    paddingBottom: theme.spacing.unit / 2,
  },

  dragHandleIcon: {
    cursor: 'move',
  },

  id: {
    userSelect: 'none',
    paddingRight: theme.spacing.unit,
  },

  text: {
    userSelect: 'none',
    flexGrow: 1,
  },

  masterIcon: {
    display: 'flex',
    color: theme.palette.primary.A900,
    margin: `0 ${theme.spacing.unit / 2}px 0 ${theme.spacing.unit / 2}px`,
  },

  deleteIcon: {
    margin: 0,
    padding: 0,

    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.background.warning,
    },
  },

  deleteIconHidden: {
    margin: 0,
    padding: 0,
    visibility: 'hidden',
  },

  canDrop: {
    backgroundColor: theme.palette.background.dndCanDrop,
  },
  cannotDrop: {
    backgroundColor: theme.palette.background.dndCannotDrop,
  },
});

const ItemHeaderPure = ({
  classes,
  item,
  fromMaster,
  translationItem,
  onClick,
  onDeleteClick,
  showIconPanel,
  hideIconPanel,
  iconPanelVisible,
  iconPanel,
  parentPageModelPath,
  isSelected,
  dragHandleProps,
  dragAndDropEnabled,
  connectDragSource,
  connectDropTarget,
  canDrop,
  isOver,
  readOnly,

  ...others
}) => {
  const deleteIconVisible = (iconPanelVisible || isSelected) && onDeleteClick && iconPanel.showDeleteIcon;
  const itemLabelText = translationItem
    ? translationItem.continueText || translationItem.text || translationItem.thanksMessage
    : '';
  const headerWithHover = isSelected ? '' : classes.hover;
  const title = `${item.id}. ${itemLabelText}`;
  const dropTargetClassName = getDroppableClassName(classes, isOver, canDrop);
  const isReadOnlyPage = readOnly && parentPageModelPath;
  const deleteIconClass = deleteIconVisible ? classes.deleteIcon : classes.deleteIconHidden;

  return connectDropTarget(
    connectDragSource(
      <div className={classes.root}>
        <Paper
          square
          {...others}
          onClick={onClick}
          title={title}
          className={classNames(classes.dndPaper, others.className, dropTargetClassName, headerWithHover)}
          onMouseEnter={showIconPanel}
          onMouseLeave={hideIconPanel}>
          <div className={classes.dragHandleContainer} {...dragHandleProps}>
            {dragAndDropEnabled && !isReadOnlyPage && <span className={classes.dragHandleIcon}>:::</span>}
          </div>
          <Typography variant="subtitle2" className={classes.id}>
            {item.id}.
          </Typography>
          <Typography noWrap className={classes.text}>
            {translationItem && (translationItem.continueText || translationItem.text || translationItem.thanksMessage)}
          </Typography>
          {fromMaster && (
            <div className={classes.masterIcon}>
              <AlphaMcircleOutline titleAccess="Question is from Master. You can change texts in Translation View" />
            </div>
          )}
          <IconButton aria-label="Delete" title="Delete Question" className={deleteIconClass} onClick={onDeleteClick}>
            <Delete fontSize="small" />
          </IconButton>
        </Paper>
      </div>
    )
  );
};

const StyledItemHeader = pipe(
  withStateHandlers(
    { iconPanelVisible: false }, // state initialization
    {
      showIconPanel: () => () => ({ iconPanelVisible: true }),
      hideIconPanel: () => () => ({ iconPanelVisible: false }),
    }
  ),
  withHandlers({
    onClick: ({ modelPath, onSelect, isSelected }) => () => onSelect(isSelected ? null : modelPath),
    onDeleteClick: createOnDeleteHandler,
  }),
  withStylesAsClasses(styles),
  omit([
    'modelPath',
    'onDelete',
    'onSelect',
    'translationPath',
    'draggableRoot',
    'isDragging',
    'flattenMasterQuestions',
  ]),
  memoize(ItemHeaderPure)
);

const droppableTarget = {
  canDrop: ({ item, draggableRoot, readOnly, flattenMasterQuestions }, monitor) => {
    const draggedItem = monitor.getItem().item;
    const draggedItemIsEscapeQuestion = isEscape(draggedItem);
    const targetIsInPage = draggableRoot.length > 1;
    const draggingEscapeQuestionIntoPage = draggedItemIsEscapeQuestion && targetIsInPage;
    const draggingPageIntoPage = isPage(draggedItem) && targetIsInPage;
    const draggingIntoReadOnlyPage = targetIsInPage && readOnly;
    const draggedQuestionIsFromMaster = isQuestionInSurvey(draggedItem, flattenMasterQuestions);
    const draggingMasterQuestionIntoPage = targetIsInPage && draggedQuestionIsFromMaster;
    return (
      !isIntro(item) &&
      !isOutro(item) &&
      !draggingEscapeQuestionIntoPage &&
      !draggingPageIntoPage &&
      !draggingIntoReadOnlyPage &&
      !draggingMasterQuestionIntoPage
    );
  },
  drop: (props, monitor) => {
    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.index;

    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      return;
    }
    props.onDrop({
      destination: {
        index: hoverIndex,
        draggableRoot: props.draggableRoot,
      },
      source: {
        index: dragIndex,
        draggableRoot: monitor.getItem().draggableRoot,
      },
    });
  },
};

const droppableCollect = (dndConnect, monitor) => {
  return {
    connectDropTarget: dndConnect.dropTarget(),
    canDrop: monitor.canDrop(),
    isOver: monitor.isOver(),
  };
};

const draggableSource = {
  canDrag: ({ item, isSelected, readOnly, parentPageModelPath }) => {
    const isReadOnlyPage = readOnly && parentPageModelPath;
    return !isIntro(item) && !isOutro(item) && !isSelected && !isReadOnlyPage;
  },
  beginDrag({ index, draggableRoot, item }) {
    return {
      index,
      draggableRoot,
      item,
    };
  },
};

const draggableCollect = (dndConnect, monitor) => {
  return {
    connectDragSource: dndConnect.dragSource(),
    isDragging: monitor.isDragging(),
  };
};

const DroppableItemHeader = DropTarget(
  DraggableTypes.QUESTION,
  droppableTarget,
  droppableCollect
)(DragSource(DraggableTypes.QUESTION, draggableSource, draggableCollect)(StyledItemHeader));

const mapDispatchToProps = (dispatch) => ({
  onSelect: (itemPath) => {
    dispatch(selectItem(itemPath));
  },
  onDelete: (...args) => {
    dispatch(deleteItem(...args));
  },
  onDrop: (result) => {
    if (!result.destination) {
      // dropped outside the list
      return;
    }
    dispatch(moveQuestion(result));
  },
});

const mapStateToProps = (state, { item }) => ({
  translationPath: getTranslationPathForItem(state, item),
  flattenMasterQuestions: getFlattenMasterQuestions(state),
});

export const DraggableItemHeader = connect(mapStateToProps, mapDispatchToProps)(DroppableItemHeader);

DraggableItemHeader.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string,
    text: PropTypes.string,
  }),
  modelPath: PropTypes.array.isRequired,
  iconPanel: PropTypes.shape({
    showDeleteIcon: PropTypes.bool,
  }),
  iconPanelVisible: PropTypes.bool,
  parentPageModelPath: PropTypes.array,
  isSelected: PropTypes.bool,
  dragHandleProps: PropTypes.object,
  dragAndDropEnabled: PropTypes.bool,
  draggableRoot: PropTypes.array,
  readOnly: PropTypes.bool,
  fromMaster: PropTypes.bool,
};

DraggableItemHeader.defaultProps = {
  iconPanel: {
    showDeleteIcon: true,
  },
};
