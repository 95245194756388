import { TextField } from '@material-ui/core';
import classNames from 'classnames';
import { pipe, withHandlers } from 'commity-rehook-fork';
import * as PropTypes from 'prop-types';
import { omit, pluck, propOr, update } from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { flatQuestionTree } from 'xperience-model-management';
import { withStylesAsClasses } from '../../../rehooks';
import { getSelectedItemPath } from '../../../state/modules/editor';
import { getSurveyDefinition } from '../../../state/modules/survey/overview';
import { updateItemId } from '../../../state/modules/survey/overview/index';
import { getSelectedTranslationItem, getTranslationPathForItem } from '../../../state/modules/survey/translations';
import { updateTranslation } from '../../../state/modules/survey/translations/index';
import { createTranslationOnChangeHandler } from '../../handlerHelper';
import { InputWithState } from '../../InputWithState';
import { generateUniqQuestionId } from '../../questionHelper';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
});

const PageTypesOIPure = ({
  classes,
  translationItem,
  onPlaceholderChange,
  readOnly,
  item,
  onIdChange,
  questionIds,
  ...others
}) => {
  return (
    <div {...others} className={classNames(classes.root, others.className)}>
      {item.id && (
        <InputWithState
          id="id"
          label="ID"
          className={classes.idTextField}
          value={item.id}
          invalidValues={questionIds}
          onBlur={onIdChange}
          margin="normal"
          readOnly={readOnly}
          isMasterSurvey={false}
        />
      )}
      <TextField
        id="title"
        label="Page title"
        placeholder="..."
        multiline
        value={propOr('', 'title', translationItem)}
        onChange={onPlaceholderChange}
        margin="normal"
        disabled={readOnly}
      />
    </div>
  );
};

const PageTypesOIWithStyles = pipe(
  withHandlers({
    onIdChange: ({ modelPath, onItemIdChange }) => (newValue) => {
      return onItemIdChange({
        itemPath: [...modelPath],
        newValue,
      });
    },
    onPlaceholderChange: (props) => {
      let translationPath = props.translationPath;
      if (!props.item.id) {
        const id = generateUniqQuestionId(props.surveyDefinition, 'T');
        props.onItemIdChange({
          itemPath: [...props.modelPath],
          newValue: id,
        });
        props.item.id = id;
        translationPath = update(-1, id, translationPath);
      }

      return createTranslationOnChangeHandler(['title'])({ ...props, translationPath });
    },
  }),
  withStylesAsClasses(styles),
  omit(['translationPath', 'onTranslationChange', 'onItemIdChange', 'modelPath', 'surveyDefinition']),
  PageTypesOIPure
);

const mapStateToProps = (state, { item }) => ({
  questionIds: pluck('id', flatQuestionTree(getSurveyDefinition(state))),
  surveyDefinition: getSurveyDefinition(state),
  modelPath: getSelectedItemPath(state),
  translationItem: getSelectedTranslationItem(state),
  translationPath: getTranslationPathForItem(state, item),
});

const mapDispatchToProps = (dispatch) => ({
  onItemIdChange: ({ itemPath, newValue }) => dispatch(updateItemId(itemPath, newValue)),
  onTranslationChange: ({ translationPath, value }) => dispatch(updateTranslation(translationPath, value)),
});

export const PageTypesOI = connect(mapStateToProps, mapDispatchToProps)(PageTypesOIWithStyles);

PageTypesOI.propTypes = {
  readOnly: PropTypes.bool,
};
