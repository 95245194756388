import createReducer from 'create-reducer';
import { keys, path, pipe } from 'ramda';
import { combineEpics, ofType } from 'redux-observable';
import { map } from 'rxjs/operators';
import {
  getMasterTranslation,
  getSelectedCurrentLanguage,
  getSelectedSourceLanguage,
  getSelectedTargetLanguage,
  selectTargetLanguage,
} from '../../editor';
import { getPathWithRoot, PATHS } from '../paths';
import { getSelectedItem } from '../overview';
import { masterEnUSLocalization } from '../../../../components/translations/translationHelper';
import {
  addNewLanguageHandler,
  deleteLanguageHandler,
  toggleAgreementHandler,
  updateTranslationHandler,
  toggleOptOutHandler,
} from './handlers';

const ADD_NEW_LANGUAGE = 'survey/translations/addNewLanguage';
export const DELETE_LANGUAGE = 'survey/translation/deleteLanguage';
export const UPDATE_TRANSLATION = 'survey/translation/updateTranslation';
const TOGGLE_AGREEMENT = 'survey/translation/intro/toggleAgreement';
const TOGGLE_OPT_OUT = 'survey/translation/intro/toggleOptOut';

export const updateTranslation = (translationPath, value) => ({
  type: UPDATE_TRANSLATION,
  translationPath,
  value,
});

export const addNewLanguage = (language) => ({
  type: ADD_NEW_LANGUAGE,
  language,
});

export const deleteLanguage = (language) => ({
  type: DELETE_LANGUAGE,
  language,
});

export const toggleAgreement = (item, translationPath) => {
  return {
    type: TOGGLE_AGREEMENT,
    item,
    translationPath,
  };
};

export const toggleOptOut = (item, translationPath) => ({
  type: TOGGLE_OPT_OUT,
  item,
  translationPath,
});

const handlers = {
  [ADD_NEW_LANGUAGE]: (state, { language }) => addNewLanguageHandler(state, language),
  [DELETE_LANGUAGE]: (state, { language }) => deleteLanguageHandler(state, language),
  [UPDATE_TRANSLATION]: (state, { translationPath, value }) => updateTranslationHandler(state, translationPath, value),
  [TOGGLE_AGREEMENT]: toggleAgreementHandler,
  [TOGGLE_OPT_OUT]: toggleOptOutHandler,
};

export default createReducer(handlers, () => ({}));

const addNewLanguageEpic = (action$) =>
  action$.pipe(
    ofType(ADD_NEW_LANGUAGE),
    map((action) => selectTargetLanguage(action.language))
  );

export const epics = combineEpics(addNewLanguageEpic);

export const getTranslations = path(getPathWithRoot(PATHS.translations));

export const getSurveyLanguages = pipe(getTranslations, keys);

export const getSourceTranslation = (state) => {
  const sourceLanguage = getSelectedSourceLanguage(state);
  if (sourceLanguage === masterEnUSLocalization.code) {
    return getMasterTranslation(state);
  }
  return getTranslations(state)[getSelectedSourceLanguage(state)];
};

export const getTargetTranslation = (state) => getTranslations(state)[getSelectedTargetLanguage(state)];

export const getSelectedTranslationItem = (state) => {
  const selectedItem = getSelectedItem(state);
  const currentLanguage = getSelectedCurrentLanguage(state);
  return selectedItem && path([...getPathWithRoot(PATHS.translations), currentLanguage, selectedItem.id], state);
};

export const getTranslationForItem = (state, item) => {
  const currentLanguage = getSelectedCurrentLanguage(state);
  return item && path([...getPathWithRoot(PATHS.translations), currentLanguage, item.id], state);
};

export const getTranslationPathForItem = (state, item) => {
  const currentLanguage = getSelectedCurrentLanguage(state);
  return [currentLanguage, item.id];
};
