import { TextField } from '@material-ui/core';
import classNames from 'classnames';
import { pipe, withHandlers } from 'commity-rehook-fork';
import * as PropTypes from 'prop-types';
import { omit } from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { memoizeWithEquals, withStylesAsClasses } from '../../../rehooks';
import { getTranslationPathForItem, updateTranslation } from '../../../state/modules/survey/translations';
import { CopyPlaceholderButton } from '../../CopyPlaceholderButton';
import { createTranslationOnChangeHandler } from '../../handlerHelper';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing.unit,
  },

  introContainer: {
    display: 'flex',
  },
  introText: {
    flex: '1',
  },
});

/**
 const model = {
  id: 'intro', // povinný unikátní identifikátor
  type: 'intro',
  imagePath: {type: 'variable', value: 'carImage'}, // cesta k obrázku - expression
  optional: true, // hardcodovaná hodnota - vyžaduje dotazovač, není možné menit
  text: 'krátký nadpis', // text s placeholdery
  introduction: 'dlouhá intrukce', // text s placeholdery,
  agreement: {
    text: 'I agree with terms', // text
    errorMessage: 'You can\'t start without agreement.', // text
  },
  optOut: {
    text: 'pokud se chcetet odhlásit', // text
    linkText: 'klikněte zde', // text
    link: 'link na odhlášení', // text
  },
  startButtonText: 'text tlačítka start', // text
};
 **/

const IntroFormPure = ({ item, translationItem, onTextChange, onIntroductionChange, classes, ...others }) => {
  return (
    <div {...others} className={classNames(classes.root, others.className)}>
      <div className={classes.introContainer}>
        <TextField
          id="text"
          label="Text"
          placeholder="... insert text"
          multiline
          value={translationItem && translationItem.text}
          onChange={onTextChange}
          margin="normal"
          className={classes.introText}
        />
        <CopyPlaceholderButton />
      </div>
      <TextField
        id="introduction"
        label="Introduction"
        placeholder="... insert introduction text"
        multiline
        value={translationItem && translationItem.introduction}
        onChange={onIntroductionChange}
        margin="normal"
      />
    </div>
  );
};

const StyledIntroForm = pipe(
  withHandlers({
    onTextChange: createTranslationOnChangeHandler(['text']),
    onIntroductionChange: createTranslationOnChangeHandler(['introduction']),
  }),
  withStylesAsClasses(styles),
  omit(['onTranslationChange', 'translationPath', 'parentPageModelPath', 'modelPath']),
  memoizeWithEquals(IntroFormPure)
);

const mapStateToProps = (state, { item }) => ({
  translationPath: getTranslationPathForItem(state, item),
});

const mapDispatchToProps = (dispatch) => {
  return {
    onTranslationChange: ({ translationPath, value }) => {
      dispatch(updateTranslation(translationPath, value));
    },
  };
};

export const IntroForm = connect(mapStateToProps, mapDispatchToProps)(StyledIntroForm);

IntroForm.propTypes = {
  item: PropTypes.shape({
    text: PropTypes.string,
    introduction: PropTypes.string,
  }),
};
