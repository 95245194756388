import classNames from 'classnames';
import { pipe } from 'commity-rehook-fork';
import * as PropTypes from 'prop-types';
import React from 'react';
import { withStylesAsClasses } from '../../../../rehooks';

const styles = () => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
});

const TextQuestionFormPure = ({ item, translationItem, translationPath, classes, modelPath, readOnly, ...others }) => {
  return <div {...others} className={classNames(classes.root, others.className)} />;
};

export const TextQuestionForm = pipe(
  withStylesAsClasses(styles),
  TextQuestionFormPure
);

TextQuestionForm.propTypes = {
  item: PropTypes.shape({}),
  modelPath: PropTypes.array.isRequired,
  readOnly: PropTypes.bool,
};
