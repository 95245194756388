import {
  addIndex,
  always,
  any,
  anyPass,
  concat,
  cond,
  defaultTo,
  filter,
  flatten,
  hasPath,
  isEmpty,
  join,
  keys,
  map,
  path,
  pathOr,
  pipe,
  pluck,
  prop,
  propEq,
  propOr,
  reduce,
  reject,
  uniq,
  values,
  without,
} from 'ramda';
import {
  isGender,
  isIntro,
  isMulti,
  isQuestion,
  isSingle,
  isSquare,
  isStar,
  isOutro,
  ITEM_TYPES,
  isDynamicSquare,
} from 'xperience-model-management';
import {
  EXPRESSIONS_PROP_PATHS,
  getExpressionsFromString,
  transformExpression,
} from '../../surveyModel/postProcessing/textToExpressions';
import { checkRequiredPropertiesOnObject, isPathUndefinedOrEmpty } from './validationHelper';
import { createError, ERROR_MESSAGES } from './errors';

export const alwaysApply = always(true);
const reduceIndexed = addIndex(reduce);

const QUESTION_PROPS = 'questionProps';

const REQUIRED_PROPS_PATHS = {
  [ITEM_TYPES.INTRO]: [['text'], ['introduction'], ['startButtonText']],
  [ITEM_TYPES.OUTRO]: [['text']],
  [ITEM_TYPES.ESCAPE]: [
    ['thanksMessage'],
    ['continueButtonText'],
    ['continueText'],
    ['terminateText'],
    ['terminateButtonText'],
    ['saveDialogText'],
    ['saveButtonText'],
  ],
  [ITEM_TYPES.PERSONAL_AGREEMENT]: [
    ['thanksMessage'],
    ['continueButtonText'],
    ['continueText'],
    ['terminateButtonText'],
    ['agreement', 'text'],
    ['agreement', 'errorMessage'],
  ],
  [QUESTION_PROPS]: [['text']],
};
const REQUIRED_AGREEMENT_PROPS_PATHS = [
  ['agreement', 'text'],
  ['agreement', 'errorMessage'],
];
const REQUIRED_OPT_OUT_PROPS_PATHS = [
  ['optOut', 'text'],
  ['optOut', 'linkText'],
  ['optOut', 'unsubscribedText'],
  ['optOut', 'modalInfo'],
  ['optOut', 'modalOk'],
  ['optOut', 'modalCancel'],
];

const OPTIONAL_PROPS_PATHS = {
  [ITEM_TYPES.INTRO]: [['agreement', 'instruction']],
  [QUESTION_PROPS]: [['instruction']],
};

const REQUIRED_REDIRECT_PROPS_PATHS = [
  ['redirect', 'text'],
  ['redirect', 'linkText'],
];

const getRequiredPropsPaths = (item) =>
  isQuestion(item) ? REQUIRED_PROPS_PATHS[QUESTION_PROPS] : REQUIRED_PROPS_PATHS[item.type];
const getOptionalPropsPaths = (item) => {
  if (isQuestion(item)) {
    return OPTIONAL_PROPS_PATHS[QUESTION_PROPS];
  }
  return isIntro(item) ? OPTIONAL_PROPS_PATHS[item.type] : [];
};

export const hasPathInAnyTranslation = (propPath, itemId, translations) => {
  return any((language) => {
    const translationItem = path([language, itemId], translations);
    return translationItem && hasPath(propPath, translationItem) && !isEmpty(path(propPath, translationItem));
  })(keys(translations));
};

const REQUIRED_CHOICES_PROPS = ['text'];

const getChoiceValues = pipe(prop('choices'), defaultTo([]), pluck('value'));

export const missingRequiredChoicesFieldsValidator = {
  applyIf: (item) => anyPass([isSingle, isMulti, isGender, isSquare, isStar, isDynamicSquare])(item),
  validationFn: ({ translations }, item, itemPath) => {
    const languages = keys(translations);

    const getMissingReqProperties = (language, choices) =>
      pipe(
        map((choice) => {
          return checkRequiredPropertiesOnObject({
            item: choice,
            itemPath: [...itemPath, 'choices'],
            requiredProps: REQUIRED_CHOICES_PROPS,
            validatorName: 'missingRequiredChoicesFieldsValidator',
            errorMsgFun: missingChoicePropertiesErrorMsgFun,
            language,
          });
        }),
        flatten
      )(choices);

    const missingChoiceValueErrorMsgFun = (property) => `missing translation - choice ${property}`;
    const missingChoicePropertiesErrorMsgFun = (property) => `missing translation ${property} in choices`;

    return reduce((acc, language) => {
      const translationItem = path([language, item.id], translations);
      const translationChoices = propOr([], 'choices', translationItem);

      const missingChoiceValuesInTranslations = checkRequiredPropertiesOnObject({
        item: translationChoices,
        itemPath: [...itemPath, 'choices'],
        requiredProps: getChoiceValues(item),
        validatorName: 'missingRequiredChoicesFieldsValidator',
        errorMsgFun: missingChoiceValueErrorMsgFun,
        language,
      });
      const newAcc = concat(acc, missingChoiceValuesInTranslations);
      const missingReqProperties = getMissingReqProperties(language, values(translationChoices));
      return concat(newAcc, missingReqProperties);
    }, [])(languages);
  },
};

export const missingRequiredConditionalTextFieldsValidator = {
  applyIf: (item) => anyPass([isOutro])(item),
  validationFn: ({ translations }, item, itemPath) => {
    const languages = keys(translations);

    const missingChoicePropertiesErrorMsgFun = (index) => (property) =>
      `missing translation ${property} in condition : ${index + 1}`;

    return reduce((acc, language) => {
      const translationItem = path([language, item.id], translations);
      const translationTexts = propOr([], 'conditionalTexts', translationItem);

      const missingConditionalTextFieldsInTranslations = reduceIndexed(
        (state, field, index) => {
          const missingConditionalTextFields = checkRequiredPropertiesOnObject({
            item: field,
            itemPath: [...itemPath],
            requiredProps: ['text'],
            validatorName: 'missingRequiredConditionalTextFieldsValidator',
            errorMsgFun: missingChoicePropertiesErrorMsgFun(index),
            language,
          });

          return concat(state, missingConditionalTextFields);
        },
        [],
        translationTexts
      );
      return concat(acc, missingConditionalTextFieldsInTranslations);
    }, [])(languages);
  },
};

export const missingRequiredIntroSpecificFieldsValidator = {
  applyIf: (item) => isIntro(item),
  validationFn: ({ translations }, item, itemPath) => {
    const isAgreementActive = hasPathInAnyTranslation(['agreement'], item.id, translations);
    const isOptOutActive = hasPathInAnyTranslation(['optOut'], item.id, translations);
    if (!isAgreementActive && !isOptOutActive) {
      return [];
    }
    const languages = keys(translations);
    const checkRequiredPropertiesOnPath = (language) => {
      const translationItem = path([language, item.id], translations);

      const validationResult = [
        ...(isAgreementActive
          ? map(getErrorIfDataMissingOnPath(translationItem, language, itemPath))(REQUIRED_AGREEMENT_PROPS_PATHS)
          : []),
        ...(isOptOutActive
          ? map(getErrorIfDataMissingOnPath(translationItem, language, itemPath))(REQUIRED_OPT_OUT_PROPS_PATHS)
          : []),
      ];

      return filter(Boolean)(validationResult);
    };

    return reduce((acc, language) => {
      return concat(acc, checkRequiredPropertiesOnPath(language));
    }, [])(languages);
  },
};

export const missingRequiredOutroSpecificFieldsValidator = {
  applyIf: (item) => isOutro(item),
  validationFn: ({ translations }, item, itemPath) => {
    const isRedirectActive = path(['componentProps', 'isRedirect'], item);
    if (!isRedirectActive) {
      return [];
    }
    const languages = keys(translations);
    const checkRequiredPropertiesOnPath = (language) => {
      const translationItem = path([language, item.id], translations);

      const validationResult = [
        ...(isRedirectActive
          ? map(getErrorIfDataMissingOnPath(translationItem, language, itemPath))(REQUIRED_REDIRECT_PROPS_PATHS)
          : []),
      ];

      return filter(Boolean)(validationResult);
    };

    return reduce((acc, language) => {
      return concat(acc, checkRequiredPropertiesOnPath(language));
    }, [])(languages);
  },
};

const getErrorIfDataMissingOnPath = (translationItem, language, itemPath) => (propPath) => {
  if (!translationItem || isPathUndefinedOrEmpty(translationItem, propPath)) {
    const errorMsg = `${ERROR_MESSAGES.MISSING_TRANSLATION} - ${joinPropertyPath(propPath)}`;
    return createError({ message: errorMsg, validator: 'missingRequiredFieldsValidator', itemPath, language });
  }
  return null;
};

export const missingRequiredFieldsValidator = {
  applyIf: alwaysApply,
  validationFn: ({ translations }, item, itemPath) => {
    const requiredPropsPaths = getRequiredPropsPaths(item);
    const languages = keys(translations);

    const checkRequiredPropertiesOnPath = (language) => {
      const translationItem = path([language, item.id], translations);

      return pipe(
        map(getErrorIfDataMissingOnPath(translationItem, language, itemPath)),
        filter(Boolean)
      )(requiredPropsPaths);
    };

    return reduce((acc, language) => {
      return concat(acc, checkRequiredPropertiesOnPath(language));
    }, [])(languages);
  },
};

const joinPropertyPath = join('.');

export const missingOptionalFieldsValidator = {
  applyIf: alwaysApply,
  validationFn: ({ translations }, item, itemPath) => {
    const optionalPropPaths = getOptionalPropsPaths(item);
    const languages = keys(translations);

    const checkOptionalPropertiesOnPath = (language) => {
      const translationItem = path([language, item.id], translations);
      const pathExistsInAnyTranslation = (propPath) => hasPathInAnyTranslation(propPath, item.id, translations);

      return pipe(
        filter(pathExistsInAnyTranslation),
        map(getErrorIfDataMissingOnPath(translationItem, language, itemPath)),
        filter(Boolean)
      )(optionalPropPaths);
    };

    return reduce((acc, language) => {
      const translationItem = path([language, item.id], translations);
      if (translationItem) {
        return concat(acc, checkOptionalPropertiesOnPath(language));
      }
      return acc;
    }, [])(languages);
  },
};

const getPlaceholderNameByTypeVariable = cond([
  [propEq('type', 'variable'), prop('value')],
  [propEq('type', 'function'), (placeholder) => map(getPlaceholderNameByTypeVariable, placeholder.args)],
]);

const getPlaceholderNamesFromExpression = pipe(
  map(transformExpression),
  flatten,
  map(getPlaceholderNameByTypeVariable),
  flatten
);

export const notExistentPlaceholderUsedValidator = {
  applyIf: alwaysApply,
  validationFn: ({ translations, placeholders }, item, itemPath) => {
    const validatorName = 'notExistentPlaceholderUsedValidator';
    const languages = keys(translations);
    const placeholderNames = pluck('name', placeholders);

    const getExpressions = (propPath) =>
      pipe(
        pathOr('', propPath),
        getExpressionsFromString,
        getPlaceholderNamesFromExpression,
        uniq,
        without(placeholderNames)
      );

    const getAllExpressions = (translationItem) => {
      const getValueFromExpressionPath = (propPath) => ({
        expressions: getExpressions(propPath)(translationItem),
        propPath: joinPropertyPath(propPath),
      });

      return pipe(map(getValueFromExpressionPath), flatten)(values(EXPRESSIONS_PROP_PATHS));
    };

    const rejectEmptyExpressions = reject((expression) => {
      return isEmpty(prop('expressions', expression));
    });

    const expressionsToErrors = (language, propPath) =>
      map((expression) => {
        return createError({
          message: `use of not existent placeholder "${expression}" in ${propPath} fíeld`,
          validator: validatorName,
          language,
          itemPath,
        });
      });

    const placeholdersToErrors = (language) =>
      map(({ expressions, propPath }) => {
        return expressionsToErrors(language, propPath)(expressions);
      });

    return reduce((acc, language) => {
      const translationItem = path([language, item.id], translations);
      const getErrors = pipe(
        getAllExpressions,
        rejectEmptyExpressions,
        placeholdersToErrors(language),
        flatten,
        concat(acc)
      );

      if (translationItem) {
        return getErrors(translationItem);
      }
      return acc;
    }, [])(languages);
  },
};
