import { Typography } from '@material-ui/core';
import classNames from 'classnames';
import { pipe } from 'commity-rehook-fork';
import * as PropTypes from 'prop-types';
import React from 'react';
import { memoize, withStylesAsClasses } from '../../../rehooks';
import { TranslationProperty } from './TranslationProperty';

const styles = theme => ({
  root: {
    border: theme.primaryBorder.border,
    borderRadius: theme.primaryBorder.borderRadius,
    margin: theme.spacing.unit,
    padding: theme.spacing.unit,
    display: 'flex',
    flexDirection: 'column',
  },

  choice: {
    display: 'flex',
    padding: `0 ${theme.spacing.unit}px 0 ${theme.spacing.unit}px`,
  },

  value: {
    alignSelf: 'center',
    minWidth: theme.spacing.unit * 4,
  },

  text: {
    flex: 1,
  },
});

/*
verze 2:

  choices: {
    3: { value: 3, text: "Fairly likely" },
    2: { value: 2, text: "Probably not" },
    1: { value: 1, text: "Definitely not" }
  }

 */

const TranslationChoicesPure = ({
  propertyLabel,
  translationPath,
  choicesDefinition,
  sourcePropertyValue: sourceChoices = [],
  targetPropertyValue: targetChoices = [],
  classes,
  ...others
}) => {
  return (
    <div {...others} className={classNames(classes.root, others.className)}>
      <Typography variant="subtitle1">{propertyLabel}</Typography>
      {choicesDefinition &&
        choicesDefinition.map(({ value }, index) => {
          const choiceValue = value.toString();
          return (
            <div key={value}>
              <div className={classes.choice}>
                <div className={classes.value}>
                  <Typography variant="subtitle1" title="Value">
                    {choiceValue}:
                  </Typography>
                </div>
                <div className={classes.text}>
                  <TranslationProperty
                    propertyLabel="Text"
                    translationPath={[...translationPath, choiceValue, 'text']}
                    sourcePropertyValue={sourceChoices[choiceValue] && sourceChoices[choiceValue].text}
                    targetPropertyValue={targetChoices[choiceValue] && targetChoices[choiceValue].text}
                  />
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export const TranslationChoices = pipe(
  withStylesAsClasses(styles),
  memoize(TranslationChoicesPure)
);

TranslationChoices.propTypes = {
  propertyLabel: PropTypes.string.isRequired,
  translationPath: PropTypes.array.isRequired,
  sourcePropertyValue: PropTypes.object,
  targetPropertyValue: PropTypes.object,
};
