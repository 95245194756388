import { assocPath, isNil, keys, pipe } from 'ramda';
import { flatQuestionTree } from 'xperience-model-management';
import { surveyPreProcessing } from '../../../surveyModel/exportDefinition/preProcessing';
import {
  INITIALIZE as OVERVIEW_INITIALIZE,
  INITIALIZE_MASTER as INIT_MASTER,
  INITIALIZE_SURVEY as INIT_SURVEY,
} from '../survey/overview';
import { masterEnUSLocalization } from '../../../components/translations/translationHelper';
import { EDITOR_PATHS } from './index';

export const SELECT_ITEM = 'editor/select';
export const SELECT_EDITOR_VIEW = 'editor/selectEditorView';
export const SELECT_SOURCE_LANGUAGE = 'editor/selectSourceLanguage';
export const SELECT_TARGET_LANGUAGE = 'editor/selectTargetLanguage';
export const SELECT_CURRENT_LANGUAGE = 'editor/selectCurrentLanguage';

// TODO tmp hack fix for #363, OVERVIEW_INITIALIZE is undefined, probably because circle dependency
const INITIALIZE = OVERVIEW_INITIALIZE || 'survey/overview/initialize';
const INITIALIZE_SURVEY = INIT_SURVEY || 'survey/overview/initializeSurvey';
const INITIALIZE_MASTER = INIT_MASTER || 'survey/overview/initializeMaster';

export const handlers = {
  '@@INIT': state =>
    pipe(
      assocPath(EDITOR_PATHS.selectedItemPath, []),
      assocPath(EDITOR_PATHS.masterSurvey, undefined),
      assocPath(EDITOR_PATHS.placeholders, []),
      assocPath(EDITOR_PATHS.isMaster, true)
    )(state),

  [INITIALIZE]: (state, props) => {
    const { masterDefinition, placeholders, surveyDefinition } = props;
    const isMaster = isNil(masterDefinition);
    const masterSurvey = !isMaster ? surveyPreProcessing(masterDefinition) : null;
    const flattenMasterQuestions = masterSurvey ? flatQuestionTree(masterSurvey.definition) : [];

    const languages = keys(surveyDefinition.translations);

    return pipe(
      assocPath(EDITOR_PATHS.isMaster, isMaster),
      assocPath(EDITOR_PATHS.selectedItemPath, []),
      assocPath(EDITOR_PATHS.masterSurvey, masterSurvey),
      assocPath(EDITOR_PATHS.placeholders, placeholders),
      assocPath(EDITOR_PATHS.flattenMasterQuestions, flattenMasterQuestions),
      assocPath(EDITOR_PATHS.currentLanguage, languages[0]),
      assocPath(EDITOR_PATHS.selectedSourceLanguage, languages[0]),
      assocPath(EDITOR_PATHS.selectedTargetLanguage, languages[0])
    )(state);
  },

  [INITIALIZE_SURVEY]: (state, props) => {
    const { masterDefinition, placeholders, surveyDefinition, language } = props;
    const isMaster = isNil(masterDefinition);
    const masterSurvey = !isMaster ? surveyPreProcessing(masterDefinition) : null;
    const flattenMasterQuestions = masterSurvey ? flatQuestionTree(masterSurvey.definition) : [];

    const languages = keys(surveyDefinition.translations);

    return pipe(
      assocPath(EDITOR_PATHS.isMaster, isMaster),
      assocPath(EDITOR_PATHS.selectedItemPath, []),
      assocPath(EDITOR_PATHS.masterSurvey, masterSurvey),
      assocPath(EDITOR_PATHS.placeholders, placeholders),
      assocPath(EDITOR_PATHS.flattenMasterQuestions, flattenMasterQuestions),
      assocPath(EDITOR_PATHS.currentLanguage, language || languages[0]),
      assocPath(EDITOR_PATHS.selectedSourceLanguage, masterEnUSLocalization.code),
      assocPath(EDITOR_PATHS.selectedTargetLanguage, language || languages[0])
    )(state);
  },

  [INITIALIZE_MASTER]: (state, props) => {
    const { placeholders } = props;
    const language = 'en_US';

    return pipe(
      assocPath(EDITOR_PATHS.isMaster, true),
      assocPath(EDITOR_PATHS.selectedItemPath, []),
      assocPath(EDITOR_PATHS.masterSurvey, null),
      assocPath(EDITOR_PATHS.placeholders, placeholders),
      assocPath(EDITOR_PATHS.flattenMasterQuestions, []),
      assocPath(EDITOR_PATHS.currentLanguage, language),
      assocPath(EDITOR_PATHS.selectedSourceLanguage, language),
      assocPath(EDITOR_PATHS.selectedTargetLanguage, language)
    )(state);
  },

  [SELECT_ITEM]: (state, { itemPath }) => assocPath(EDITOR_PATHS.selectedItemPath, itemPath, state),
  [SELECT_EDITOR_VIEW]: (state, { editorView }) => assocPath(EDITOR_PATHS.selectedEditorView, editorView, state),
  [SELECT_SOURCE_LANGUAGE]: (state, { language }) => assocPath(EDITOR_PATHS.selectedSourceLanguage, language, state),
  [SELECT_TARGET_LANGUAGE]: (state, { language }) => assocPath(EDITOR_PATHS.selectedTargetLanguage, language, state),
  [SELECT_CURRENT_LANGUAGE]: (state, { language }) => assocPath(EDITOR_PATHS.currentLanguage, language, state),
};
