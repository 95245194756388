import {
  append,
  assoc,
  assocPath,
  dissoc,
  dissocPath,
  dropLast,
  equals,
  filter,
  findIndex,
  forEach,
  has,
  hasPath,
  head,
  includes,
  insert,
  intersection,
  is,
  isEmpty,
  keys,
  last,
  lensPath,
  map,
  max,
  move,
  over,
  path,
  pathOr,
  pipe,
  pluck,
  prop,
  propEq,
  reduce,
  reject,
  remove,
  when,
} from 'ramda';
import {
  flatQuestionTreeWithPaths,
  isGender,
  isPage,
  translationPropsByElementTypes,
} from 'xperience-model-management';
import { isDragAndDropRoot } from '../../../../components/dndHelper';
import {
  generateUniqQuestionId,
  HIDEABLE_CHOICE_VALUE,
  ITEM_TYPES,
  removeQuestionByIds,
} from '../../../../components/questionHelper';
import { surveyPreProcessing } from '../../../../surveyModel/exportDefinition/preProcessing';
import { typeReshaper } from '../../../../surveyModel/reshaper';
import { PATHS } from '../paths';
import { getTranslationItemFromMasterTranslations } from '../../../../components/translations/translationHelper';
import { MASTER_LANGUAGE } from '../../editor';

const reshape = typeReshaper(ITEM_TYPES);

const getCompleteSurveyDefinitionPath = (modelPath = []) => [...PATHS.surveyDefinition, ...modelPath];

const getMaxValue = pipe(pluck('value'), reduce(max, 0));

export const QUESTION_PREFIX = {
  master: 'Q',
  survey: 'I',
};

const createEmptyQuestion = (state, type = ITEM_TYPES.TEXT, isMaster) => {
  const surveyDefinition = path(PATHS.surveyDefinition, state);
  const id = generateUniqQuestionId(surveyDefinition, isMaster ? QUESTION_PREFIX.master : QUESTION_PREFIX.survey);
  return reshape({ id }, type);
};

const createEmptyPageQuestion = (state, isMaster) => createEmptyQuestion(state, ITEM_TYPES.PAGE, isMaster);

export const updateItemHandler = (state, { itemPath, value }) => {
  return assocPath([...PATHS.surveyDefinition, ...itemPath], value, state);
};

const translationsLens = lensPath(PATHS.translations);

const defaultGenderTranslation = {
  1: { text: 'Male' },
  2: { text: 'Female' },
  999: { text: 'I prefer not to answer' },
};

const clearChoicesTranslations = (item) => (state) => {
  const itemChoices = prop('choices', item);
  if (!itemChoices) {
    // remove choices
    const itemId = prop('id', item);
    const removeChoicesFromTranslationItem = (translation) => dissocPath([itemId, 'choices'], translation);
    const removeChoicesInTranslations = map(removeChoicesFromTranslationItem);
    return over(translationsLens, removeChoicesInTranslations, state);
  }
  return state;
};

const clearPlaceholderProperty = (item, newType) => (state) => {
  if (newType === ITEM_TYPES.TEXT) {
    return state;
  }
  const itemId = prop('id', item);
  const deletePlaceholderPropertyInTranslationItem = (translation) => dissocPath([itemId, 'placeholder'], translation);
  const deletePlaceholderInTranslations = map(deletePlaceholderPropertyInTranslationItem);
  return over(translationsLens, deletePlaceholderInTranslations, state);
};

const fixGenderChoices = (item, currentLanguage) => (state) => {
  if (!isGender(item)) {
    return state;
  }
  return assocPath([PATHS.translations, currentLanguage, item.id, 'choices'], defaultGenderTranslation, state);
};

export const initTranslations = (item) => (state) => {
  const translationKeysByType = translationPropsByElementTypes[item.type];
  const translationWithEmptyString = intersection(translationKeysByType, [
    'text',
    'instruction',
    'labelMin',
    'labelMax',
  ]);

  const transformLanguages = (currentLang) => {
    const setEmptyStrings = reduce(
      (acc, key) => {
        if (!hasPath([item.id, key], currentLang)) {
          return append(assocPath([item.id, key], ''), acc);
        }

        return acc;
      },
      {},
      translationWithEmptyString
    );

    return !isEmpty(setEmptyStrings) ? pipe(...setEmptyStrings)(currentLang) : currentLang;
  };
  const setEmptyStringInTranslations = map(transformLanguages);
  return over(translationsLens, setEmptyStringInTranslations, state);
};

export const changeQuestionTypeHandler = (state, { itemPath, newType, currentLanguage }) => {
  const completeItemPath = getCompleteSurveyDefinitionPath(itemPath);
  let item = path(completeItemPath, state);
  // TODO: tohle je velice jednoduchý reshape, pokud bude čas použít reshape z q-b
  item = reshape(item, newType);
  return pipe(
    initTranslations(item),
    clearChoicesTranslations(item),
    clearPlaceholderProperty(item, newType),
    fixGenderChoices(item, currentLanguage),
    assocPath(completeItemPath, item)
  )(state);
};

export const addNewChoiceHandler = (state, { modelPath, value }) => {
  const completePath = getCompleteSurveyDefinitionPath(modelPath);
  let choices = pathOr([], completePath, state);
  let newChoice = {
    value: value || getMaxValue(choices) + 1,
  };
  newChoice = when(propEq('value', HIDEABLE_CHOICE_VALUE), assoc('hidden', false))(newChoice);
  choices = append(newChoice, choices);
  return assocPath(completePath, choices, state);
};

export const addNewQuestionHandler = (state, { questionType, isMaster }) => {
  const completePath = getCompleteSurveyDefinitionPath();
  let items = path(completePath, state);
  const newQuestion = createEmptyQuestion(state, questionType, isMaster);
  items = insert(items.length - 1, newQuestion, items);
  // TODO - add select new item
  return assocPath(completePath, items, state);
};

export const deleteItemHandler = (
  state,
  { translationPath, itemPath, parentPageQuestionPath, enablePageDelete = true }
) => {
  let newState = dissocPath(getCompleteSurveyDefinitionPath(itemPath), state);
  if (parentPageQuestionPath) {
    const completePath = getCompleteSurveyDefinitionPath(parentPageQuestionPath);
    const parentPageQuestion = path(completePath, newState);
    if (enablePageDelete && parentPageQuestion.elements.length === 1) {
      newState = assocPath(completePath, head(parentPageQuestion.elements), newState);
    }
  }
  if (translationPath) {
    newState = dissocPath([...PATHS.translations, ...translationPath], newState);
  }
  return newState;
};

export const addNewQuestionToPageHandler = (state, { modelPath, parentPageQuestionPath, isMaster }) => {
  const hasPageQuestionParent = Boolean(parentPageQuestionPath);
  const elementsProp = 'elements';
  const question = path(getCompleteSurveyDefinitionPath(modelPath), state);
  let parentPageQuestion;
  let newQuestionIndex;
  if (hasPageQuestionParent) {
    parentPageQuestion = path(getCompleteSurveyDefinitionPath(parentPageQuestionPath), state);
    newQuestionIndex = last(parentPageQuestionPath);
  } else {
    parentPageQuestion = createEmptyPageQuestion(state, isMaster);
    parentPageQuestion = assocPath([elementsProp, 0], question, parentPageQuestion);
    newQuestionIndex = parentPageQuestion.elements.length;
  }
  const elements = pipe(
    prop(elementsProp),
    insert(newQuestionIndex, createEmptyQuestion(state, ITEM_TYPES.TEXT, isMaster))
  )(parentPageQuestion);
  parentPageQuestion = assoc(elementsProp, elements, parentPageQuestion);
  const completePath = getCompleteSurveyDefinitionPath(parentPageQuestionPath || modelPath);
  // TODO - focus new item using Redux-observable
  return assocPath(completePath, parentPageQuestion, state);
};

export const addQuestionFromMaster = (state, { question, masterTranslations }) => {
  if (isPage(question)) {
    return addPageQuestionFromMaster(state, question, masterTranslations);
  }
  return addNewQuestionFromMaster(state, question, masterTranslations);
};

export const addPageQuestionFromMaster = (state, question, masterTranslations) => {
  const pageElements = question.elements;
  const pageElementsIds = getIds(pageElements);
  const completePath = getCompleteSurveyDefinitionPath();
  const items = path(completePath, state);
  const surveyItemsWithPaths = flatQuestionTreeWithPaths(items);
  const alreadyInsertedQuestions = filter(({ id }) => {
    return includes(id, pageElementsIds);
  }, surveyItemsWithPaths);

  if (!alreadyInsertedQuestions.length) {
    return addNewQuestionFromMaster(state, question, masterTranslations);
  }

  if (alreadyInsertedQuestions.length === 1) {
    // already inserted question is actually not in any page, need to create a new page
    const newItems = removeQuestionByIds(items, getIds(alreadyInsertedQuestions));
    // eslint-disable-next-line no-shadow
    const completePath = getCompleteSurveyDefinitionPath();
    // eslint-disable-next-line no-param-reassign
    state = assocPath(completePath, newItems, state);
    return addNewQuestionFromMaster(state, question, masterTranslations);
  }

  if (alreadyInsertedQuestions.length !== pageElements.length) {
    // insert only new questions to the existing page
    const newQuestions = reject(({ id }) => {
      return includes(id, getIds(alreadyInsertedQuestions));
    }, pageElements);
    const destPagePath = getDestPagePath(alreadyInsertedQuestions);
    forEach((newQuestion) => {
      const newQuestionIndex = findIndex(equals(newQuestion.id), pageElementsIds);
      // eslint-disable-next-line no-param-reassign
      state = addMasterQuestionToPage(state, newQuestion, head(destPagePath), newQuestionIndex, masterTranslations);
    }, newQuestions);
  }
  return state;
};

const getIds = pluck('id');
const getDestPagePath = pipe(head, prop('path'));

const addNewQuestionFromMaster = (state, question, translationItem) => {
  const completePath = getCompleteSurveyDefinitionPath();
  let items = path(completePath, state);
  items = insert(items.length - 1, question, items);
  // eslint-disable-next-line no-param-reassign
  state = assocPath(completePath, items, state);
  return copyTranslationFromMaster(question, translationItem, state);
};

const addMasterQuestionToPage = (state, question, destPageIndex, itemIndex, masterTranslations) => {
  const completePath = getCompleteSurveyDefinitionPath();
  let items = path(completePath, state);
  const destPage = items[destPageIndex];
  const newDestPageElements = insert(itemIndex, question, destPage.elements);
  items = assocPath([destPageIndex, 'elements'], newDestPageElements, items);
  // eslint-disable-next-line no-param-reassign
  state = assocPath(completePath, items, state);
  return copyTranslationFromMaster(question, masterTranslations, state);
};

const copyMasterTranslationsForPage = (masterTranslations, state) =>
  reduce((acc, element) => {
    return copyTranslationFromMaster(element, masterTranslations, acc);
  }, state);

export const resetTranslationObjectValues = (translationObj) => {
  return reduce(
    (acc, key) => {
      const value = acc[key];
      if (Array.isArray(value)) {
        return assoc(key, map(resetTranslationObjectValues, value), acc);
      }
      if (is(Object, value)) {
        return assoc(key, resetTranslationObjectValues(value), acc);
      }
      return assoc(key, null, acc);
    },
    translationObj,
    keys(translationObj)
  );
};

const copyTranslationFromMaster = (question, masterTranslations, state) => {
  if (isPage(question)) {
    return copyMasterTranslationsForPage(masterTranslations, state)(question.elements);
  }
  const translationItem = getTranslationItemFromMasterTranslations(masterTranslations, question);
  const copyMasterTranslation = (translation, language) => {
    const translationObj =
      language === MASTER_LANGUAGE ? translationItem : resetTranslationObjectValues(translationItem);
    return assoc(question.id, translationObj, translation);
  };
  const translations = path(PATHS.translations, state);
  return reduce(
    (acc, language) => {
      const updatedTranslations = copyMasterTranslation(translations[language], language);
      return assocPath([...PATHS.translations, language], updatedTranslations, acc);
    },
    state,
    keys(translations)
  );
};

export const moveQuestionHandler = (state, { result }) => {
  const { source, destination } = result;
  const sourceRootPath = getCompleteSurveyDefinitionPath(source.draggableRoot);
  const movedQuestion = path([...sourceRootPath, source.index], state);
  const isSourceDndRoot = isDragAndDropRoot(source.draggableRoot);

  // remove question from source
  const newState = deleteItemHandler(state, {
    itemPath: [...source.draggableRoot, source.index],
    parentPageQuestionPath: !isSourceDndRoot ? [head(source.draggableRoot)] : null,
    enablePageDelete: isSourceDndRoot || !equals(source.draggableRoot, destination.draggableRoot),
  });

  let destinationModelPath = destination.draggableRoot;
  if (
    !equals(source.draggableRoot, destination.draggableRoot) &&
    !isDragAndDropRoot(destination.draggableRoot) &&
    isSourceDndRoot
  ) {
    // moving item from survey overview above page item into page item
    const destinationItemIndex = destinationModelPath[0];
    if (destinationItemIndex > source.index) {
      destinationModelPath = assocPath([0], destinationItemIndex - 1, destinationModelPath);
    }
  }
  const destinationPath = getCompleteSurveyDefinitionPath(destinationModelPath);
  // add question to destination
  let destinationItems = path(destinationPath, newState);
  destinationItems = insert(destination.index, movedQuestion, destinationItems);
  return assocPath(destinationPath, destinationItems, newState);
};

export const moveChoiceHandler = (state, { result }) => {
  const { source, destination } = result;
  const sourceRootPath = getCompleteSurveyDefinitionPath(source.draggableRoot);
  const movedChoice = path([...sourceRootPath, source.index], state);
  // remove question from source
  const newState = deleteItemHandler(state, {
    itemPath: [...source.draggableRoot, source.index],
  });

  const destinationPath = getCompleteSurveyDefinitionPath(destination.draggableRoot);
  // add question to destination
  let destinationItems = path(destinationPath, newState);
  destinationItems = insert(destination.index, movedChoice, destinationItems);
  return assocPath(destinationPath, destinationItems, newState);
};

export const toggleMandatoryHandler = (state, { item, selectedItemPath }) => {
  const mandatoryPath = PATHS.mandatory;
  const newMandatoryValue = !path(mandatoryPath, item);
  let updatedItem = assocPath(mandatoryPath, newMandatoryValue, item);
  if (!newMandatoryValue) {
    updatedItem = dissocPath(PATHS.dependency, updatedItem);
  }
  return assocPath(getCompleteSurveyDefinitionPath(selectedItemPath), updatedItem, state);
};

export const toggleBeforeExitQuestionHandler = (state, { beforeExitQuestion, itemPath }) => {
  let newState;
  const beforeExitQuestionPath = [...getCompleteSurveyDefinitionPath(itemPath), ...PATHS.beforeExitQuestion];
  if (beforeExitQuestion) {
    newState = assocPath(beforeExitQuestionPath, null, state);
  } else {
    newState = dissocPath(dropLast(1, beforeExitQuestionPath), state);
  }
  return newState;
};

export const toggleHiddenIntroHandler = (state, { hiddenIntro, itemPath }) => {
  let newState;
  const hiddenIntroPath = [...getCompleteSurveyDefinitionPath(itemPath), ...PATHS.hiddenIntro];
  if (hiddenIntro) {
    newState = assocPath(hiddenIntroPath, true, state);
  } else {
    newState = dissocPath(dropLast(1, hiddenIntroPath), state);
  }
  return newState;
};

export const toggleAutofocusHandler = (state, { autofocus, itemPath }) => {
  let newState;
  const autofocusPath = [...getCompleteSurveyDefinitionPath(itemPath), ...PATHS.autofocus];
  if (autofocus) {
    newState = dissocPath(autofocusPath, state);
  } else {
    newState = assocPath(autofocusPath, false, state);
  }
  return newState;
};

export const updateOptionalQuestionHandler = (state, { checked, itemPath }) => {
  const optionalQuestionPath = [...getCompleteSurveyDefinitionPath(itemPath), ...PATHS.optionalQuestion];

  return checked ? assocPath(optionalQuestionPath, true, state) : dissocPath(optionalQuestionPath, state);
};

export const toggleAutoNextNavigationHandler = (state, { navigationAutoNext }) => {
  let newState;
  if (navigationAutoNext) {
    newState = dissocPath(PATHS.navigationAutoNext, state);
  } else {
    newState = assocPath(PATHS.navigationAutoNext, navigationAutoNext, state);
  }
  return newState;
};

export const showLanguageSwitchHandler = (state, { showLanguageSwitch }) => {
  let newState;
  if (showLanguageSwitch === 'intro') {
    newState = dissocPath(PATHS.showLanguageSwitch, state);
  } else {
    newState = assocPath(PATHS.showLanguageSwitch, showLanguageSwitch, state);
  }
  return newState;
};

export const updateTextFieldRowsValueHandler = (state, { rowsValue, itemPath }) => {
  let newState;
  const parseRowsValue = parseInt(rowsValue, 10);
  const textFieldRowsPath = [...getCompleteSurveyDefinitionPath(itemPath), ...PATHS.textFieldRows];
  if (parseRowsValue === 0) {
    newState = dissocPath(textFieldRowsPath, state);
  } else {
    newState = assocPath(textFieldRowsPath, parseRowsValue, state);
  }
  return newState;
};

export const updateDynamicSquareCountValueHandler = (state, { rowsValue, itemPath }) => {
  let newState;
  let parseRowsValue = parseInt(rowsValue, 10);
  const dynamicSquareCountPath = [...getCompleteSurveyDefinitionPath(itemPath), ...PATHS.dynamicSquareCount];
  if (parseRowsValue < 2) {
    newState = dissocPath(dynamicSquareCountPath, state);
  } else {
    if (parseRowsValue > 10) {
      parseRowsValue = 10;
    }
    newState = assocPath(dynamicSquareCountPath, parseRowsValue, state);
  }
  return newState;
};

export const initializeHandler = (state, { surveyDefinition, guid }) =>
  pipe(surveyPreProcessing, assocPath(['metadata', 'temp', 'guid'], guid))(surveyDefinition);

export const initializeMasterHandler = (state, { surveyDefinition, guid }) =>
  pipe(surveyPreProcessing, assocPath(['metadata', 'temp', 'guid'], guid))(surveyDefinition);

export const initializeSurveyHandler = (state, { surveyDefinition, masterDefinition, guid, language }) => {
  const preProcessedMasterDefinition = surveyPreProcessing(masterDefinition);
  let newState = pipe(
    surveyPreProcessing,
    assocPath(['metadata', 'temp', 'guid'], guid),
    assocPath(['definition'], path(['definition'], preProcessedMasterDefinition))
  )(surveyDefinition);
  if (language) {
    newState = assocPath(
      ['translations', language],
      path(['translations', 'en_US'], preProcessedMasterDefinition),
      newState
    );
  }
  return newState;
};

const fixId = (translation, oldId, newId) => {
  return pipe(when(has(oldId), assoc(newId, translation[oldId])), dissoc(oldId))(translation);
};

export const updateItemIdHandler = (state, { itemPath, newValue }) => {
  const idPath = [...PATHS.surveyDefinition, ...itemPath, 'id'];
  const oldId = path(idPath, state);

  const translations = path([...PATHS.translations], state);
  const newTranslations = map((translation) => fixId(translation, oldId, newValue), translations);

  return pipe(assocPath(idPath, newValue), assocPath(PATHS.translations, newTranslations))(state);
};

const fixValue = (translation, itemId, oldValue, newValue) => {
  const movedChoice = path([itemId, 'choices', oldValue], translation);
  return pipe(
    assocPath([itemId, 'choices', newValue.toString()], movedChoice),
    dissocPath([itemId, 'choices', oldValue])
  )(translation);
};

const fixHidden = (oldValue, newValue, newChoice) => {
  let fixedChoice = newChoice;
  if (newValue === HIDEABLE_CHOICE_VALUE && oldValue !== HIDEABLE_CHOICE_VALUE) {
    fixedChoice = assoc('hidden', false, newChoice);
  } else if (newValue !== HIDEABLE_CHOICE_VALUE && oldValue === HIDEABLE_CHOICE_VALUE) {
    fixedChoice = dissoc('hidden', newChoice);
  }
  return fixedChoice;
};

export const updateChoiceValueHandler = (state, { modelPath, itemId, newValue }) => {
  const choicePath = [...PATHS.surveyDefinition, ...modelPath];
  const choiceValuePath = [...choicePath, 'value'];
  const oldValue = path(choiceValuePath, state);

  let newChoice = path(choicePath, state);
  newChoice = fixHidden(oldValue, newValue, newChoice);
  newChoice = assoc('value', newValue, newChoice);

  const translations = path([...PATHS.translations], state);
  const newTranslations = map((translation) => fixValue(translation, itemId, oldValue, newValue), translations);
  return pipe(assocPath(choicePath, newChoice), assocPath(PATHS.translations, newTranslations))(state);
};

export const toggleSetupOfRedirect = (state, { isRedirect, itemPath }) => {
  let newState;
  const redirectPath = [...getCompleteSurveyDefinitionPath(itemPath), ...PATHS.componentProps];
  if (isRedirect) {
    newState = assocPath(
      redirectPath,
      {
        isRedirect: true,
        redirectSeconds: 5,
      },
      state
    );
  } else {
    newState = dissocPath(redirectPath, state);
  }
  return newState;
};

export const appendConditionalTextHandler = (state, { itemPath }) => {
  const completeItemPath = getCompleteSurveyDefinitionPath(itemPath);
  const itemId = path([...completeItemPath, 'id'], state);

  return pipe(
    over(lensPath([...completeItemPath, 'conditionalTexts']), append({})),
    over(translationsLens, map(over(lensPath([itemId, 'conditionalTexts']), append({}))))
  )(state);
};

export const updateConditionalTextHandler = (state, { itemPath, index, condition }) => {
  const completeItemPath = getCompleteSurveyDefinitionPath(itemPath);

  return assocPath([...completeItemPath, 'conditionalTexts', index], condition, state);
};

export const moveConditionalTextHandler = (state, { itemPath, dragIndex, hoverIndex }) => {
  const completeItemPath = getCompleteSurveyDefinitionPath(itemPath);
  const itemId = path([...completeItemPath, 'id'], state);

  return pipe(
    over(
      translationsLens,
      map(over(lensPath([itemId, 'conditionalTexts']), when(is(Array), move(dragIndex, hoverIndex))))
    ),
    over(lensPath([...completeItemPath, 'conditionalTexts']), move(dragIndex, hoverIndex))
  )(state);
};

export const removeConditionalTextHandler = (state, { itemPath, index }) => {
  const completeItemPath = getCompleteSurveyDefinitionPath(itemPath);
  const itemId = path([...completeItemPath, 'id'], state);

  return pipe(
    over(translationsLens, map(over(lensPath([itemId, 'conditionalTexts']), when(is(Array), remove(index, 1))))),
    over(lensPath([...completeItemPath, 'conditionalTexts']), remove(index, 1))
  )(state);
};
