export const PATHS = {
  root: ['undoable', 'present', 'survey'],
  surveyDefinition: ['definition'],
  translations: ['translations'],
  masterMetaData: ['masterMetaData'],
  metadata: ['metadata'],
  surveySettings: ['metadata', 'surveySettings'],
  navigationAutoNext: ['metadata', 'surveySettings', 'navigationAutoNext'],
  mandatory: ['masterMetaData', 'mandatory'],
  dependency: ['masterMetaData', 'dependency'],
  hookOptions: ['hookOptions'],
  beforeExitQuestion: ['hookOptions', 'beforeExitQuestion'],
  componentProps: ['componentProps'],
  autofocus: ['componentProps', 'autofocus'],
  textFieldRows: ['componentProps', 'rows'],
  optionalQuestion: ['optional'],
  dynamicSquareCount: ['componentProps', 'count'],
  hiddenIntro: ['componentProps', 'hiddenIntro'],
  isRedirect: ['componentProps', 'isRedirect'],
  showLanguageSwitch: ['metadata', 'surveySettings', 'showLanguageSwitch'],
  questionVisualType: ['questionVisualType'],
};

export const getPathWithRoot = (innerPath = []) => [...PATHS.root, ...innerPath];
