import Paper from '@material-ui/core/Paper/index';
import classNames from 'classnames';
import { pipe } from 'commity-rehook-fork';
import * as PropTypes from 'prop-types';
import { omit } from 'ramda';
import React from 'react';
import { DragSource, DropTarget } from 'react-dnd';
import { connect } from 'react-redux';
import { withStylesAsClasses } from '../../rehooks';
import { getFlattenMasterQuestions } from '../../state/modules/editor';
import { moveQuestion } from '../../state/modules/survey/overview';
import { DraggableTypes, getDraggableId, getDroppableClassName } from '../dndHelper';
import { findTranslationItemInCurrentTranslation } from '../translations/translationHelper';
import { ItemWrapper } from './ItemWrapper';
import { PageSelectablePart } from './PageSelectablePart';

const styles = (theme) => ({
  root: {},

  dndContainer: {
    cursor: 'move',
    display: 'flex',
    backgroundColor: theme.palette.background.primary,
    margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px 0 0 `,
  },

  elementsContainer: {
    display: 'flex',
  },

  dndPaper: {
    padding: theme.spacing.unit / 2,
    backgroundColor: theme.palette.background.primary,
    display: 'flex',
    alignItems: 'center',
  },

  dndHandle: {
    userSelect: 'none',
    padding: theme.spacing.unit / 2,
  },

  itemWrapper: {
    flex: 1,
    width: '96%',
  },

  canDrop: {
    backgroundColor: theme.palette.background.dndCanDrop,
  },
  cannotDrop: {
    backgroundColor: theme.palette.background.dndCannotDrop,
  },
});

const PageHeaderPure = ({
  item,
  survey,
  classes,
  modelPath,
  selectedItemPath,
  connectDragSource,
  connectDropTarget,
  isOver,
  canDrop,
  flattenMasterQuestions,
  ...others
}) => {
  const iconPanelConfig = {
    showDeleteIcon: true,
  };

  const dropTargetClassName = getDroppableClassName(classes, isOver, canDrop);

  return (
    <div {...others} className={classNames(classes.root, others.className)}>
      <div className={classes.elementsContainer}>
        <>
          {connectDropTarget(
            connectDragSource(
              <div className={classes.dndContainer}>
                <Paper className={classNames(classes.dndPaper, dropTargetClassName)}>
                  <div className={classes.dndHandle}>:::</div>
                </Paper>
              </div>
            )
          )}
        </>
        <div className={classes.itemWrapper}>
          <PageSelectablePart item={item} modelPath={modelPath} selectedItemPath={selectedItemPath} />

          {item.elements.map((element, index) => {
            const elementPath = [...modelPath, 'elements', index];
            const draggableId = getDraggableId(elementPath);
            return (
              <ItemWrapper
                index={index}
                draggableRoot={[...modelPath, 'elements']}
                iconPanel={iconPanelConfig}
                selectedItemPath={selectedItemPath}
                parentPageModelPath={modelPath}
                flattenMasterQuestions={flattenMasterQuestions}
                modelPath={elementPath}
                key={draggableId}
                item={element}
                translationItem={findTranslationItemInCurrentTranslation(survey, element)}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

const droppableTarget = {
  drop: (props, monitor) => {
    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.index;

    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      return;
    }
    props.onDrop({
      destination: {
        index: hoverIndex,
        draggableRoot: props.draggableRoot,
      },
      source: {
        index: dragIndex,
        draggableRoot: monitor.getItem().draggableRoot,
      },
    });
  },
};

const droppableCollect = (dndConnect, monitor) => {
  return {
    connectDropTarget: dndConnect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop(),
  };
};

const draggableSource = {
  beginDrag({ index, draggableRoot, item }) {
    return {
      index,
      draggableRoot,
      item,
    };
  },
};

const draggableCollect = (dndConnect, monitor) => {
  return {
    connectDragSource: dndConnect.dragSource(),
    isDragging: monitor.isDragging(),
  };
};

const PageHeader = pipe(withStylesAsClasses(styles), omit(['draggableRoot', 'isDragging']), PageHeaderPure);

const DraggablePageHeaderPure = DropTarget(
  DraggableTypes.QUESTION,
  droppableTarget,
  droppableCollect
)(DragSource(DraggableTypes.QUESTION, draggableSource, draggableCollect)(PageHeader));

const mapStateToProps = (state) => ({
  flattenMasterQuestions: getFlattenMasterQuestions(state),
});
const mapDispatchToProps = (dispatch) => {
  return {
    onDrop: (result) => {
      if (!result.destination) {
        // dropped outside the list
        return;
      }
      dispatch(moveQuestion(result));
    },
  };
};

export const DraggablePageHeader = connect(mapStateToProps, mapDispatchToProps)(DraggablePageHeaderPure);

DraggablePageHeader.propTypes = {
  item: PropTypes.shape({
    elements: PropTypes.array.isRequired,
    type: PropTypes.string.isRequired,
  }),
  survey: PropTypes.shape({}).isRequired,
  modelPath: PropTypes.array.isRequired,
  selectedItemPath: PropTypes.array,
  draggableRoot: PropTypes.array.isRequired,
};
