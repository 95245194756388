import { IconButton, Paper, Typography } from '@material-ui/core';
import { ArrowForward, Check, Warning } from '@material-ui/icons';
import classNames from 'classnames';
import { pipe, withHandlers, withStateHandlers } from 'commity-rehook-fork';
import { Help } from 'mdi-material-ui';
import * as PropTypes from 'prop-types';
import { join, omit, pluck, prop } from 'ramda';
import React from 'react';
import { isPage } from 'xperience-model-management';
import { withStylesAsClasses } from '../../rehooks';

const styles = theme => ({
  root: {},

  dndPaper: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing.unit,
    minHeight: 42,
    backgroundColor: theme.palette.background.primary,
    marginTop: theme.spacing.unit / 2,
  },

  hover: {
    '&:hover': {
      backgroundColor: theme.palette.background.highlight,
    },
  },

  statusIcon: {
    marginRight: theme.spacing.unit / 2,
  },

  statusIconOptional: {
    marginRight: theme.spacing.unit / 2,
    fontSize: 20,
  },

  id: {
    userSelect: 'none',
    paddingRight: theme.spacing.unit,
  },

  text: {
    userSelect: 'none',
    flexGrow: 1,
  },

  arrowIcon: {
    margin: 0,
    padding: 0,

    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.primary.A200,
    },
  },
});

const QuestionBankItemHeaderPure = ({
  classes,
  item,
  translationItem,
  onClick,
  showIconPanel,
  hideIconPanel,
  iconPanelVisible,
  questionAlreadyInSurvey,
  mandatory,
  allMandatoryQuestionsFromPageInSurvey,
  ...others
}) => {
  const getItemProperties = () => {
    if (questionAlreadyInSurvey) {
      return {
        IconComponent: Check,
        title: 'Question is already in survey',
        className: classes.statusIcon,
      };
    }
    if (mandatory && !allMandatoryQuestionsFromPageInSurvey) {
      return {
        IconComponent: Warning,
        color: 'error',
        title: 'MISSING MANDATORY QUESTION, must be inserted into survey.',
        className: classes.statusIcon,
      };
    }
    return {
      IconComponent: Help,
      className: classes.statusIconOptional,
      color: 'primary',
      title: 'OPTIONAL QUESTION, can be inserted into survey.',
    };
  };

  const iconVisible = iconPanelVisible && !questionAlreadyInSurvey;
  let itemLabelText;
  let id;
  if (isPage(item)) {
    itemLabelText = pipe(
      prop('elements'),
      pluck('id'),
      join(', ')
    )(item);
    id = 'PAGE';
  } else {
    itemLabelText = translationItem && translationItem.text ? translationItem.text : '';
    id = item.id;
  }
  const title = `${id}. ${itemLabelText}`;
  const itemProperties = getItemProperties();
  const { IconComponent } = itemProperties;

  return (
    <div className={classes.root}>
      <Paper
        square
        {...others}
        title={title}
        className={classNames(classes.dndPaper, others.className)}
        onMouseEnter={showIconPanel}
        onMouseLeave={hideIconPanel}>
        <IconComponent
          className={itemProperties.className}
          color={itemProperties.color}
          titleAccess={itemProperties.title}
        />
        <Typography variant="subtitle2" className={classes.id}>
          {id}.
        </Typography>
        <Typography noWrap className={classes.text}>
          {itemLabelText || (translationItem && translationItem.thanksMessage)}
        </Typography>
        {iconVisible && (
          <IconButton
            aria-label="Insert Question"
            title="Insert Question"
            className={classes.arrowIcon}
            onClick={onClick}>
            <ArrowForward fontSize="small" />
          </IconButton>
        )}
      </Paper>
    </div>
  );
};

export const QuestionBankItemHeader = pipe(
  withStateHandlers(
    { iconPanelVisible: false }, // state initialization
    {
      showIconPanel: () => () => ({ iconPanelVisible: true }),
      hideIconPanel: () => () => ({ iconPanelVisible: false }),
    }
  ),
  withHandlers({
    onClick: ({ modelPath, onSelect, isSelected }) => () => onSelect(isSelected ? null : modelPath),
  }),
  withStylesAsClasses(styles),
  omit(['modelPath', 'onDelete', 'onSelect', 'translationPath', 'draggableRoot', 'isDragging']),
  QuestionBankItemHeaderPure
);

QuestionBankItemHeader.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string,
    text: PropTypes.string,
  }),
  questionAlreadyInSurvey: PropTypes.bool,
  translationItem: PropTypes.shape({}),
  modelPath: PropTypes.array.isRequired,
  mandatory: PropTypes.bool.isRequired,
  allMandatoryQuestionsFromPageInSurvey: PropTypes.bool.isRequired,
};
