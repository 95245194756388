import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import classNames from 'classnames';
import { pipe, withHandlers, withPropsOnChange, withStateHandlers } from 'commity-rehook-fork';
import * as PropTypes from 'prop-types';
import {
  anyPass,
  append,
  assoc,
  assocPath,
  concat,
  dissocPath,
  dropLast,
  filter,
  lensIndex,
  lensPath,
  omit,
  over,
  path as ramdaPath,
  prop,
  propEq,
  reduce,
  set,
  toUpper,
  when,
} from 'ramda';
import { isPlainObj, mapIndexed } from 'ramda-adjunct';
import React from 'react';
import { connect } from 'react-redux';
import { isDynamicSquare, isGender, isMulti, isSingle, isSquare, isStar } from 'xperience-model-management';
import { withStylesAsClasses } from '../../../rehooks';
import { getPlaceholders, getSelectedItemPath } from '../../../state/modules/editor';
import { getFlatSurvey } from '../../../state/modules/survey/overview/index';
import { transformPlaceholderForSelect } from '../../placeholderHelper';
import { questionsBeforeGivenQuestion, transformQuestionsForSelect } from '../../questionHelper';
import { transformForEditor } from './conditionsFormat';
import { ConditionsRow } from './ConditionsRow';

const styles = (theme) => ({
  dialog: {
    overflow: 'visible',
    maxWidth: '1110px',
  },

  dialogTitle: {
    '& h6': {
      color: theme.palette.background.warning,
      display: 'flex',
      justifyContent: 'space-between',
    },
  },

  conditionSelect: {
    color: theme.palette.primary.A900,
  },

  dialogContent: { minHeight: 200, overflow: 'visible' },
  addCondition: {
    marginTop: theme.spacing.unit * 3,
  },

  andConditionButtonNested: {
    marginRight: theme.spacing.unit,
    color: theme.palette.primary.main,
  },

  orConditionButtonNested: {
    marginRight: theme.spacing.unit,
    color: theme.palette.orange,
    borderColor: '#C66F3660',
    '&:hover': {
      borderColor: theme.palette.orange,
      backgroundColor: '#C66F3614',
    },
  },

  conditionsRow: {
    margin: `${theme.spacing.unit}px 0`,
  },
  conditionsContainer: {
    ...theme.primaryBorder,
    margin: `${theme.spacing.unit * 2}px ${theme.spacing.unit / 2}px`,
    paddingBottom: theme.spacing.unit,
  },
  primaryConditionBorder: {
    border: `${theme.spacing.unit / 4}px solid ${theme.palette.primary.main}`,
  },

  secondaryConditionBorder: {
    borderColor: theme.palette.primary.main,
  },

  textAnd: {
    width: '100%',
    textAlign: 'center',
    borderBottom: theme.primaryBorder.border,
    lineHeight: '0.1em',
  },
  spanAnd: {
    backgroundColor: theme.palette.background.conditionModal,
    color: theme.palette.primary.main,
    padding: `0 ${theme.spacing.unit}px`,
  },
  spanOr: {
    backgroundColor: theme.palette.background.conditionModal,
    color: theme.palette.orange,
    padding: `0 ${theme.spacing.unit}px`,
  },
});

const ConditionsModalPure = ({
  classes,
  conditions,
  deleteRow,
  editRow,
  flatSurveyAndSampleDataOptions,
  changeType,
  item,
  itemPath,
  onClose,
  onDialogClose,
  onSave,
  setNewRow,
  setValid,
  valid,
  typeIsDisabled = false,
  ...others
}) => {
  let path = [null];
  const pathIncrement = over(lensIndex(-1), (k) => (k === null ? 0 : k + 1));
  const rowComponent = (row, index = 0, operation = 'and') => {
    path = pathIncrement(path);
    const spanCaptionClass = operation === 'and' ? classes.spanAnd : classes.spanOr;
    return (
      <div key={path}>
        {index !== 0 && (
          <Typography variant="h5" className={classes.textAnd}>
            <span className={spanCaptionClass}>{toUpper(operation)}</span>
          </Typography>
        )}
        <ConditionsRow
          className={classes.conditionsRow}
          item={row}
          onChange={editRow(path)}
          onDeleteClick={deleteRow(path)}
          flatSurveyAndSampleDataOptions={flatSurveyAndSampleDataOptions}
          setNewRow={setNewRow(path)}
          operationName={operation}
        />
      </div>
    );
  };
  const nestedRows = (operation) => (row, index) => {
    const spanCaptionClass = operation === 'and' ? classes.spanAnd : classes.spanOr;
    if (row.rows) {
      const conditionButtonClass =
        operation === 'and' ? classes.orConditionButtonNested : classes.andConditionButtonNested;
      path = [...pathIncrement(path), 'rows', null];
      const items = (
        <div key={path}>
          {index !== 0 && (
            <Typography variant="h5" className={classes.textAnd}>
              <span className={spanCaptionClass}>{toUpper(operation)}</span>
            </Typography>
          )}
          <div className={classNames(classes.conditionsContainer, classes.secondaryConditionBorder)}>
            {mapIndexed(nestedRows(row.operation), row.rows)}
            <Grid container direction="row" justify="flex-end" className={classes.addCondition}>
              <Button
                className={conditionButtonClass}
                color="primary"
                variant="outlined"
                title="Add condition"
                aria-label="Add"
                onClick={setNewRow(path)(row.operation)}>
                Add {toUpper(row.operation)} condition
              </Button>
            </Grid>
          </div>
        </div>
      );
      path = dropLast(2, path);
      return items;
    }
    return rowComponent(row, index, operation);
  };

  const rowsWitSeparator = reduce((acc, row) => {
    let nestedItems;
    if (row.rows) {
      const conditionButtonClass =
        row.operation === 'and' ? classes.andConditionButtonNested : classes.orConditionButtonNested;
      path = [...pathIncrement(path), 'rows', null];
      nestedItems = [
        ...mapIndexed(nestedRows(row.operation), row.rows),
        <Grid key={`grid-${path}`} container direction="row" justify="flex-end" className={classes.addCondition}>
          <Button
            className={conditionButtonClass}
            color="primary"
            variant="outlined"
            title="Add condition"
            aria-label="Add"
            onClick={setNewRow(path)(row.operation)}>
            Add {toUpper(row.operation)} condition
          </Button>
        </Grid>,
      ];
      path = dropLast(2, path);
    }
    return [
      ...acc,
      <div key={`${path}-${acc.length}`}>
        {acc.length !== 0 && (
          <Typography variant="h5" className={classes.textAnd}>
            <span className={classes.spanAnd}>AND</span>
          </Typography>
        )}
        <div className={classNames(classes.conditionsContainer, classes.primaryConditionBorder)}>
          {nestedItems || rowComponent(row)}
        </div>
      </div>,
    ];
  }, []);

  return (
    <Dialog
      {...others}
      classes={{ paper: classNames(classes.dialog, others.className) }}
      fullWidth
      maxWidth={'md'}
      open
      scroll={'body'}
      onClose={onClose}
      aria-labelledby="max-width-dialog-title">
      <DialogTitle id="max-width-dialog-title" className={classes.dialogTitle}>
        <Select
          onChange={changeType}
          value={conditions.type}
          className={classes.conditionSelect}
          disabled={typeIsDisabled}>
          <MenuItem value={'visibleIf'}>Visible if</MenuItem>
          <MenuItem value={'hideIf'}>Hide if</MenuItem>
        </Select>
        {!valid && <span>All fields are required</span>}
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {conditions.rows.length > 0 && (
          <Grid container direction="column" justify="center">
            {rowsWitSeparator(conditions.rows)}
          </Grid>
        )}
        <Grid container direction="row" justify="flex-end" className={classes.addCondition}>
          <Button
            color="primary"
            variant="outlined"
            title="Add condition"
            aria-label="Add"
            onClick={setNewRow([])('and')}>
            Add AND condition
          </Button>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onDialogClose} color="primary">
          Cancel
        </Button>
        <Button onClick={onSave(itemPath, item, conditions, onDialogClose, setValid)} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const isEmptyRows = propEq('rows', []);

const appendNewRow = (path) =>
  over(
    lensPath(['rows', ...dropLast(1, path)]),
    append({
      isNegation: 0,
      condition: 'equal',
    })
  );

const initConditions = pipe(prop('item'), transformForEditor, when(isEmptyRows, appendNewRow([0])));

const reduceNesting = (path, conditions, item) => {
  return set(lensPath(path), item, conditions);
};

const deleteNestedEmptyRows = (path, conditions) => {
  const newConditions = dissocPath(path, conditions);
  const parentPath = dropLast(2, path);
  const parent = ramdaPath(parentPath, newConditions);
  if (!parent.type && parent.rows.length === 1) {
    return reduceNesting(parentPath, newConditions, parent.rows[0]);
  }
  if (!parent.type && parent.rows.length === 0) {
    return deleteNestedEmptyRows(parentPath, newConditions);
  }
  return newConditions;
};

const respondedWithoutSecondValue = when(propEq('condition', 'responded'), omit(['secondValue']));

export const ConditionsModalWithPipe = pipe(
  withStateHandlers(
    (props) => {
      return {
        conditions: initConditions(props),
        valid: true,
      };
    },
    {
      updateConditions: (state) => (conditions) => assoc('conditions', conditions, state),
      setValid: (state) => (valid) => assoc('valid', valid, state),
    }
  ),
  withHandlers({
    setNewRow: ({ conditions, updateConditions }) => (path) => (operation) => () => {
      const parent = ramdaPath(dropLast(2, ['rows', ...path]), conditions);
      const parentOperation = parent.operation || 'and';
      const current = ramdaPath(['rows', ...path], conditions);
      if (isPlainObj(parent) && parentOperation !== operation) {
        return updateConditions(
          set(
            lensPath(['rows', ...path]),
            {
              operation,
              rows: [
                current,
                {
                  isNegation: 0,
                  condition: 'equal',
                },
              ],
            },
            conditions
          )
        );
      }

      return updateConditions(appendNewRow(path)(conditions));
    },
    editRow: ({ conditions, updateConditions }) => (path) => (row) => {
      const validRow = respondedWithoutSecondValue(row);
      updateConditions(assocPath(['rows', ...path], validRow, conditions));
    },
    deleteRow: ({ conditions, updateConditions }) => (path) => () => {
      let currentConditions;
      const parentPath = dropLast(2, ['rows', ...path]);
      const parent = ramdaPath(parentPath, conditions);
      if (!parent.type && parent.rows.length < 2) {
        currentConditions = deleteNestedEmptyRows(parentPath, conditions);
      } else {
        currentConditions = dissocPath(['rows', ...path], conditions);
        const parentPathNext = dropLast(2, ['rows', ...path]);
        const parentNext = ramdaPath(parentPathNext, currentConditions);
        if (!parentNext.type && parentNext.rows.length === 1) {
          currentConditions = reduceNesting(parentPathNext, currentConditions, parentNext.rows[0]);
        }
      }
      updateConditions(currentConditions);
    },
    changeType: ({ conditions, updateConditions }) => ({ target }) => {
      updateConditions(assoc('type', target.value, conditions));
    },
  }),
  withPropsOnChange(['flatSurvey', 'placeholders'], (props) => {
    const questionsBeforeCurrent = questionsBeforeGivenQuestion(props.flatSurvey, props.item.id, ['intro']);
    const questionsForSelect = filter(
      anyPass([isSingle, isMulti, isGender, isStar, isSquare, isDynamicSquare]),
      questionsBeforeCurrent
    );
    return {
      flatSurveyAndSampleDataOptions: concat(
        transformQuestionsForSelect(questionsForSelect),
        transformPlaceholderForSelect(props.placeholders)
      ),
    };
  }),
  withStylesAsClasses(styles),
  omit(['updateConditions', 'flatSurvey', 'placeholders']),
  ConditionsModalPure
);

const mapStateToProps = (state) => ({
  placeholders: getPlaceholders(state),
  itemPath: getSelectedItemPath(state),
  flatSurvey: getFlatSurvey(state),
});

export const ConditionsModal = connect(mapStateToProps)(ConditionsModalWithPipe);

ConditionsModal.propTypes = {
  typeIsDisabled: PropTypes.bool,
  conditionsRows: PropTypes.array,
  onDialogClose: PropTypes.func,
  onSave: PropTypes.func.isRequired,
};
ConditionsModal.defaultProps = {};
