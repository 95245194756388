import {
  append,
  assoc,
  assocPath,
  filter,
  has,
  keys,
  map,
  mergeRight,
  pick,
  pipe,
  prop,
  propOr,
  reduce,
  when,
} from 'ramda';
import { isArray } from 'ramda-adjunct';
import { flatQuestionTreeWithPaths } from 'xperience-model-management';

export const transformChoicesInTranslation = when(
  isArray,
  reduce((acc, item) => {
    return assoc(item.value, { text: item.text }, acc);
  }, {})
);

const hasChoices = has('choices');

const transformTranslationsToV2 = translations => {
  const choiceToV2Reducer = (acc, choice) => assoc(choice.value, { text: choice.text }, acc);
  const transformChoices = choices => reduce(choiceToV2Reducer, {}, choices);
  const transformTranslationItem = item => {
    if (hasChoices(item)) {
      return assoc('choices', transformChoices(item.choices), item);
    }
    return item;
  };
  const transformTranslation = map(transformTranslationItem);
  return map(transformTranslation, translations);
};

const transformDefinitionToV2 = ({ translations, definition }) => {
  const firstLanguage = keys(translations)[0];
  const firstTranslation = translations[firstLanguage];
  const flatQuestions = flatQuestionTreeWithPaths(definition);
  const getChoicesValue = pipe(
    propOr([], 'choices'),
    map(pick(['value']))
  );
  const getPathToChoices = pipe(
    prop('path'),
    append('choices')
  );
  const mergeDefinitionWithTranslation = map(question => {
    return mergeRight(question, firstTranslation[question.id]);
  });

  const questionsWithChoices = pipe(
    mergeDefinitionWithTranslation,
    filter(hasChoices)
  )(flatQuestions);

  return reduce(
    (acc, question) => {
      return assocPath(getPathToChoices(question), getChoicesValue(question), acc);
    },
    definition,
    questionsWithChoices
  );
};

export const transformSurveyToV2 = survey => {
  const transformedTranslations = transformTranslationsToV2(survey.translations);
  const transformedDefinition = transformDefinitionToV2(survey);
  return {
    ...survey,
    translations: transformedTranslations,
    definition: transformedDefinition,
  };
};
