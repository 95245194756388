import { Paper, Typography } from '@material-ui/core';
import classNames from 'classnames';
import { mapProps, pipe } from 'commity-rehook-fork';
import { assoc, prop } from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { defaultCommonUITranslations, flatQuestionTree } from 'xperience-model-management';
import { memoize, withStylesAsClasses } from '../../rehooks';
import { getSelectedTargetLanguage, isMaster } from '../../state/modules/editor';
import { getSurveyDefinition } from '../../state/modules/survey/overview';
import { getSourceTranslation, getTargetTranslation } from '../../state/modules/survey/translations';
import { TranslationProperty } from './propertyComponents/TranslationProperty';
import { flatToKeysWithDotsPath } from './translationHelper';
import { TranslationItem } from './TranslationItem';

const styles = (theme) => ({
  root: {
    cursor: 'default',
    overflow: 'auto',
  },

  title: {
    padding: theme.spacing.unit * 2,
  },

  paperItem: {
    margin: theme.spacing.unit,
  },
});

const TranslationOverviewPure = ({
  flatDefinition,
  isMasterSurvey,
  sourceTranslation,
  targetTranslation,
  targetLanguage,
  defaultCommonFlatTranslations,
  classes,
  ...others
}) => {
  return (
    <div {...others} className={classNames(classes.root, others.className)}>
      {!isMasterSurvey &&
        flatDefinition.map((item, index) => {
          return (
            sourceTranslation[item.id] && (
              <Paper key={index} className={classes.paperItem}>
                <Typography variant="h6" className={classes.title}>
                  {item.id}
                </Typography>
                <TranslationItem
                  inlineItem={item}
                  sourceTranslationItem={sourceTranslation[item.id]}
                  targetTranslationItem={targetTranslation[item.id]}
                  translationPath={[targetLanguage, item.id]}
                />
              </Paper>
            )
          );
        })}
      {sourceTranslation.common && (
        <Paper className={classes.paperItem}>
          <Typography variant="h6" className={classes.title}>
            UI translations
            {Object.keys(defaultCommonFlatTranslations).map((key, index) => {
              return (
                <TranslationProperty
                  key={index}
                  translationPath={[targetLanguage, 'common', ...key.split('.')]}
                  sourcePropertyValue={sourceTranslation.common[key] || defaultCommonFlatTranslations[key]}
                  targetPropertyValue={targetTranslation.common[key]}
                  propertyLabel={key}
                />
              );
            })}
          </Typography>
        </Paper>
      )}
    </div>
  );
};

const StyledTranslationOverview = pipe(
  mapProps(({ sourceTranslation, targetTranslation, ...others }) => ({
    defaultCommonFlatTranslations: flatToKeysWithDotsPath(defaultCommonUITranslations),
    sourceTranslation: assoc('common', flatToKeysWithDotsPath(prop('common', sourceTranslation)), sourceTranslation),
    targetTranslation: assoc('common', flatToKeysWithDotsPath(prop('common', targetTranslation)), targetTranslation),
    ...others,
  })),
  withStylesAsClasses(styles),
  memoize(TranslationOverviewPure)
);

const mapStateToProps = (state) => {
  return {
    flatDefinition: flatQuestionTree(getSurveyDefinition(state)),
    sourceTranslation: getSourceTranslation(state),
    targetTranslation: getTargetTranslation(state),
    targetLanguage: getSelectedTargetLanguage(state),
    isMasterSurvey: isMaster(state),
  };
};

const mapDispatchToProps = () => ({});

export const TranslationOverview = connect(mapStateToProps, mapDispatchToProps)(StyledTranslationOverview);
