import classNames from 'classnames';
import { pipe } from 'commity-rehook-fork';
import * as PropTypes from 'prop-types';
import React from 'react';
import { withStylesAsClasses } from '../../../../rehooks';
import { ChoicesList } from '../choices/ChoicesList';

const styles = () => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
});

const SingleChoiceQuestionFormPure = ({
  item,
  translationItem,
  translationPath,
  classes,
  modelPath,
  config,
  readOnly,

  ...others
}) => {
  return (
    <div {...others} className={classNames(classes.root, others.className)}>
      <ChoicesList
        modelPath={modelPath}
        item={item}
        choicesTranslations={translationItem && translationItem.choices}
        translationPath={translationPath}
        config={config}
        readOnly={readOnly}
      />
    </div>
  );
};

export const SingleChoiceQuestionForm = pipe(
  withStylesAsClasses(styles),
  SingleChoiceQuestionFormPure
);

SingleChoiceQuestionForm.propTypes = {
  item: PropTypes.shape({}),
  modelPath: PropTypes.array.isRequired,
  config: PropTypes.shape({
    addChoice: PropTypes.bool,
    deleteChoice: PropTypes.bool,
  }),
  readOnly: PropTypes.bool,
};
