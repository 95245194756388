import {
  assocPath,
  defaultTo,
  filter,
  findIndex,
  has,
  lensPath,
  lensProp,
  over,
  path,
  pipe,
  pluck,
  propEq,
  reduce,
} from 'ramda';
import { flatQuestionTreeWithPaths } from 'xperience-model-management';
import { HIDEABLE_CHOICE_VALUE } from '../../../components/questionHelper';

const HIDDEN = 'hidden';
const CHOICES = 'choices';

const hasHideableChoice = item => {
  if (!has(CHOICES)) {
    return false;
  }
  const choices = defaultTo([], item.choices);
  const specificChoiceIndex = findHideableChoiceIndex(choices);
  return specificChoiceIndex > -1 && !has(HIDDEN, path([specificChoiceIndex], choices));
};

const getPathsToProcess = pipe(
  flatQuestionTreeWithPaths,
  filter(hasHideableChoice),
  pluck('path')
);

const findHideableChoiceIndex = findIndex(propEq('value', HIDEABLE_CHOICE_VALUE));

const assocHiddenFalseToHideableChoice = item => {
  const pathToHidden = [CHOICES, findHideableChoiceIndex(item.choices), HIDDEN];
  return assocPath(pathToHidden, false, item);
};

const updateChoicesInDefinition = (definition, pathToHiddenProp) =>
  over(lensPath(pathToHiddenProp), assocHiddenFalseToHideableChoice, definition);

const assocMissingHiddenPropertyToChoices = definition =>
  pipe(
    getPathsToProcess,
    reduce(updateChoicesInDefinition, definition)
  )(definition);

export const addHiddenFalseToHideableChoices = survey =>
  over(lensProp('definition'), assocMissingHiddenPropertyToChoices, survey);
