import { TextField } from '@material-ui/core';
import classNames from 'classnames';
import { pipe, withHandlers } from 'commity-rehook-fork';
import * as PropTypes from 'prop-types';
import { omit } from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { memoizeWithEquals, withStylesAsClasses } from '../../../rehooks';
import { getTranslationPathForItem, updateTranslation } from '../../../state/modules/survey/translations';
import { createTranslationOnChangeHandler } from '../../handlerHelper';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing.unit,
  },
  textField: {},
});

/**
 * const model = {
      "type": "personalAgreement",
      "id": "QP",
      "hookOptions": {
        "suppressEscape": true
      },
      "thanksMessage": "We appreciate that you shared your experience with us!\nWilling to share some more?",
      "continueText": "We would like to ask you few more specific questions for marketing purposes. Based on your answers you may be contacted with additional offers",
      "continueButtonText": "Next question »",
      "terminateButtonText": "End",
      "agreement": {
        "text": "I agree to the processing of my personal data for the purpose of the marketing offer",
        "errorMessage": "Consent is required for continuation"
      }
    };
 */

const PersonalAgreementFormPure = ({ item, translationItem, onThanksMessageChange, classes, readOnly, ...others }) => {
  return (
    <div {...others} className={classNames(classes.root, others.className)}>
      <TextField
        id="thanksMessage"
        label="Thanks Message"
        placeholder="... insert text"
        multiline
        value={translationItem && translationItem.thanksMessage}
        onChange={onThanksMessageChange}
        className={classes.textField}
        margin="normal"
        disabled={readOnly}
      />
    </div>
  );
};

const StyledPersonalAgreementForm = pipe(
  withHandlers({
    onThanksMessageChange: createTranslationOnChangeHandler(['thanksMessage']),
  }),
  withStylesAsClasses(styles),
  omit(['parentPageModelPath', 'modelPath', 'onTranslationChange', 'translationPath']),
  memoizeWithEquals(PersonalAgreementFormPure)
);

const mapStateToProps = (state, { item }) => ({
  translationPath: getTranslationPathForItem(state, item),
});

const mapDispatchToProps = (dispatch) => {
  return {
    onTranslationChange: ({ translationPath, value }) => {
      dispatch(updateTranslation(translationPath, value));
    },
  };
};

export const PersonalAgreementForm = connect(mapStateToProps, mapDispatchToProps)(StyledPersonalAgreementForm);

PersonalAgreementForm.propTypes = {
  item: PropTypes.shape({}),
  readOnly: PropTypes.bool,
};
