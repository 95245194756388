/* eslint no-shadow: "off" */

import { all, eqProps, intersection, isEmpty, keys, propEq, propOr } from 'ramda';
import { combineReducers } from 'redux';
import { combineEpics } from 'redux-observable';
import undoable from 'redux-undo';

import editor, { epics as editorEpics } from './editor';
import { SELECT_ITEM } from './editor/handlers';
import survey, { epics as surveyEpics } from './survey';
import { UPDATE_ITEM } from './survey/overview';
import { UPDATE_TRANSLATION } from './survey/translations';
import validations, { epics as validationsEpics } from './validations';

const GROUPABLE_ACTIONS = {
  [UPDATE_ITEM]: ['itemPath'],
  [UPDATE_TRANSLATION]: ['translationPath'],
  [SELECT_ITEM]: [],
};

export const actionGroupingFactory = groupableActions => {
  let lastGroupedAction = {};

  const getGroupableActionsByType = type => propOr(null, type, groupableActions);
  const getGroupableProps = (matchingActionProps, action) => intersection(keys(action), matchingActionProps);
  const shouldProcessAction = (previousHistory, actionType) => !propEq('group', actionType, previousHistory);

  return (action, currentState, previousHistory) => {
    const matchingActionProps = getGroupableActionsByType(action.type);

    if (!matchingActionProps) {
      return null;
    }

    if (shouldProcessAction(previousHistory, action.type) || isEmpty(matchingActionProps)) {
      lastGroupedAction = action;
      return action.type;
    }

    const propIsEqualBetweenActionAndLastGroupedAction = prop => eqProps(prop, action, lastGroupedAction);

    const hasEqualActionProps = (matchingActionProps, action) => {
      const matchedActionProps = getGroupableProps(matchingActionProps, action);

      return (
        matchedActionProps.length === matchingActionProps.length &&
        all(propIsEqualBetweenActionAndLastGroupedAction, matchedActionProps)
      );
    };

    if (hasEqualActionProps(matchingActionProps, action)) {
      lastGroupedAction = action;
      return action.type;
    }

    return null;
  };
};

export const rootReducer = combineReducers({
  undoable: undoable(
    combineReducers({
      editor,
      survey,
    }),
    {
      groupBy: actionGroupingFactory(GROUPABLE_ACTIONS),
    }
  ),
  validations,
});

export const rootEpic = combineEpics(editorEpics, surveyEpics, validationsEpics);

export const hasUndo = ({ undoable: { past } }) => Boolean(past.length);

export const hasRedo = ({ undoable: { future } }) => Boolean(future.length);
