import { TextField } from '@material-ui/core';
import classNames from 'classnames';
import { pipe, withHandlers, withState } from 'commity-rehook-fork';
import * as PropTypes from 'prop-types';
import { includes, omit, startsWith } from 'ramda';
import React from 'react';
import { withStylesAsClasses } from '../rehooks';
import { AlertSnackbar } from './AlertSnackbar';

const styles = () => ({
  root: {},
});

const InputWithStatePure = ({
  value,
  onInnerBlur,
  onValueChange,
  classes,
  inputValue,
  setSnackbar,
  snackbar,
  type,
  readOnly,

  ...others
}) => {
  return (
    <>
      <TextField
        id="id"
        label="ID"
        type={type}
        className={classNames(classes.root, others.className)}
        value={inputValue}
        onBlur={onInnerBlur}
        onChange={onValueChange}
        margin="normal"
        disabled={readOnly}
      />
      <AlertSnackbar
        snackbarOpen={snackbar.open}
        closeSnackbar={() => setSnackbar({ open: false })}
        variant="error"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        message={snackbar.message}
      />
    </>
  );
};

export const InputWithState = pipe(
  withStylesAsClasses(styles),
  withState('snackbar', 'setSnackbar', { open: false }),
  withState('inputValue', 'setInputValue', ({ value }) => {
    return value;
  }),
  withHandlers({
    onValueChange: ({ setInputValue }) => event => {
      setInputValue(event.target.value);
    },
    onInnerBlur: ({ type, onBlur, value, setInputValue, invalidValues, setSnackbar, isMasterSurvey }) => event => {
      const isValidIdValue = val => {
        if (!isMasterSurvey && startsWith('Q', val)) {
          setSnackbar({ open: true, message: 'Id must not begin with "Q"' });
          return false;
        } else if (isMasterSurvey && !/^[QS]/.test(val)) {
          setSnackbar({ open: true, message: 'Id must begin with "Q" or "S"' });
          return false;
        } else if (includes(val, invalidValues)) {
          setSnackbar({ open: true, message: 'Invalid input or duplicated value' });
          return false;
        }
        return true;
      };
      const newValue = type === 'text' ? event.target.value : Number(event.target.value);
      if (newValue === value) {
        return;
      }
      if (!newValue || !isValidIdValue(newValue)) {
        setInputValue(value);
        return;
      }
      onBlur(newValue);
    },
  }),
  omit(['surveyDefinition', 'onBlur', 'invalidValues', 'isMasterSurvey']),
  InputWithStatePure
);

InputWithState.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.string,
  onBlur: PropTypes.func.isRequired,
  invalidValues: PropTypes.array,
  readOnly: PropTypes.bool,
  isMasterSurvey: PropTypes.bool,
};

InputWithState.defaultProps = {
  invalidValues: [],
  type: 'text',
};
