import {
  defaultCommonUITranslations,
  flatQuestionTreeWithPaths,
  translationPropsByElementTypes,
  localizationList,
} from 'xperience-model-management';
import { append, includes, keys, map, pathOr, reduce, replace, split, values } from 'ramda';
import allKeys from 'all-object-keys';
import { unparse } from 'papaparse';
import { mapIndexed } from 'ramda-adjunct';

export const arrayPreparation = (masterDefinition, { translations, definition }) => {
  const languages = keys(translations);
  const firstTranslation = translations[languages[0]];

  const masterTranslation = masterDefinition
    ? pathOr(firstTranslation, [0], values(masterDefinition.translations))
    : firstTranslation;

  const flatQuestion = flatQuestionTreeWithPaths(definition, true);
  const commonKeys = allKeys({ common: defaultCommonUITranslations });
  const defaultRows = [
    ['id', 'type', 'master', ...languages],
    ['Id', 'Type', 'Master', ...map((lang) => pathOr(lang, [lang, 'localName'], localizationList), languages)],
  ];

  const getRowsByElementType = (item) =>
    reduce(
      (acc, propertyName) => {
        const setNextRow = (getTranslationMethod, type = propertyName) => [
          item.id,
          type,
          getTranslationMethod(masterTranslation),
          ...map((lang) => getTranslationMethod(translations[lang]), languages),
        ];

        const getTranslation = pathOr('', [item.id, propertyName]);
        if (propertyName === 'choices') {
          const choiceKeys = keys(getTranslation(firstTranslation));
          return [
            ...acc,
            ...map((choice) => {
              const getTranslationNested = pathOr('', [item.id, propertyName, choice, 'text']);
              return setNextRow(getTranslationNested, `choices.${choice}.text`);
            }, choiceKeys),
          ];
        }
        if (propertyName === 'conditionalTexts') {
          if (item.conditionalTexts) {
            return [
              ...acc,
              ...mapIndexed((ii, index) => {
                const getTranslationNested = pathOr('', [item.id, propertyName, index, 'text']);
                return setNextRow(getTranslationNested, `conditionalTexts.${index}.text`);
              }, item.conditionalTexts),
            ];
          }
          return acc;
        }
        if (includes(propertyName, ['agreement', 'optOut', 'redirect'])) {
          const propKeys = keys(getTranslation(firstTranslation));
          return [
            ...acc,
            ...map((propKey) => {
              const getTranslationNested = pathOr('', [item.id, propertyName, propKey]);
              return setNextRow(getTranslationNested, `${propertyName}.${propKey}`);
            }, propKeys),
          ];
        }
        return append(setNextRow(getTranslation), acc);
      },
      [],
      translationPropsByElementTypes[item.type]
    );

  const questionRows = reduce(
    (acc, item) => {
      return [...acc, ...getRowsByElementType(item)];
    },
    [],
    flatQuestion
  );

  const commonRows = reduce(
    (acc, path) => {
      const getTranslation = pathOr('', split('.', path));

      return append(
        [
          'common',
          replace('common.', '', path),
          getTranslation(masterTranslation),
          ...map((lang) => getTranslation(translations[lang]), languages),
        ],
        acc
      );
    },
    [],
    commonKeys
  );

  return [...defaultRows, ...questionRows, ...commonRows];
};

export default (masterDefinition, surveyDefinition) => {
  return unparse(arrayPreparation(masterDefinition, surveyDefinition), {
    delimiter: ';',
    header: false,
  });
};
