import { getValidatorProps } from './state/modules/validations';
import { validatorContainer } from './state/validations/validatorContainer';
import { VALIDATION_STATUS } from './state/validations/validationHelper';

export class EditorHandler {
  constructor(reduxStore) {
    this.reduxStore = reduxStore;
  }

  getStatus() {
    const validatorProps = getValidatorProps(this.reduxStore.getState());
    const validationResults = validatorContainer(validatorProps);

    return {
      valid: validationResults.status === VALIDATION_STATUS.VALID,
    };
  }
}
