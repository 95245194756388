import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import classNames from 'classnames';
import { withHandlers } from 'commity-rehook-fork';
import * as PropTypes from 'prop-types';
import { omit, pipe } from 'ramda';
import React from 'react';
import { withStylesAsClasses } from '../../rehooks';

const styles = theme => ({
  root: {},

  dialogTitle: {
    cursor: 'default',
    '& h6': {
      color: theme.palette.background.warning,
    },
  },

  dialogTitleContentText: {
    cursor: 'default',
  },

  yesButton: {
    color: theme.palette.background.warning,
  },
});

const ConfirmDialogPure = ({
  classes,
  handleNoClick,
  handleYesClick,
  onDialogClose,
  dialogTitle,
  dialogContent,
  dialogOpen,
  ...others
}) => {
  return (
    <div {...others} className={classNames(classes.root, others.className)}>
      <Dialog
        open={dialogOpen}
        onClose={onDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title" className={classes.dialogTitle}>
          {dialogTitle}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" className={classes.dialogTitleContentText}>
            {dialogContent}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleNoClick} color="primary">
            No
          </Button>
          <Button onClick={handleYesClick} className={classes.yesButton} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export const ConfirmDialog = pipe(
  withHandlers({
    handleNoClick: ({ onNoClick, onDialogClose }) => () => {
      onNoClick && onNoClick();
      onDialogClose();
    },
    handleYesClick: ({ onYesClick, onDialogClose }) => () => {
      onYesClick();
      onDialogClose();
    },
  }),
  withStylesAsClasses(styles),
  omit(['onNoClick', 'onYesClick']),
  ConfirmDialogPure
);

ConfirmDialog.propTypes = {
  dialogTitle: PropTypes.string,
  dialogContentText: PropTypes.string,
  onNoClick: PropTypes.func,
  onYesClick: PropTypes.func.isRequired,
  dialogOpen: PropTypes.bool,
  onDialogClose: PropTypes.func,
};

ConfirmDialog.defaultProps = {
  dialogOpen: false,
  dialogTitle: 'Dialog title',
  dialogContent: 'Dialog Content Text',
};
