import { Typography } from '@material-ui/core';
import classNames from 'classnames';
import { pipe } from 'commity-rehook-fork';
import * as PropTypes from 'prop-types';
import React from 'react';
import { memoize, withStylesAsClasses } from '../../../rehooks';
import { TranslationProperty } from './TranslationProperty';

const styles = (theme) => ({
  root: {
    border: theme.primaryBorder.border,
    borderRadius: theme.primaryBorder.borderRadius,
    margin: theme.spacing.unit,
    padding: theme.spacing.unit,
    display: 'flex',
    flexDirection: 'column',
  },
});

const TranslationConditionalTextsPure = ({
  propertyLabel,
  translationPath,
  sourcePropertyValue: sourceConditionalTexts = [],
  targetPropertyValue: targetConditionalTexts = [],
  conditionalTextsDefinition,
  classes,
  ...others
}) => {
  return (
    <div {...others} className={classNames(classes.root, others.className)}>
      <Typography variant="subtitle1">{propertyLabel}</Typography>
      {conditionalTextsDefinition &&
        conditionalTextsDefinition.map(({ value }, index) => (
          <div key={index}>
            <div className={classes.choice}>
              <div className={classes.value}>
                <Typography variant="subtitle1" title="Value">
                  {index + 1}:
                </Typography>
              </div>
              <div className={classes.text}>
                <TranslationProperty
                  propertyLabel="Text"
                  translationPath={[...translationPath, index, 'text']}
                  sourcePropertyValue={sourceConditionalTexts[index] && sourceConditionalTexts[index].text}
                  targetPropertyValue={targetConditionalTexts[index] && targetConditionalTexts[index].text}
                />
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

export const TranslationConditionalTexts = pipe(withStylesAsClasses(styles), memoize(TranslationConditionalTextsPure));

TranslationConditionalTexts.propTypes = {
  propertyLabel: PropTypes.string.isRequired,
  translationPath: PropTypes.array.isRequired,
  conditionalTextsDefinition: PropTypes.array.isRequired,
  sourcePropertyValue: PropTypes.array,
  targetPropertyValue: PropTypes.array,
};
