import { Paper, Switch, TextField, Typography } from '@material-ui/core';
import classNames from 'classnames';
import { mapProps, pipe } from 'commity-rehook-fork';
import * as PropTypes from 'prop-types';
import { hasPath, map, path, propEq, reject } from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { isQuestion } from 'xperience-model-management';
import { withStylesAsClasses } from '../../rehooks';
import { getSelectedItemPath } from '../../state/modules/editor';
import { getFlatSurvey, getSelectedItem, toggleMandatory, updateItem } from '../../state/modules/survey/overview';
import { PATHS } from '../../state/modules/survey/paths';
import { QuestionSelect } from '../QuestionSelect';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    cursor: 'default',
  },

  componentPadding: {
    padding: theme.spacing.unit,
  },

  switchPanel: {
    display: 'flex',
    padding: theme.spacing.unit,
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  dependencyContainer: {
    display: 'flex',
  },

  dependencySelect: {
    paddingRight: theme.spacing.unit,
    flex: 1,
  },

  textField: {
    display: 'flex',
    marginTop: 0,
  },
});

const MasterSettingsPure = ({
  classes,
  item,
  selectedItemPath,
  modelPath,
  onMandatoryToggle,
  onDependencySelect,
  onNoteChange,
  options,
  ...others
}) => {
  const showMasterSettingsPanel = isQuestion(item);
  const hasMasterMetaData = hasPath(PATHS.masterMetaData, item);
  const mandatoryChecked = Boolean(path(PATHS.mandatory, item));

  return (
    showMasterSettingsPanel && (
      <div {...others} className={classNames(classes.root, others.className)}>
        <Paper square>
          <Typography variant="h5" component="h3" className={classes.componentPadding}>
            Master Settings
          </Typography>
          <div className={classes.switchPanel}>
            <Typography variant="h6">Mandatory</Typography>
            <Switch
              title="Mandatory Question"
              checked={mandatoryChecked}
              onChange={onMandatoryToggle(selectedItemPath)}
            />
          </div>
          <div className={classes.dependencyContainer}>
            <Typography variant="subtitle2" className={classes.componentPadding}>
              Set Dependency Question:
            </Typography>
            <QuestionSelect
              onSelect={onDependencySelect(selectedItemPath)}
              options={options}
              disabled={!mandatoryChecked}
              value={hasMasterMetaData ? createQuestionSelectItem({ id: path(PATHS.dependency, item) }) : null}
              className={classes.dependencySelect}
            />
          </div>
          <div className={classes.componentPadding}>
            <Typography variant="h6">Note:</Typography>
            <TextField
              id="note"
              label="Note Text"
              placeholder="... insert note text"
              multiline
              value={hasMasterMetaData ? item.masterMetaData.note : ''}
              className={classes.textField}
              onChange={onNoteChange(selectedItemPath)}
              margin="normal"
            />
          </div>
        </Paper>
      </div>
    )
  );
};

const questionsWithoutSelectedItem = state => reject(propEq('id', getSelectedItem(state).id), getFlatSurvey(state));

const createQuestionSelectItem = question => ({ label: question.id, value: question.id, type: 'value' });

const MasterSettingsWithStyles = pipe(
  mapProps(({ questions, ...others }) => ({
    options: map(createQuestionSelectItem, questions),
    ...others,
  })),
  withStylesAsClasses(styles),
  MasterSettingsPure
);

const mapStateToProps = state => {
  return {
    selectedItemPath: getSelectedItemPath(state),
    modelPath: [...getSelectedItemPath(state), ...PATHS.masterMetaData],
    questions: questionsWithoutSelectedItem(state),
  };
};

const mapDispatchToProps = (dispatch, { item }) => {
  return {
    onNoteChange: selectedItemPath => event => {
      dispatch(updateItem([...selectedItemPath, ...PATHS.masterMetaData, 'note'], event.target.value));
    },
    onMandatoryToggle: selectedItemPath => () => {
      dispatch(toggleMandatory(item, [...selectedItemPath]));
    },
    onDependencySelect: selectedItemPath => ({ value }) => {
      dispatch(updateItem([...selectedItemPath, ...PATHS.dependency], value));
    },
  };
};

export const MasterSettings = connect(
  mapStateToProps,
  mapDispatchToProps
)(MasterSettingsWithStyles);

MasterSettings.propTypes = {
  item: PropTypes.shape({
    type: PropTypes.string,
    note: PropTypes.string,
  }),
};
