import { Paper } from '@material-ui/core';
import classNames from 'classnames';
import * as PropTypes from 'prop-types';
import { omit, pipe } from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { Validations } from '../../components/objectInspector/validations/Validations';
import { memoize, withStylesAsClasses } from '../../rehooks';
import { getValidationResults } from './../../state/modules/validations';

const styles = theme => ({
  root: {
    cursor: 'default',
  },

  panel: {
    marginTop: theme.spacing.unit / 2,
  },

  itemContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    marginLeft: theme.spacing.unit / 2,
    height: '100%',
    backgroundColor: theme.palette.background.editorPanels,
  },
});

const TranslationRightPanelPure = ({
  item,
  classes,
  isEditorView,
  validationResults,

  ...others
}) => {
  return (
    <div {...others} className={classNames(classes.root, others.className)}>
      <Paper square className={classes.itemContainer}>
        <Validations item={item} className={classes.panel} isEditorView={isEditorView} />
      </Paper>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    validationResults: getValidationResults(state),
  };
};

const StyledTranslationRightPanel = pipe(
  withStylesAsClasses(styles),
  omit(['dispatch']),
  memoize(TranslationRightPanelPure)
);

export const TranslationRightPanel = connect(mapStateToProps)(StyledTranslationRightPanel);

TranslationRightPanel.propTypes = {
  item: PropTypes.shape({
    type: PropTypes.string,
  }),
  isEditorView: PropTypes.bool,
  validationResults: PropTypes.array,
};
