import React from 'react';
import { Switch, TextField, Typography } from '@material-ui/core';
import { withStylesAsClasses } from '../../../rehooks';
import { pipe, withHandlers } from 'commity-rehook-fork';
import { connect } from 'react-redux';
import {
  getIsRedirect,
  getSelectedItem,
  setRedirect,
  updateRedirectInputs,
} from '../../../state/modules/survey/overview';
import { getSelectedItemPath } from '../../../state/modules/editor';
import { createRedirectOnChangeHandler, createTranslationOnChangeHandler } from '../../handlerHelper';
import { omit, pathOr } from 'ramda';
import {
  getSelectedTranslationItem,
  getTranslationPathForItem,
  updateTranslation,
} from '../../../state/modules/survey/translations';
import { CopyPlaceholderButton } from '../../CopyPlaceholderButton';

const styles = (theme) => ({
  switch: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  switchHeader: {
    display: 'flex',
    flexDirection: 'column',
    padding: '10px',
  },
  switchText: {
    flex: 1,
  },
  commonLink: {
    marginBottom: '10px',
    width: '100%',
  },
  timeError: {
    display: 'block',
    marginTop: 10,
    color: 'red',
  },
  items: {
    display: 'flex',
    flexDirection: 'column',
  },
});

const outroRedirectPlaceholders = [
  { name: 'linkText', type: 'STRING', description: 'Link text' },
  { name: 'seconds', type: 'STRING', description: 'remaining time' },
];

export const MAXSECOND = 60;
export const MINSECOND = 0;

const OutroPure = ({
  classes,
  onRedirectToggle,
  isRedirect,
  modelPath,
  onRedirectLinkChange,
  onRedirectTextLinkChange,
  onRedirectSecondsChange,
  onRedirectTextChange,
  selectedItem,
  translationItem,
}) => {
  const second = +pathOr('', ['redirect', 'seconds'], selectedItem);

  const biggerThenMax = second > MAXSECOND;
  const lessThenMin = second < MINSECOND;

  return (
    <div>
      <div className={classes.switchHeader}>
        <div className={classes.switch}>
          <Typography variant="h6" className={classes.switchText}>
            Redirect
          </Typography>
          <Switch checked={isRedirect} onChange={onRedirectToggle(modelPath)} />
        </div>
        {isRedirect && (
          <div className={classes.items}>
            <TextField
              className={classes.commonLink}
              label="Link"
              placeholder="...insert link"
              value={pathOr('', ['componentProps', 'redirectLink'], selectedItem)}
              onChange={onRedirectLinkChange}
              required
            />
            <TextField
              className={classes.commonLink}
              label="Link text"
              placeholder="...insert text for outro link"
              value={pathOr('', ['redirect', 'linkText'], translationItem)}
              onChange={onRedirectTextLinkChange}
              required
            />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <TextField
                label="Text"
                placeholder="...insert text for outro"
                value={pathOr('', ['redirect', 'text'], translationItem)}
                onChange={onRedirectTextChange}
                required
                multiline
                fullWidth
              />
              <CopyPlaceholderButton customPlaceholder={outroRedirectPlaceholders} />
            </div>
            <TextField
              id="outlined-number"
              style={{ width: '100%' }}
              label="Redirect time in seconds"
              type="number"
              value={pathOr('', ['componentProps', 'redirectSeconds'], selectedItem)}
              onChange={onRedirectSecondsChange}
              inputProps={{
                min: MINSECOND,
                max: MAXSECOND,
              }}
            />
            {biggerThenMax && <span className={classes.timeError}> Please input value less then 30 </span>}
            {lessThenMin && <span className={classes.timeError}> Please input value more then 3 </span>}
          </div>
        )}
      </div>
    </div>
  );
};

const OutroWithStyles = pipe(
  withHandlers({
    onRedirectLinkChange: createRedirectOnChangeHandler(['componentProps', 'redirectLink']),
    onRedirectSecondsChange: createRedirectOnChangeHandler(['componentProps', 'redirectSeconds']),
    onRedirectTextLinkChange: createTranslationOnChangeHandler(['redirect', 'linkText']),
    onRedirectTextChange: createTranslationOnChangeHandler(['redirect', 'text']),
  }),
  omit(['onRedirectChange']),
  omit(['onTranslationChange']),
  withStylesAsClasses(styles),
  OutroPure
);

const mapState = (state, { item }) => {
  return {
    isRedirect: getIsRedirect(state),
    modelPath: getSelectedItemPath(state),
    selectedItem: getSelectedItem(state),
    translationPath: getTranslationPathForItem(state, item),
    translationItem: getSelectedTranslationItem(state),
  };
};

const mapDispatch = (dispatch) => {
  return {
    onRedirectToggle: (modelPath) => (event, checked) => {
      dispatch(setRedirect(checked, modelPath));
    },
    onRedirectChange: ({ redirectPath, value }) => {
      dispatch(updateRedirectInputs(value, redirectPath));
    },
    onTranslationChange: ({ translationPath, value }) => {
      dispatch(updateTranslation(translationPath, value));
    },
  };
};

export const OutroOI = connect(mapState, mapDispatch)(OutroWithStyles);
