import {
  assoc,
  assocPath,
  dissoc,
  dissocPath,
  has,
  ifElse,
  keys,
  lensIndex,
  lensPath,
  mapObjIndexed,
  over,
  path,
  pipe,
  reduce,
  set,
  type,
} from 'ramda';
import { defaultCommonUITranslations } from 'xperience-model-management';
import { PATHS } from '../paths';

export const createBlankTranslation = (sourceTranslation) => {
  const cleanTranslation = (value) => {
    if (type(value) === 'Object') {
      return mapObjIndexed(cleanTranslation, value);
    }
    return '';
  };

  return mapObjIndexed(cleanTranslation, sourceTranslation);
};

export const addNewLanguageHandler = (state, language) => {
  const surveyLanguages = pipe(path([...PATHS.translations]), keys)(state);
  const sourceTranslation = surveyLanguages[0]
    ? path([...PATHS.translations, surveyLanguages[0]], state)
    : defaultCommonUITranslations;
  const newClearTranslation = createBlankTranslation(sourceTranslation);
  return assocPath([...PATHS.translations, language], newClearTranslation, state);
};

export const deleteLanguageHandler = (state, language) => dissocPath([...PATHS.translations, language], state);

export const updateTranslationHandler = (state, translationPath, value) => {
  return assocPath([...PATHS.translations, ...translationPath], value, state);
};

export const toggleAgreementHandler = (state, { item, translationPath }) => {
  const defaultAgreement = {
    text: '',
    instruction: '',
    errorMessage: '',
  }; // todo defaultní hodnoty vytáhnout někam na jedno místo, tady by stačil asi i prázdný objekt!

  const updatedIntro = pipe(ifElse(has('agreement'), dissoc('agreement'), assoc('agreement', defaultAgreement)))(item);
  return assocPath([...PATHS.translations, ...translationPath], updatedIntro, state);
};

export const toggleOptOutHandler = (state, { translationPath }) => {
  const defaultOptOut = {
    text: '',
    linkText: '',
    unsubscribedText: '',
    modalInfo: '',
    modalOk: '',
    modalCancel: '',
  }; // @TODO: pokud to tu není, tak validace nefunguje správně

  const updateIntro = pipe(ifElse(has('optOut'), dissoc('optOut'), assoc('optOut', defaultOptOut)));
  const surveyLanguages = pipe(path([...PATHS.translations]), keys)(state);

  const languageLens = lensIndex(0);
  const getLangIntroLensPath = (lang) => lensPath([...PATHS.translations, ...set(languageLens, lang, translationPath)]);

  // kvuli validaci je potřeba odstranit optOut ze všech jazyku
  return reduce((acc, language) => over(getLangIntroLensPath(language), updateIntro, acc), state, surveyLanguages);
};
