import { Checkbox, FormControlLabel, Select, MenuItem, InputLabel, FormControl } from '@material-ui/core';
import classNames from 'classnames';
import { pipe } from 'commity-rehook-fork';
import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withStylesAsClasses } from '../../../rehooks';
import { getSelectedItemPath } from '../../../state/modules/editor';
import { updateOptionalQuestion, getOptionalQuestion, updateItem } from '../../../state/modules/survey/overview';
import { isText } from 'xperience-model-management';
import { PATHS } from '../../../state/modules/survey/paths';
import { propOr } from 'ramda';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
});

const QuestionTypesOIPure = ({
  classes,
  modelPath,
  onOptionalQuestion,
  optionalQuestion,
  item,
  onUpdateVisualType,
  ...others
}) => {
  const options = [
    { value: 'normal', label: 'Normal' },
    { value: 'compact', label: 'Compact' },
  ];
  if (isText(item)) {
    options.splice(1, 0, { value: 'secondary', label: 'Secondary' });
  }

  return (
    <div {...others} className={classNames(classes.root, others.className)}>
      <FormControlLabel
        control={
          <Checkbox
            checked={optionalQuestion}
            color="primary"
            onChange={onOptionalQuestion(modelPath)}
            title="Is optional"
          />
        }
        label="Is optional"
      />

      <FormControl margin="normal">
        <InputLabel htmlFor="type">Visual type</InputLabel>
        <Select onChange={onUpdateVisualType(modelPath)} value={propOr('normal', 'questionVisualType', item)}>
          {options.map(({ value, label }) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

const QuestionTypesOIWithStyles = pipe(withStylesAsClasses(styles), QuestionTypesOIPure);

const mapStateToProps = (state) => ({
  modelPath: getSelectedItemPath(state),
  optionalQuestion: getOptionalQuestion(state),
});

const mapDispatchToProps = (dispatch) => {
  return {
    onOptionalQuestion: (modelPath) => (event, checked) => {
      dispatch(updateOptionalQuestion(checked, modelPath));
    },
    onUpdateVisualType: (selectedItemPath) => ({ target: { value } }) => {
      dispatch(updateItem([...selectedItemPath, ...PATHS.questionVisualType], value));
    },
  };
};

export const QuestionTypesOI = connect(mapStateToProps, mapDispatchToProps)(QuestionTypesOIWithStyles);

QuestionTypesOI.propTypes = {
  readOnly: PropTypes.bool,
};
