/* eslint no-template-curly-in-string: "off" */
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import classNames from 'classnames';
import { withHandlers, withState } from 'commity-rehook-fork';
import * as PropTypes from 'prop-types';
import { pipe } from 'ramda';
import React from 'react';
import { withStylesAsClasses } from '../rehooks';
import { LocaleSelect } from './LocaleSelect';

const styles = () => ({
  root: {},

  dialogHack: {
    overflow: 'visible',
  },

  toolbarLanguageSelect: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    cursor: 'pointer',
  },
});

const SurveyLanguageDialogPure = ({
  classes,
  open,
  onOkLanguageClick,
  onSelectLanguage,
  okButtonDisabled,
  setOkButton,
  selectedLanguage,
  setSelectedLanguage,

  ...others
}) => {
  return (
    <div {...others} className={classNames(classes.root, others.className)}>
      <Dialog
        aria-labelledby="survey-language--dialog-title"
        classes={{ paperScrollPaper: classes.dialogHack }}
        open={open}
        disableBackdropClick
        disableEscapeKeyDown>
        <DialogTitle id="survey-language-dialog-title">Please, select survey language...</DialogTitle>
        <DialogContent className={classes.dialogHack}>
          <LocaleSelect autoFocus className={classes.toolbarLanguageSelect} onSelect={onSelectLanguage} />
        </DialogContent>
        <DialogActions>
          <Button onClick={onOkLanguageClick} color="primary" disabled={okButtonDisabled}>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export const SurveyLanguageDialog = pipe(
  withState('okButtonDisabled', 'setOkButton', true),
  withState('selectedLanguage', 'setSelectedLanguage', null),

  withHandlers({
    onSelectLanguage: ({ setOkButton, setSelectedLanguage }) => language => {
      setOkButton(false);
      setSelectedLanguage(language);
    },
    onOkLanguageClick: ({ onOkLanguageClick, selectedLanguage }) => () => {
      onOkLanguageClick(selectedLanguage);
    },
  }),

  withStylesAsClasses(styles),
  SurveyLanguageDialogPure
);

SurveyLanguageDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onOkLanguageClick: PropTypes.func.isRequired,
  onSelectLanguage: PropTypes.func,
};

SurveyLanguageDialog.defaultProps = {
  open: false,
};
