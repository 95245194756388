import { green, grey } from '@material-ui/core/colors';

const myGreen = {
  ...green,
  500: '#4ba82e',
  600: '#4ba82e',
  A100: '#4ba82e',
  A200: '#4ba82e',
  A400: '#4ba82e',
  A700: '#4ba82e',
  A800: '#2E7D32',
  A900: '#1B5E20',
};

const fontFamily = '"SKODA Next", "Helvetica Neue", Helvetica, Arial, sans-serif';

const theme = {
  palette: {
    primary: myGreen,
    secondary: grey,
    orange: '#C66F36',
    background: {
      primary: '#EEEEEE',
      highlight: '#DDDDDD',
      selected: '#DCEDC8',
      warning: '#E53935',
      dndCanDrop: '#C5E1A5',
      dndCannotDrop: '#EF9A9A',
      editorPanels: '#F5F5F5',
      conditionModal: '#FFF',
      pageHeader: grey['500'],
    },
  },

  defaultFont: fontFamily,

  typography: {
    fontFamily,
    useNextVariants: true,
  },

  primaryBorder: {
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: 4,
  },

  editorToolbarHeight: 48,
  translationToolbarHeight: 52,

  overrides: {
    MuiInput: {
      inputMultiline: {
        overflow: 'auto',
      },
    },
    MuiFormControl: {
      // Name of the component ⚛️ / style sheet
      marginNormal: {
        // Name of the rule
        marginTop: 8, // Some CSS
      },
    },
  },
};

export default theme;
