import {
  all,
  allPass,
  always,
  any,
  anyPass,
  applySpec,
  complement,
  concat,
  drop,
  dropWhile,
  equals,
  filter,
  findIndex,
  has,
  inc,
  isEmpty,
  join,
  map,
  path,
  pipe,
  prop,
  propEq,
  reject,
  startsWith,
  take,
  toString,
  when,
} from 'ramda';
import { flatQuestionTree, isOutro, isPage, ITEM_TYPES, getDataType } from 'xperience-model-management';
import { PATHS } from '../state/modules/survey/paths';

export {
  ITEM_TYPES,
  isEscape,
  isPage,
  isGender,
  isIntro,
  isMulti,
  isOutro,
  isQuestion,
  isSingle,
  isSquare,
  isStar,
  questionTypes,
} from 'xperience-model-management';

export const ESCAPE_QUESTION_ID = 'QE';
export const PERSONAL_AGREEMENT_ID = 'QP';
export const HIDEABLE_CHOICE_VALUE = 999;

const joinArrayAsString = join('.');

export const isModelPathSelected = (modelPath, selectedItemPath) => {
  if (!selectedItemPath) {
    return false;
  }
  const modelPathString = joinArrayAsString(modelPath);
  const selectedItemPathString = joinArrayAsString(selectedItemPath);
  return equals(modelPathString, selectedItemPathString);
};

export const hasConditions = anyPass([has('visibleIf'), has('hideIf')]);

export const isDeletableItem = item => {
  const nonDeletableItems = [ITEM_TYPES.INTRO, ITEM_TYPES.OUTRO];
  return !nonDeletableItems.includes(item.type);
};

export const getNumberFromItemId = ({ id }) => {
  const defaultResult = 0;
  const numberStr = id.match(/\d+/g, '');
  const number = parseInt(numberStr);
  return isNaN(number) ? defaultResult : number;
};

const getMax = values => Math.max(...values);

export const generateUniqQuestionId = (surveyDefinition, questionPrefix = 'Q') => {
  const startsWithPrefix = question => startsWith(questionPrefix, prop('id', question));
  return pipe(
    flatQuestionTree,
    filter(allPass([has('id'), startsWithPrefix])),
    when(isEmpty, always([{ id: '0' }])),
    map(getNumberFromItemId),
    getMax,
    inc,
    toString,
    concat(questionPrefix)
  )(surveyDefinition);
};

export const removeQuestionByIds = (questions, withoutQuestionIds) => {
  return questions.filter(item => !withoutQuestionIds.includes(item.id));
};

export const questionsBeforeGivenQuestion = (questions, currentQuestionId, withoutQuestionIds) => {
  const questionIndex = findIndex(propEq('id', currentQuestionId), questions);
  const questionsBefore = take(questionIndex, questions);
  return withoutQuestionIds ? removeQuestionByIds(questionsBefore, withoutQuestionIds) : questionsBefore;
};

export const getQuestionsAfterQuestionId = (id, flattenQuestionsTree) =>
  pipe(
    dropWhile(complement(propEq('id', id))),
    reject(isOutro),
    when(complement(isEmpty), drop(1))
  )(flattenQuestionsTree);

const createLabelForSelect = pipe(
  prop('id'),
  concat('Value from: ')
);

export const transformQuestionsForSelect = map(
  applySpec({
    value: prop('id'),
    type: always('variable'),
    dataType: getDataType,
    label: createLabelForSelect,
  })
);
export const hasEscapeQuestion = surveyDefinition => isInSurveyDefinition(ESCAPE_QUESTION_ID)(surveyDefinition);
export const hasPersonalAgreement = surveyDefinition => isInSurveyDefinition(PERSONAL_AGREEMENT_ID)(surveyDefinition);

const isInSurveyDefinition = id => any(propEq('id', id));

export const isQuestionInSurvey = (item, flattenQuestionsTree) => {
  if (!item || !flattenQuestionsTree) {
    return false;
  }
  if (isPage(item)) {
    return all(element => isInSurveyDefinition(element.id)(flattenQuestionsTree))(item.elements);
  }
  return isInSurveyDefinition(item.id)(flattenQuestionsTree);
};

export const hasAllMandatoryQuestionsFromPageInSurvey = (item, flattenQuestionsTree) => {
  if (isPage(item)) {
    const mandatoryElements = filter(element => path(PATHS.mandatory, element), item.elements);
    return all(element => isInSurveyDefinition(element.id)(flattenQuestionsTree))(mandatoryElements);
  }
  return isInSurveyDefinition(item.id)(flattenQuestionsTree);
};

export const isQuestionMandatory = (item, flattenQuestionsTree) => {
  if (!item || (!isPage(item) && !path(PATHS.mandatory, item))) {
    return false;
  }
  if (isPage(item)) {
    return any(element => isQuestionMandatory(element, flattenQuestionsTree))(item.elements);
  }
  const dependency = path(PATHS.dependency, item);
  if (!dependency) {
    return true;
  }
  return isInSurveyDefinition(dependency)(flattenQuestionsTree);
};

export const scrollToOpenQuestion = () => {
  const openedQuestion = document.querySelector('.open-item-wrapper');
  if (openedQuestion) {
    openedQuestion.scrollIntoViewIfNeeded ? openedQuestion.scrollIntoViewIfNeeded() : openedQuestion.scrollIntoView();
  }
};
