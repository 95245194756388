import { Grid, Typography } from '@material-ui/core';
import { pipe } from 'commity-rehook-fork';
import { pathOr } from 'ramda';
import { mapIndexed } from 'ramda-adjunct';
import React from 'react';
import { conditionsType } from '../../../conditionsType';
import { withStylesAsClasses } from '../../../rehooks';

const styles = (theme) => ({
  root: {},

  conditionContainer: {
    fontFamily: theme.defaultFont,
    margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px ${theme.spacing.unit - 2}px ${
      theme.spacing.unit / 4
    }px`,
  },

  legendTitle: {
    color: theme.palette.primary.A100,
  },
});

const getFirstValue = pathOr('', ['firstValue', 'value']);
const getSecondValue = pathOr('', ['secondValue', 'value']);
const getCondition = (condition) => (conditionsType[condition] ? conditionsType[condition].name : '');
const rowToString = (operation = 'and', showOperation = false) => (row, index) => {
  if (row.rows) {
    return (
      <div key={index}>
        {showOperation ? `${operation} ` : ''} ({mapIndexed(rowToString(row.operation, true), row.rows)})
      </div>
    );
  }
  return (
    <Typography variant="subtitle2" key={index}>
      {index !== 0 ? `${operation} ` : ''}
      {getFirstValue(row)} {row.isNegation ? 'not ' : ''}
      {getCondition(row.condition)} {getSecondValue(row)}
    </Typography>
  );
};

export const Rows = ({ conditions, ...others }) => {
  const rows = mapIndexed(rowToString(conditions.operation), conditions.rows);
  return (
    <Grid {...others} container direction="column" justify="center" alignItems="flex-start">
      {rows}
    </Grid>
  );
};

const ConditionsAsStringPure = ({ classes, conditions, ...others }) => {
  return (
    <fieldset className={classes.conditionContainer}>
      <legend className={classes.legendTitle}>{conditions.type === 'hideIf' ? 'Hide if' : 'Visible if'}:</legend>
      <Rows conditions={conditions} {...others} />
    </fieldset>
  );
};

export const ConditionsAsString = pipe(withStylesAsClasses(styles), ConditionsAsStringPure);

ConditionsAsString.propTypes = {};
ConditionsAsString.defaultProps = {
  conditions: [],
};
