import {
  cond,
  has,
  head,
  identity,
  join,
  lensPath,
  lensProp,
  map,
  over,
  path,
  pipe,
  prop,
  propEq,
  reduce,
  when,
} from 'ramda';
import { ensureArray, isArray, isString } from 'ramda-adjunct';
// TODO move this functionality to model management package

const translationsLens = lensProp('translations');
const isTypeVariable = propEq('type', 'variable');
const isTypeFunction = propEq('type', 'function');
const expressionPaths = [['text'], ['introduction'], ['agreement', 'instruction']];

export const expressionPartToString = (v, suffix = '') =>
  cond([
    [isString, identity],
    [isTypeVariable, (exp) => `\${${prop('value', exp)}${suffix}}`],
    [isTypeFunction, (exp) => expressionPartToString(head(exp.args), ` | ${prop('operation', exp)}`)],
  ])(v);

export const expressionToString = pipe(ensureArray, map(expressionPartToString), join(''));

const replaceExpressionWithString = (propPath) => over(lensPath(propPath), expressionToString);

const isPropArray = (propPath) => pipe(path(propPath), isArray);

const replaceExpressionProps = (translation) =>
  reduce(
    (acc, propPath) => {
      return when(isPropArray(propPath), replaceExpressionWithString(propPath))(acc);
    },
    translation,
    expressionPaths
  );

const replaceExpressionChoices = when(
  has('choices'),
  over(lensProp('choices'), map(when(isPropArray(['text']), replaceExpressionWithString(['text']))))
);

export const processExpressions = (translation) => {
  return map(pipe(replaceExpressionProps, replaceExpressionChoices), translation);
};

const goThroughAllTranslations = (translations) => {
  return map(processExpressions, translations);
};

export const expressionsToTexts = (survey) => {
  return over(translationsLens, goThroughAllTranslations, survey);
};
