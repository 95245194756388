import React from 'react';

import { Button } from '@material-ui/core';
import fileSaver from 'file-saver';
import classNames from 'classnames';
import { pipe } from 'commity-rehook-fork';
import { omit } from 'ramda';

import { format } from 'date-fns';
import { withStylesAsClasses } from './rehooks';
import { exportDefinition } from './surveyModel/exportDefinition/index';

const styles = () => ({
  root: {},
});

function formatTimestampForCsvExport() {
  return format(new Date(), 'yyyymmdd_HHmmss');
}

const DownloadDefinitionPure = ({ classes, survey, ...others }) => {
  function handleChange() {
    const blob = new Blob([String.fromCharCode(0xfeff), JSON.stringify(exportDefinition(survey))], {
      type: 'application/json;charset=utf-8',
    });
    fileSaver.saveAs(blob, `definition_export_${formatTimestampForCsvExport()}.json`, { autoBom: false });
  }

  return (
    <div {...others} className={classNames(classes.root, others.className)}>
      <Button
        variant="contained"
        color="primary"
        aria-label="Download definition"
        title="Download definition"
        onClick={handleChange}>
        Download definition
      </Button>
    </div>
  );
};

export const DownloadDefinition = pipe(withStylesAsClasses(styles), omit(['dispatch']), DownloadDefinitionPure);
