/* eslint-disable no-console */

import Select from '@material-ui/core/Select';
import { withStateHandlers } from 'commity-rehook-fork';
import { assoc, pipe } from 'ramda';
import React from 'react';
import { EditorApp } from './EditorApp';
import { dummySurvey, dummySurveyMaster } from './example-definition/dummyData';
import moroccoSalesDefinition from './example-definition/morocco-skoda-sales-rtl-test';
import serbiaSalesDefinition from './example-definition/serbia-skoda-sales';
import masterDefinition from './example-definition/skoda-sales-definition';
import taiwanSalesDefinition from './example-definition/taiwan-skoda-sales';
import afs2020Definition from './example-definition/skoda-after-sales-definition-2020';
import { DownloadDefinition } from './DownloadDefinition';

const placeholders = [
  {
    name: 'workshopName',
    type: 'string',
    description: 'Workshop name',
  },
  {
    name: 'carDealerName',
    type: 'string',
    description: 'Car Dealer Name',
  },
  {
    name: 'serviceDate',
    type: 'date',
    description: 'Service Date',
  },
  {
    name: 'carImage',
    type: 'string',
    description: 'Car Image',
  },
  {
    name: 'dealerId',
    type: 'string',
    description: 'Dealer ID',
  },
  {
    name: 'carBrand',
    type: 'string',
    description: 'Car Brand',
  },
  {
    name: 'carModel',
    type: 'string',
    description: 'Car Model',
  },
  {
    name: 'carColor',
    type: 'string',
    description: 'Car Color',
  },
  {
    name: 'invoiceDate|dateFormatter',
    type: 'date',
    description: 'Invoice Date',
  },
  {
    name: 'serviceDate|dateFormatter',
    type: 'date',
    description: 'Service Date',
  },
  {
    name: 'electricCar',
    type: 'boolean',
    description: 'Electric car',
  },
];

const examples = {
  dummy: {
    surveyDefinition: dummySurvey,
    placeholders,
    masterDefinition: dummySurveyMaster,
  },
  dummy2: {
    surveyDefinition: dummySurvey,
    placeholders,
    masterDefinition: masterDefinition.survey,
  },
  createSurvey: {
    placeholders,
    masterDefinition: masterDefinition.survey,
  },
  createMaster: {
    placeholders,
  },
  master: {
    surveyDefinition: masterDefinition.survey,
    placeholders,
  },
  moroccoSales: {
    surveyDefinition: moroccoSalesDefinition.survey,
    masterDefinition: masterDefinition.survey,
    placeholders,
  },
  serbiaSales: {
    surveyDefinition: serbiaSalesDefinition.survey,
    masterDefinition: masterDefinition.survey,
    placeholders,
  },
  taiwanSales: {
    surveyDefinition: taiwanSalesDefinition.survey,
    masterDefinition: masterDefinition.survey,
    placeholders,
  },
  'AFS-2020': {
    surveyDefinition: afs2020Definition.survey,
    placeholders,
  },
};

export const ExampleApp = pipe(
  withStateHandlers(
    {
      example: 'dummy',
      surveyDefinition: examples.dummy.surveyDefinition,
      masterDefinition: examples.dummy.masterDefinition,
      placeholders,
    },
    {
      setExample: (state) => (example) => {
        const exampleData = examples[example];

        return pipe(
          assoc('example', example),
          assoc('surveyDefinition', exampleData.surveyDefinition),
          assoc('masterDefinition', exampleData.masterDefinition),
          assoc('placeholders', exampleData.placeholders)
        )(state);
      },
      setSurveyDefinition: (state) => (surveyDefinition) => assoc('surveyDefinition', surveyDefinition, state),
    }
  ),
  ({ example, setExample, setSurveyDefinition, ...props }) => (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <div style={{ flex: '0 0 auto' }}>
        Vyberte dotazník k editaci:
        <Select native value={example} onChange={(event) => setExample(event.target.value)}>
          {Object.keys(examples).map((value) => (
            <option key={value} value={value}>
              {value}
            </option>
          ))}
        </Select>
        <div style={{ float: 'right' }}>
          <DownloadDefinition survey={props.surveyDefinition} />
        </div>
      </div>
      <EditorApp
        {...props}
        onChange={setSurveyDefinition}
        handlerProvider={(handler) => (window.handler = handler)}
        fullscreen={false}
        onFullscreenChange={console.log.bind(null, 'onFullscreenChange')}
      />
    </div>
  )
);
