import { Button } from '@material-ui/core';
import classNames from 'classnames';
import { pipe } from 'commity-rehook-fork';
import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { memoize, withStylesAsClasses } from '../../rehooks';
import { getFlattenMasterQuestions, getSelectedCurrentLanguage, isMaster } from '../../state/modules/editor';
import { addNewQuestion } from '../../state/modules/survey/overview';
import { getDragAndDropRootPath, getDraggableId } from '../dndHelper';
import { hasEscapeQuestion, hasPersonalAgreement, isDeletableItem, isPage, ITEM_TYPES } from '../questionHelper';
import { findTranslationItemInCurrentTranslation } from '../translations/translationHelper';
import { ItemWrapper } from './ItemWrapper';
import { DraggablePageHeader } from './PageHeader';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing.unit,
  },

  nonDraggable: {
    padding: `0 ${theme.spacing.unit / 2}px 0 ${theme.spacing.unit / 2}px`,
  },

  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: `${theme.spacing.unit}px 0 ${theme.spacing.unit}px 0`,
    paddingBottom: theme.spacing.unit / 2,
  },

  addQuestionButton: {
    marginLeft: theme.spacing.unit,
    minHeight: 32,
  },

  listStyle: {
    padding: `0 ${theme.spacing.unit / 2}px ${theme.spacing.unit / 2}px ${theme.spacing.unit / 2}px`,
  },
});

const SurveyOverviewPure = ({
  surveyDefinition,
  currentLanguage,
  selectedItemPath,
  classes,
  onAddNewQuestion,
  onAddEscapeQuestion,
  onAddPersonalAgreement,
  survey,
  flattenMasterQuestions,
  isMasterSurvey,
  ...others
}) => {
  const getIconPanelConfig = item => ({
    showDeleteIcon: isDeletableItem(item),
  });

  return (
    <div {...others} className={classNames(classes.root, others.className)}>
      {survey.definition.map((item, index) => {
        const modelPath = [index];
        const draggableId = getDraggableId([...modelPath, currentLanguage]);
        return isPage(item) ? (
          <DraggablePageHeader
            key={draggableId}
            selectedItemPath={selectedItemPath}
            draggableRoot={getDragAndDropRootPath()}
            modelPath={modelPath}
            index={index}
            item={item}
            survey={survey}
          />
        ) : (
          <ItemWrapper
            key={draggableId}
            selectedItemPath={selectedItemPath}
            draggableRoot={getDragAndDropRootPath()}
            iconPanel={getIconPanelConfig(item)}
            modelPath={modelPath}
            flattenMasterQuestions={flattenMasterQuestions}
            index={index}
            item={item}
            translationItem={findTranslationItemInCurrentTranslation(survey, item)}
          />
        );
      })}

      <div className={classes.buttonContainer}>
        {!hasEscapeQuestion(survey.definition) && (
          <Button
            variant="outlined"
            color="primary"
            aria-label="Add Escape Question"
            title="Add Escape Question"
            className={classes.addQuestionButton}
            onClick={onAddEscapeQuestion(isMasterSurvey)}>
            Add Escape Question
          </Button>
        )}

        {!hasPersonalAgreement(survey.definition) && (
          <Button
            variant="outlined"
            color="primary"
            aria-label="Add Personal Agreement"
            title="Add Personal Agreement"
            className={classes.addQuestionButton}
            onClick={onAddPersonalAgreement(isMasterSurvey)}>
            Add Personal Agreement
          </Button>
        )}

        <Button
          variant="contained"
          color="primary"
          aria-label="Add Question"
          title="Add Question"
          className={classes.addQuestionButton}
          onClick={onAddNewQuestion(isMasterSurvey)}>
          Add Question
        </Button>
      </div>
    </div>
  );
};

const StyledSurveyOverview = pipe(
  withStylesAsClasses(styles),
  memoize(SurveyOverviewPure)
);

const mapStateToProps = state => ({
  flattenMasterQuestions: getFlattenMasterQuestions(state),
  isMasterSurvey: isMaster(state),
  currentLanguage: getSelectedCurrentLanguage(state),
});

const mapDispatchToProps = dispatch => {
  return {
    onAddEscapeQuestion: isMasterSurvey => () => {
      dispatch(addNewQuestion(ITEM_TYPES.ESCAPE, isMasterSurvey));
    },
    onAddPersonalAgreement: isMasterSurvey => () => {
      dispatch(addNewQuestion(ITEM_TYPES.PERSONAL_AGREEMENT, isMasterSurvey));
    },
    onAddNewQuestion: isMasterSurvey => () => {
      dispatch(addNewQuestion(ITEM_TYPES.TEXT, isMasterSurvey));
    },
  };
};

export const SurveyOverview = connect(
  mapStateToProps,
  mapDispatchToProps
)(StyledSurveyOverview);

SurveyOverview.propTypes = {
  survey: PropTypes.shape({}).isRequired,
  selectedItemPath: PropTypes.array,
};
