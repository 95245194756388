import { TextField, Grid, Button } from '@material-ui/core';
import classNames from 'classnames';
import { pipe, withHandlers } from 'commity-rehook-fork';
import * as PropTypes from 'prop-types';
import { omit } from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { memoizeWithEquals, withStylesAsClasses } from '../../../rehooks';
import {
  appendConditionalText,
  moveConditionalText,
  updateConditionalText,
  removeConditionalText,
} from '../../../state/modules/survey/overview';
import { getTranslationPathForItem, updateTranslation } from '../../../state/modules/survey/translations';
import { createTranslationOnChangeHandler } from '../../handlerHelper';
import { OutroQuestionForm } from './subforms/OutroQuestionForm';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing.unit,
  },
  textField: {},
  a: {
    border: '2px solid #4ba82e',
    borderRadius: 4,
    padding: 8,
    marginTop: 4,
  },
});

/**
 const model = {
  id: "outro", // libovolný ale povinný identifikátor
  type: "outro",
  text: "krátký nadpis" // text s placeholdery
};
 **/

const OutroFormPure = ({
  item,
  translationItem,
  onTextChange,
  onTextChange2,
  classes,
  onConditionalTextAppend,
  onConditionalTextTranslateChange,
  onConditionalTextChange,
  onConditionalTextMove,
  onConditionalTextRemove,
  ...others
}) => {
  return (
    <div {...others} className={classNames(classes.root, others.className)}>
      {item.conditionalTexts ? (
        <>
          <OutroQuestionForm
            item={item}
            translationItem={translationItem}
            updateConditionalText={onConditionalTextChange}
            conditionalTextTranslate={onConditionalTextTranslateChange}
            moveConditionalText={onConditionalTextMove}
            removeConditionalText={onConditionalTextRemove}
          />
          <Button variant="contained" color="primary" onClick={onConditionalTextAppend}>
            add conditional text
          </Button>
          <Grid container alignItems="center" className={classes.a}>
            <Grid item xs={2}>
              else show text:
            </Grid>
            <Grid item xs={10}>
              <TextField
                id="text"
                label="Text"
                placeholder="... insert outro text"
                multiline
                value={translationItem && translationItem.text}
                onChange={onTextChange}
                className={classes.textField}
                margin="normal"
                fullWidth
              />
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <Button variant="contained" color="primary" onClick={onConditionalTextAppend}>
            add conditional text
          </Button>
          <TextField
            id="text"
            label="Text"
            placeholder="... insert outro text"
            multiline
            value={translationItem && translationItem.text}
            onChange={onTextChange}
            className={classes.textField}
            margin="normal"
            fullWidth
          />
        </>
      )}
    </div>
  );
};

const StyledOutroForm = pipe(
  withHandlers({
    onTextChange: createTranslationOnChangeHandler(['text']),
    onConditionalTextTranslateChange: (props) => (index) =>
      createTranslationOnChangeHandler(['conditionalTexts', index, 'text'])(props),
    onConditionalTextAppend: ({ modelPath, onConditionalTextAppend }) => () => onConditionalTextAppend(modelPath),
    onConditionalTextChange: ({ modelPath, onConditionalTextChange }) => (index, condition) =>
      onConditionalTextChange(modelPath, index, condition),
    onConditionalTextMove: ({ modelPath, onConditionalTextMove }) => (dragIndex, hoverIndex) =>
      onConditionalTextMove(modelPath, dragIndex, hoverIndex),
    onConditionalTextRemove: ({ modelPath, onConditionalTextRemove }) => (index) =>
      onConditionalTextRemove(modelPath, index),
  }),
  withStylesAsClasses(styles),
  omit(['parentPageModelPath', 'translationPath', 'onTranslationChange', 'modelPath']),
  memoizeWithEquals(OutroFormPure)
);

const mapStateToProps = (state, { item }) => ({
  translationPath: getTranslationPathForItem(state, item),
});

const mapDispatchToProps = (dispatch) => {
  return {
    onTranslationChange: ({ translationPath, value }) => {
      dispatch(updateTranslation(translationPath, value));
    },
    onConditionalTextAppend: (modelPath) => {
      dispatch(appendConditionalText(modelPath));
    },
    onConditionalTextChange: (modelPath, index, condition) => {
      dispatch(updateConditionalText(modelPath, index, condition));
    },
    onConditionalTextMove: (modelPath, index, condition) => {
      dispatch(moveConditionalText(modelPath, index, condition));
    },
    onConditionalTextRemove: (modelPath, index) => {
      dispatch(removeConditionalText(modelPath, index));
    },
  };
};

export const OutroForm = connect(mapStateToProps, mapDispatchToProps)(StyledOutroForm);

OutroForm.propTypes = {
  item: PropTypes.shape({
    text: PropTypes.string,
  }),
};
