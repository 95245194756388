/* eslint no-case-declarations: "off", guard-for-in: "off", no-use-before-define: "off" */

import { filter, identity, map, mergeAll, pick, pickBy, pipe, prop, propSatisfies } from 'ramda';
import { isNotUndefined, isUndefined } from 'ramda-adjunct';
import { ESCAPE_QUESTION_ID, PERSONAL_AGREEMENT_ID } from '../components/questionHelper';

const VALUE = '_shape:spec:simple:value_';
const DEFAULT = '_shape:spec:value:default_';
const FORCED_VALUE = '_shape:spec:value:forced_';
const COLLECTION = '_shape:spec:type:collection_';
const DICTIONARY = '_shape:spec:type:dictionary_';

export const shapeSpec = {
  VALUE,

  defaultValue: (value) => {
    return {
      _shapeSpecValue: DEFAULT,
      defaultValue: value,
    };
  },

  forceValue: (value) => {
    return {
      _shapeSpecValue: FORCED_VALUE,
      _forcedValue: value,
    };
  },

  collectionOf: (shapeDefinition) => {
    return {
      _shapeSpecValue: DEFAULT,
      _shapeSpecType: COLLECTION,
      collectionProcessing: [],
      defaultValue: [],
      shapeDefinition,
      filter(filterFunction) {
        this.collectionProcessing.push(filter(filterFunction));
        return this;
      },
      map(filterFunction) {
        this.collectionProcessing.push(map(filterFunction));
        return this;
      },
    };
  },

  dictionary(shapeDefinition) {
    return {
      _shapeSpecType: DICTIONARY,
      shapeDefinition,
    };
  },
};

const selector = {
  specType: prop('_shapeSpecType'),
  specValue: prop('_shapeSpecValue'),
  defaultValue: prop('defaultValue'),
  forcedValue: prop('_forcedValue'),
};

const keyIsUndefined = propSatisfies(isUndefined);

const reshapeComplexTypes = (complexShapes, data) => {
  const newData = {};
  for (const key in complexShapes) {
    const reshapedData = reshapeComplexType(complexShapes[key], data[key]);
    if (isNotUndefined(reshapedData)) {
      newData[key] = reshapedData;
    }
  }
  return newData;
};

const reshapeValues = (shapeDefinition, data) => {
  const needDefaultValue = (value, key) => keyIsUndefined(key, data) && selector.specValue(value) === DEFAULT;
  const hasForcedValue = (value) => selector.specValue(value) === FORCED_VALUE;

  const getDefaults = pipe(pickBy(needDefaultValue), map(selector.defaultValue));
  const getForcedValues = pipe(pickBy(hasForcedValue), map(selector.forcedValue));

  const keys = Object.keys(shapeDefinition);

  const shapedData = pick(keys, data);
  const defaults = getDefaults(shapeDefinition);
  const forcedValues = getForcedValues(shapeDefinition);
  const complexShapes = pickBy(selector.specType, shapeDefinition);
  const complexData = reshapeComplexTypes(complexShapes, data);

  return mergeAll([shapedData, defaults, complexData, forcedValues]);
};

export const reshape = (shapeDefinition, data) => {
  if (selector.specType(shapeDefinition)) {
    return reshapeComplexType(shapeDefinition, data);
  }
  return reshapeValues(shapeDefinition, data);
};

const reshapeComplexType = (complexShape, data) => {
  if (!data) {
    return data;
  }
  switch (complexShape._shapeSpecType) {
    case COLLECTION:
      const postprocessor = complexShape.collectionProcessing.length
        ? pipe(...complexShape.collectionProcessing)
        : identity;

      const reshapedData = data.map((value) => {
        return reshape(complexShape.shapeDefinition, value);
      });

      return postprocessor(reshapedData);

    case DICTIONARY:
      return reshape(complexShape.shapeDefinition, data);

    default:
      throw new Error(`Unsupported complex type: ${complexShape._shapeSpecType}`);
  }
};

export const typeReshaper = (ITEMS) => {
  const typeShapes = {
    [ITEMS.ESCAPE]: {
      id: shapeSpec.forceValue(ESCAPE_QUESTION_ID),
      type: shapeSpec.forceValue(ITEMS.ESCAPE),
    },
    [ITEMS.PERSONAL_AGREEMENT]: {
      id: shapeSpec.forceValue(PERSONAL_AGREEMENT_ID),
      type: shapeSpec.forceValue(ITEMS.PERSONAL_AGREEMENT),
    },
    [ITEMS.GENDER]: {
      id: shapeSpec.VALUE,
      type: shapeSpec.forceValue(ITEMS.GENDER),
      choices: shapeSpec.forceValue([{ value: 1 }, { value: 2 }, { value: 999, hidden: false }]),
    },
    [ITEMS.PAGE]: {
      type: shapeSpec.forceValue(ITEMS.PAGE),
      elements: shapeSpec.collectionOf({
        id: shapeSpec.VALUE,
        type: shapeSpec.VALUE,
      }),
    },
    [ITEMS.INTRO]: {
      id: shapeSpec.VALUE,
      type: shapeSpec.forceValue(ITEMS.INTRO),
      imagePath: shapeSpec.collectionOf({
        type: shapeSpec.VALUE,
        value: shapeSpec.VALUE,
      }),
      optional: shapeSpec.VALUE,
    },
    [ITEMS.MULTI_CHOICE]: {
      id: shapeSpec.VALUE,
      type: shapeSpec.forceValue(ITEMS.MULTI_CHOICE),
      choices: shapeSpec.collectionOf({
        value: shapeSpec.VALUE,
        hidden: shapeSpec.VALUE,
      }),
    },
    [ITEMS.OUTRO]: {
      id: shapeSpec.VALUE,
      type: shapeSpec.forceValue(ITEMS.OUTRO),
    },
    [ITEMS.SINGLE_CHOICE]: {
      id: shapeSpec.VALUE,
      type: shapeSpec.forceValue(ITEMS.SINGLE_CHOICE),
      choices: shapeSpec.collectionOf({
        value: shapeSpec.VALUE,
        hidden: shapeSpec.VALUE,
      }),
    },
    [ITEMS.SQUARE_RATING]: {
      id: shapeSpec.VALUE,
      type: shapeSpec.forceValue(ITEMS.SQUARE_RATING),
      choices: shapeSpec.collectionOf({
        value: shapeSpec.VALUE,
        hidden: shapeSpec.VALUE,
      }),
    },
    [ITEMS.DYNAMIC_SQUARE_RATING]: {
      id: shapeSpec.VALUE,
      type: shapeSpec.forceValue(ITEMS.DYNAMIC_SQUARE_RATING),
      componentProps: shapeSpec.forceValue({ startFromZero: false, count: 10 }),
      choices: shapeSpec.collectionOf({
        value: shapeSpec.VALUE,
        hidden: shapeSpec.VALUE,
      }),
    },
    [ITEMS.STAR_RATING]: {
      id: shapeSpec.VALUE,
      type: shapeSpec.forceValue(ITEMS.STAR_RATING),
      choices: shapeSpec.collectionOf({
        value: shapeSpec.VALUE,
        hidden: shapeSpec.VALUE,
      }),
    },
    [ITEMS.TEXT]: {
      id: shapeSpec.VALUE,
      type: shapeSpec.forceValue(ITEMS.TEXT),
    },
  };

  return (data, type) => {
    const shape = typeShapes[type]; // default

    return reshape(shape, data);
  };
};
