import {
  addIndex,
  clone,
  filter,
  flatten,
  head,
  includes,
  indexOf,
  insertAll,
  join,
  lensPath,
  lensProp,
  map,
  over,
  pipe,
  pluck,
  reduce,
  remove,
  set,
  split,
  trim,
  values,
  without,
  replace,
  prop,
} from 'ramda';
import { localizationList, flatQuestionTreeWithPaths } from 'xperience-model-management';
import { parse } from 'papaparse';

const mapIndexed = addIndex(map);

const catchUntranslatedQuestion = reduce(
  (acc, item) => set(lensProp(item), { translateNotFound: `Translation for "${item}" not found` }, acc),
  {}
);

export const convertToPathsWithValue = (csvArray, surveyDefinition) => {
  const firstRow = head(csvArray);
  const idPosition = indexOf('id', firstRow);
  const typePosition = indexOf('type', firstRow);
  const flatQuestion = flatQuestionTreeWithPaths(surveyDefinition.definition, true);
  const questionNames = pluck('id', flatQuestion);
  let cloneQuestionNames = clone(questionNames);
  const unknownColumns = {};

  const languagePositions = pipe(
    mapIndexed((item, index) => ({
      name: trim(item),
      index,
    })),
    filter((item) => {
      if (localizationList[item.name]) {
        return true;
      }
      if (!includes(item.name, ['id', 'type', 'master', ''])) {
        unknownColumns[item.name] = { unknownColumn: `Unknown column ${item.name}` };
      }

      return false;
    })
  )(firstRow);

  const onlyQuestionRows = remove(0, 2, csvArray);

  const getTranslatePathWithValue = (row) => (lang) => {
    const splitPath = split('.', row[typePosition]);
    if (prop(0, splitPath) === 'conditionalTexts' && prop(1, splitPath)) {
      splitPath[1] = Number(splitPath[1]);
    }

    return {
      path: [lang.name, row[idPosition], ...splitPath],
      value: pipe(trim, replace(/^"|"$/g, ''))(row[lang.index]),
    };
  };

  const result = reduce(
    (acc, row) => {
      const setError = (content) => set(lensPath(['errors', row[idPosition]]), content, acc);
      if (!row[idPosition]) {
        if (filter(({ index }) => row[index], languagePositions).length) {
          return setError({ badRow: `Missing id for question` });
        }
        return acc;
      }

      cloneQuestionNames = without([row[idPosition]], cloneQuestionNames);
      if (!includes(row[idPosition], questionNames) && row[idPosition] !== 'common') {
        return setError({ questionNotFound: `Question "${row[idPosition]}" not found` });
      }
      return over(lensProp('translations'), insertAll(-1, map(getTranslatePathWithValue(row), languagePositions)), acc);
    },
    {
      translations: [],
      errors: {},
    },
    onlyQuestionRows
  );

  return {
    ...result,
    errors: {
      ...result.errors,
      ...catchUntranslatedQuestion(cloneQuestionNames),
      ...unknownColumns,
    },
  };
};

export default (onTranslationChange, survey, file) => {
  const array = parse(`${file}`, {
    newline: '\n',
    delimiter: ';',
  });

  const { errors, translations } = convertToPathsWithValue(array.data, survey);

  map((item) => onTranslationChange(item), translations);

  return pipe(values, map(values), flatten, join('\n'))(errors);
};
