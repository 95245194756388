import { pipe, withHandlers } from 'commity-rehook-fork';
import * as PropTypes from 'prop-types';
import { flatten, map, mergeRight, omit, prop, reduce } from 'ramda';
import { allEqualTo } from 'ramda-adjunct';
import { transformForCollector } from './conditionsFormat';
import { validateConditionsRow } from './ConditionsRow';
import { ConditionsModal } from './ConditionsModal';
import { memoizeWithEquals } from '../../../rehooks';

const validateReducer = (item) => {
  if (item.rows) {
    return map(validateReducer, item.rows);
  }
  return validateConditionsRow(item);
};

const validate = pipe(
  prop('rows'),
  reduce((acc, item) => [...acc, validateReducer(item)], []),
  flatten,
  allEqualTo(true)
);

export const ConditionsModalForOutro = pipe(
  withHandlers({
    onSave: ({ updateConditionalText }) => (itemPath, item, conditions, onDialogClose, setValid) => () => {
      if (validate(conditions)) {
        const updatedConditions = pipe(
          transformForCollector,
          mergeRight(omit(['hideIf', 'visibleIf'], item))
        )(conditions);

        updateConditionalText(item.index, { condition: updatedConditions.visibleIf });

        onDialogClose();
      } else {
        setValid(false);
      }
    },
  }),
  omit(['updateConditionalText']),
  memoizeWithEquals(ConditionsModal)
);

ConditionsModalForOutro.propTypes = {
  conditionsRows: PropTypes.array,
  onDialogClose: PropTypes.func,
  updateConditionalText: PropTypes.func,
  typeIsDisabled: PropTypes.bool,
  item: PropTypes.shape({}),
};
ConditionsModalForOutro.defaultProps = {
  typeIsDisabled: true,
};
