import {
  addIndex,
  allPass,
  concat,
  defaultTo,
  filter,
  find,
  flatten,
  has,
  hasPath,
  includes,
  juxt,
  map,
  path,
  pipe,
  pluck,
  prop,
  propEq,
  reduce,
  take,
  uniq,
} from 'ramda';
import { isEscape, getDataType } from 'xperience-model-management';
import {
  ESCAPE_QUESTION_ID,
  getQuestionsAfterQuestionId,
  hasConditions,
  hasEscapeQuestion,
  hasPersonalAgreement,
  PERSONAL_AGREEMENT_ID,
} from '../../components/questionHelper';
import { transformForEditor } from '../../components/objectInspector/conditions/conditionsFormat';
import { PATHS } from '../modules/survey/paths';
import { conditionsType } from '../../conditionsType';
import { createError, ERROR_MESSAGES } from './errors';
import { alwaysApply } from './itemValidators';

const FORBIDDEN_QUESTION_ID = 'common';

export const cannotHaveDuplicateIdsValidator = {
  applyIf: alwaysApply,
  validationFn: ({ flatSurveyDefinition }) =>
    pipe(
      map(item => {
        if (item.id === FORBIDDEN_QUESTION_ID || (!isEscape(item) && item.id === ESCAPE_QUESTION_ID)) {
          return createError({
            message: ERROR_MESSAGES.CANNOT_USE_RESERVED_QUESTION_IDS,
            validator: 'cannotHaveDuplicateIds',
            itemPath: item.path,
          });
        }
        if (filter(allPass([has('id'), propEq('id', item.id)]), flatSurveyDefinition).length > 1) {
          return createError({
            message: ERROR_MESSAGES.CANNOT_HAVE_DUPLICATE_ID,
            validator: 'cannotHaveDuplicateIds',
            itemPath: item.path,
          });
        }
        return null;
      }),
      filter(Boolean)
    )(flatSurveyDefinition),
};

const reduceIndexed = addIndex(reduce);

const getPreItemIds = (index, flatSurveyDefinition) =>
  pipe(
    take(index + 1),
    pluck('id')
  )(flatSurveyDefinition);

const getNames = pluck('name');

const getFirstAndSecondValues = juxt([pluck('firstValue'), pluck('secondValue')]);

const flatConditionsFirstAndSecondValue = pipe(
  getFirstAndSecondValues,
  flatten
);

const getQuestionIdsUsedInCondition = item => {
  return pipe(
    transformForEditor,
    prop('rows'),
    defaultTo([]),
    flatConditionsFirstAndSecondValue,
    filter(Boolean),
    filter(propEq('type', 'variable')),
    pluck('value'),
    uniq
  )(item);
};

export const questionIdsInConditionsMustExistAboveQuestionValidator = {
  applyIf: alwaysApply,
  validationFn: ({ flatSurveyDefinition, placeholders }) =>
    pipe(
      reduceIndexed((acc, item, index) => {
        if (hasConditions(item)) {
          const questionIdsUsedInCondition = getQuestionIdsUsedInCondition(item);
          const preItemIds = concat(getPreItemIds(index, flatSurveyDefinition), getNames(placeholders));
          return concat(
            acc,
            map(questionId => {
              if (!includes(questionId, preItemIds)) {
                const errorMsg = `Question ID/placeholder '${questionId}' used in conditions is either missing or it is not above question '${
                  item.id
                }'.`;
                return createError({
                  message: errorMsg,
                  validator: 'questionIdsInConditionsMustExistAboveQuestionValidator',
                  itemPath: item.path,
                });
              }
              return null;
            }, questionIdsUsedInCondition)
          );
        }
        return acc;
      }, []),
      filter(Boolean)
    )(flatSurveyDefinition),
};

const findQuestionById = id => find(propEq('id', id));

export const questionQZIsAfterQEQuestionValidator = {
  applyIf: ({ flatSurveyDefinition }) => hasEscapeQuestion(flatSurveyDefinition),
  validationFn: ({ flatSurveyDefinition }) => {
    const escapeQuestion = findQuestionById(ESCAPE_QUESTION_ID)(flatSurveyDefinition);
    const beforeExitQuestionId = path(PATHS.beforeExitQuestion, escapeQuestion);
    const validator = 'questionQZIsAfterQEQuestionValidator';
    if (!hasPath(PATHS.beforeExitQuestion, escapeQuestion)) {
      return [];
    }
    if (!beforeExitQuestionId) {
      return [
        createError({
          message: ERROR_MESSAGES.BEFORE_EXIT_QUESTION_IS_NOT_SELECTED,
          validator,
          itemPath: escapeQuestion.path,
        }),
      ];
    }
    const questionsAfterQE = getQuestionsAfterQuestionId(ESCAPE_QUESTION_ID, flatSurveyDefinition);
    const hasBeforeExitQuestionAfterQE = findQuestionById(beforeExitQuestionId)(questionsAfterQE);

    if (!hasBeforeExitQuestionAfterQE) {
      return [
        createError({
          message: `before exit question "${beforeExitQuestionId}" must be below escape question`,
          validator,
          itemPath: escapeQuestion.path,
        }),
      ];
    }
    return [];
  },
};

export const conditionDoesNotMatchQuestionDataTypeValidator = {
  applyIf: alwaysApply,
  validationFn: ({ flatSurveyDefinition }) => {
    const filterInvalidRows = reduce((acc, row) => {
      if (row.rows) {
        return [...acc, ...filterInvalidRows(row.rows)];
      }
      const question = find(propEq('id', row.firstValue.value), flatSurveyDefinition);
      // validate only question
      if (question) {
        const conditionType = conditionsType[row.condition];
        if (!includes(getDataType(question), conditionType.for)) {
          return [...acc, row];
        }
      }
      return acc;
    }, []);

    return pipe(
      reduce((acc, item) => {
        if (hasConditions(item)) {
          const errors = pipe(
            transformForEditor,
            prop('rows'),
            filterInvalidRows,
            map(({ firstValue: { value } }) => {
              const errorMsg = `Question ID '${value}' changed data type. The condition does not match this data type`;
              return createError({
                message: errorMsg,
                validator: 'questionIdsInConditionsMustExistAboveQuestionValidator',
                itemPath: item.path,
              });
            })
          )(item);

          return concat(acc, errors);
        }
        return acc;
      }, [])
    )(flatSurveyDefinition);
  },
};

export const questionQZIsAfterPersonalAgreementValidator = {
  applyIf: ({ flatSurveyDefinition }) => hasPersonalAgreement(flatSurveyDefinition),
  validationFn: ({ flatSurveyDefinition }) => {
    const personalAgreement = findQuestionById(PERSONAL_AGREEMENT_ID)(flatSurveyDefinition);
    const beforeExitQuestionId = path(PATHS.beforeExitQuestion, personalAgreement);
    const validator = 'questionQZIsAfterPersonalAgreementValidator';
    if (!hasPath(PATHS.beforeExitQuestion, personalAgreement)) {
      return [];
    }
    if (!beforeExitQuestionId) {
      return [
        createError({
          message: ERROR_MESSAGES.BEFORE_EXIT_QUESTION_IS_NOT_SELECTED,
          validator,
          itemPath: personalAgreement.path,
        }),
      ];
    }
    const questionsAfterQE = getQuestionsAfterQuestionId(PERSONAL_AGREEMENT_ID, flatSurveyDefinition);
    const hasBeforeExitQuestionAfterQE = findQuestionById(beforeExitQuestionId)(questionsAfterQE);

    if (!hasBeforeExitQuestionAfterQE) {
      return [
        createError({
          message: `before exit question "${beforeExitQuestionId}" must be below personal agreement question`,
          validator,
          itemPath: personalAgreement.path,
        }),
      ];
    }
    return [];
  },
};
