import { dataTypes } from 'xperience-model-management';

export const conditionsType = {
  lessThan: {
    value: 'lessThan',
    name: 'less than',
    for: [dataTypes.integer, dataTypes.date, dataTypes.string],
  },
  lessThanOrEqual: {
    value: 'lessThanOrEqual',
    name: 'less than or equal',
    for: [dataTypes.integer, dataTypes.date, dataTypes.string],
  },
  greaterThan: {
    value: 'greaterThan',
    name: 'greater than',
    for: [dataTypes.integer, dataTypes.date, dataTypes.string],
  },
  greaterThanOrEqual: {
    value: 'greaterThanOrEqual',
    name: 'greater than or equal',
    for: [dataTypes.integer, dataTypes.date, dataTypes.string],
  },
  equal: {
    value: 'equal',
    name: 'equal',
    for: [dataTypes.integer, dataTypes.string, dataTypes.date],
  },
  notEqual: {
    value: 'notEqual',
    name: 'not equal',
    for: [dataTypes.integer, dataTypes.string, dataTypes.date],
  },
  responded: {
    value: 'responded',
    name: 'responded',
    for: [dataTypes.integer, dataTypes.string, dataTypes.integer_array, dataTypes.date, dataTypes.boolean],
  },
  isTrue: {
    value: 'isTrue',
    name: 'true',
    for: [dataTypes.boolean],
  },
  isFalse: {
    value: 'isFalse',
    name: 'false',
    for: [dataTypes.boolean],
  },
  sameArrays: {
    value: 'sameArrays',
    name: 'same array as',
    for: [dataTypes.integer_array],
  },
  someConformityInArrays: {
    value: 'someConformityInArrays',
    name: 'equal to at least one value from',
    for: [dataTypes.integer_array],
  },
  differentArrays: {
    value: 'differentArrays',
    name: 'different arrays',
    for: [dataTypes.integer_array],
  },
  allLessThan: {
    value: 'allLessThan',
    name: 'all less than',
    for: [dataTypes.integer_array],
  },
  someLessThan: {
    value: 'someLessThan',
    name: 'some less than',
    for: [dataTypes.integer_array],
  },
  allLessThanOrEqual: {
    value: 'allLessThanOrEqual',
    name: 'all less than or equal',
    for: [dataTypes.integer_array],
  },
  someLessThanOrEqual: {
    value: 'someLessThanOrEqual',
    name: 'any value less than or equal to',
    for: [dataTypes.integer_array],
  },
  allGreaterThan: {
    value: 'allGreaterThan',
    name: 'all values greater than',
    for: [dataTypes.integer_array],
  },
  someGreaterThan: {
    value: 'someGreaterThan',
    name: 'some greater than',
    for: [dataTypes.integer_array],
  },
  allGreaterThanOrEqual: {
    value: 'allGreaterThanOrEqual',
    name: 'all values greater than or equal to',
    for: [dataTypes.integer_array],
  },
  someGreaterThanOrEqual: {
    value: 'someGreaterThanOrEqual',
    name: 'some greater than or equal',
    for: [dataTypes.integer_array],
  },
};
