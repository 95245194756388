import reduceReducers from 'reduce-reducers';
import { combineEpics } from 'redux-observable';

import overviewReducer, { epics as overviewEpics } from './overview';
import translationsReducer, { epics as translationsEpics } from './translations';

export default reduceReducers(
  {
    definition: [],
    translations: {},
  },
  overviewReducer,
  translationsReducer
);

export const epics = combineEpics(overviewEpics, translationsEpics);
