import { applyMiddleware, compose, createStore } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import { rootEpic, rootReducer } from './modules/root';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const configureStore = () => {
  const epicMiddleware = createEpicMiddleware({});

  const createEnhancedStore = composeEnhancers(applyMiddleware(epicMiddleware))(createStore);

  const store = createEnhancedStore(rootReducer, {});
  epicMiddleware.run(rootEpic);

  return store;
};

export default configureStore;
