import classNames from 'classnames';
import { pipe } from 'commity-rehook-fork';
import { anyPass, reject } from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { flatQuestionTree, isIntro, isOutro } from 'xperience-model-management';
import { withStylesAsClasses } from '../../rehooks';
import { getMasterSurvey, getMasterTranslation } from '../../state/modules/editor';
import { addNewQuestionFromMaster, getSurvey } from '../../state/modules/survey/overview';
import { getDraggableId } from '../dndHelper';
import { hasAllMandatoryQuestionsFromPageInSurvey, isQuestionInSurvey, isQuestionMandatory } from '../questionHelper';
import { getTranslationItemFromMasterTranslations } from '../translations/translationHelper';
import { QuestionBankItemHeader } from './QuestionBankItemHeader';

const styles = () => ({
  root: {},
});

const QuestionDatabankPure = ({
  classes,
  masterSurvey,
  onQuestionSelect,
  survey,
  openDatabasePanel,
  masterTranslations,
  ...others
}) => {
  const flattenQuestionsTree = flatQuestionTree(survey.definition);
  const questionsWithoutIntro = reject(anyPass([isIntro, isOutro]), masterSurvey.definition);

  return openDatabasePanel ? (
    <div {...others} className={classNames(classes.root, others.className)}>
      {questionsWithoutIntro.map((item, index) => {
        const modelPath = [index];
        const draggableId = getDraggableId(modelPath);
        return (
          <QuestionBankItemHeader
            key={draggableId}
            modelPath={modelPath}
            item={item}
            mandatory={isQuestionMandatory(item, flattenQuestionsTree)}
            allMandatoryQuestionsFromPageInSurvey={hasAllMandatoryQuestionsFromPageInSurvey(item, flattenQuestionsTree)}
            questionAlreadyInSurvey={isQuestionInSurvey(item, flattenQuestionsTree)}
            onSelect={onQuestionSelect(item, masterTranslations)}
            translationItem={getTranslationItemFromMasterTranslations(masterTranslations, item)}
          />
        );
      })}
    </div>
  ) : null;
};

const StyledQuestionDatabank = pipe(
  withStylesAsClasses(styles),
  QuestionDatabankPure
);

const mapStateToProps = state => ({
  masterSurvey: getMasterSurvey(state),
  survey: getSurvey(state),
  masterTranslations: getMasterTranslation(state),
});
const mapDispatchToProps = dispatch => {
  return {
    onQuestionSelect: (item, masterTranslations) => () => {
      dispatch(addNewQuestionFromMaster(item, masterTranslations));
    },
  };
};

export const QuestionDatabank = connect(
  mapStateToProps,
  mapDispatchToProps
)(StyledQuestionDatabank);

QuestionDatabank.propTypes = {};
