import { filter, map, pipe } from 'ramda';
import { flatQuestionTree } from 'xperience-model-management';
import { isQuestionInSurvey, isQuestionMandatory } from '../../components/questionHelper';
import { createError, ERROR_MESSAGES } from './errors';

export const missingMasterQuestionsInSurvey = {
  applyIf: ({ masterSurvey }) => Boolean(masterSurvey),
  validationFn: ({ flatSurveyDefinition, masterSurvey }) => {
    const flattenMasterQuestions = flatQuestionTree(masterSurvey.definition);
    return pipe(
      filter(item => isQuestionMandatory(item, flattenMasterQuestions)),
      map(item => {
        if (!isQuestionInSurvey(item, flatSurveyDefinition)) {
          return createError({
            message: ERROR_MESSAGES.MISSING_MANDATORY_QUESTION_FROM_MASTER,
            validator: 'missingMasterQuestionsInSurvey',
            itemId: item.id,
          });
        }
        return null;
      }),
      filter(Boolean)
    )(flattenMasterQuestions);
  },
};
