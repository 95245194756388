import { equals, join } from 'ramda';

const DRAGGABLE_SEPARATOR = '.';
export const DRAG_AND_DROP_ROOT = [];

export const getDraggableId = join(DRAGGABLE_SEPARATOR);

export const isDragAndDropRoot = equals(DRAG_AND_DROP_ROOT);

export const getDragAndDropRootPath = () => DRAG_AND_DROP_ROOT;

export const DraggableTypes = {
  QUESTION: 'question',
  CHOICE: 'choice',
  CONDITION_ROW: 'conditionRow',
};

export const getDroppableClassName = (classes, isOver, canDrop) => {
  if (!isOver) {
    return '';
  }
  return canDrop ? classes.canDrop : classes.cannotDrop;
};
