import createReducer from 'create-reducer';
import { combineEpics, ofType } from 'redux-observable';
import { debounceTime, filter, map, mapTo, observeOn, share } from 'rxjs/operators';
import { path, pathOr } from 'ramda';
import { asyncScheduler, merge } from 'rxjs';
import { validatorContainer } from '../../validations/validatorContainer';
import { processValidationResults, VALIDATION_STATUS } from '../../validations/validationHelper';
import { SELECT_ITEM } from '../editor/handlers';

const VALIDATION_REQUEST = 'validation_request';
const VALIDATE_INTERNAL = 'validate-now';
const VALIDATE_PENDING = 'validate-pending';

export const validate = () => ({
  type: VALIDATION_REQUEST,
});

const handlers = {
  '@@INIT': (state, action) => ({
    validationResults: {
      status: VALIDATION_STATUS.PENDING,
      results: [],
    },
    validationNumber: 0,
  }),
  [VALIDATE_INTERNAL]: (state, { validatorProps }) => {
    const validationResults = validatorContainer(validatorProps);
    const surveyDefinition = path(['survey', 'definition'], validatorProps);
    return {
      validationResults: {
        status: validationResults.status,
        results: processValidationResults(validationResults, surveyDefinition),
      },
      validationNumber: state.validationNumber++,
    };
  },
  [VALIDATE_PENDING]: () => {
    return {
      validationResults: {
        status: VALIDATION_STATUS.PENDING,
        results: [],
      },
    };
  },
};

const STATE_PATH = ['undoable', 'present'];

const VALIDATION_PATHS = {
  root: ['validations'],
  validationResults: ['validationResults'],
  survey: [...STATE_PATH, 'survey'],
  masterSurvey: [...STATE_PATH, 'editor', 'masterSurvey'],
  placeholders: [...STATE_PATH, 'editor', 'placeholders'],
  translations: [...STATE_PATH, 'survey', 'translations'],
};

export default createReducer(handlers, () => ({}));

export const getValidatorProps = state => {
  return {
    survey: path(VALIDATION_PATHS.survey, state),
    placeholders: path(VALIDATION_PATHS.placeholders, state),
    translations: path(VALIDATION_PATHS.translations, state),
    masterSurvey: path(VALIDATION_PATHS.masterSurvey, state),
  };
};

const AUTO_VALIDATION_IGNORED_ACTIONS = [VALIDATE_INTERNAL, VALIDATE_PENDING, VALIDATION_REQUEST, SELECT_ITEM];

const autoValidationEpic = action$ =>
  action$.pipe(
    filter(action => !AUTO_VALIDATION_IGNORED_ACTIONS.includes(action.type)),
    debounceTime(3000),
    map(() => ({
      type: VALIDATION_REQUEST,
    }))
  );

const delayedValidationEpic = (action$, state$) => {
  const delayedValidation$ = action$.pipe(
    ofType(VALIDATION_REQUEST),
    share()
  );

  const delayedValidationEpic$ = delayedValidation$.pipe(
    observeOn(asyncScheduler),
    map(() => ({
      type: VALIDATE_INTERNAL,
      validatorProps: getValidatorProps(state$.value),
    }))
  );

  const delayedValidationPending$ = delayedValidation$.pipe(
    mapTo({
      type: VALIDATE_PENDING,
    })
  );
  return merge(delayedValidationPending$, delayedValidationEpic$);
};

export const epics = combineEpics(delayedValidationEpic, autoValidationEpic);

export const getPathWithRoot = (innerPath = []) => [...VALIDATION_PATHS.root, ...innerPath];

export const getValidationResults = pathOr(
  { status: VALIDATION_STATUS.PENDING, results: [] },
  getPathWithRoot(VALIDATION_PATHS.validationResults)
);
