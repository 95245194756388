export const createError = ({ message, validator, itemPath, language, itemId }) => ({
  message,
  validator,
  itemPath,
  language,
  itemId, // optional - when you cannot use itemPath - master validations
});

export const ERROR_MESSAGES = {
  ALWAYS_INVALID: 'always invalid!',
  CHOICES_ARE_EMPTY: 'choices are empty',
  CANNOT_APPLY_BOTH_VISIBILITY_CONDITIONS_AT_ONCE: 'cannot apply both visibility conditions (visible/hide if) at once',
  CANNOT_HAVE_DUPLICATE_ID: 'question ID used in conditions is either missing or it is not above question with ID',
  CHOICE_VALUE_IN_STAR_RANGE: 'choice value cannot be in Star rating range (1..5)',
  CHOICE_VALUE_IN_SQUARE_RANGE: 'choice value cannot be in Square rating range (1..10)',
  CHOICE_VALUE_IN_DYNAMIC_SQUARE_RANGE: 'choice value cannot be in Dynamic square rating range (1..10)',
  CANNOT_USE_RESERVED_QUESTION_IDS: "question can not use reserved question IDs ('common', 'QE')",
  MISSING_TRANSLATION: 'missing translation',
  MISSING_MANDATORY_QUESTION_FROM_MASTER: 'missing mandatory question from master',
  BEFORE_EXIT_QUESTION_IS_NOT_SELECTED: 'before exit question is not selected',
  CONDITION_ARE_EMPTY: 'some of condition for conditional texts are not filled',
  REDIRECT_SECONDS_IS_BAD: 'Redirect time must be within range 0 - 60',
  REDIRECT_LINK_IS_EMPTY: 'Redirect link is empty',
  REDIRECT_LINK_WITHOUT_HTTP: 'Redirect link must contain http or https',
};
