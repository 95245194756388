import { Button, FormLabel } from '@material-ui/core';
import classNames from 'classnames';
import { pipe } from 'commity-rehook-fork';
import * as PropTypes from 'prop-types';
import { any, has, pluck, propEq } from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { withStylesAsClasses } from '../../../../rehooks';
import { addNewChoice, deleteItem } from '../../../../state/modules/survey/overview';
import { updateTranslation } from '../../../../state/modules/survey/translations';
import { HIDEABLE_CHOICE_VALUE } from '../../../questionHelper';
import { DraggableChoiceForm } from './ChoiceForm';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing.unit,
  },

  choiceButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing.unit,
  },

  addChoiceButton: {
    marginLeft: theme.spacing.unit,
  },
});

const ChoicesListPure = ({
  item,
  choicesTranslations,
  onTranslationChange,
  onAddNewChoice,
  onDelete,
  modelPath,
  translationPath,
  classes,
  config,
  parentPageModelPath,
  readOnly,

  ...others
}) => {
  const hideAddEscapeButton = any(propEq('value', HIDEABLE_CHOICE_VALUE), item.choices || []);
  return (
    <div {...others} className={classNames(classes.root, others.className)}>
      <FormLabel component="legend">Choices</FormLabel>
      {has('choices', item) &&
        item.choices.map((choice, index) => (
          <DraggableChoiceForm
            key={choice.value}
            index={index}
            modelPath={[...modelPath, 'choices', index]}
            translationPath={[...translationPath, 'choices', choice.value.toString()]}
            choiceTranslation={choicesTranslations && choicesTranslations[choice.value]}
            choice={choice}
            choicesValues={pluck('value', item.choices)}
            item={item}
            onTranslationChange={onTranslationChange}
            onDelete={onDelete}
            config={config}
            readOnly={readOnly}
          />
        ))}
      {config.addChoice && (
        <div className={classes.choiceButtonContainer}>
          {!hideAddEscapeButton && (
            <Button
              color="primary"
              variant="outlined"
              title="Add escape answer"
              aria-label="Add"
              className={classes.addChoiceButton}
              onClick={onAddNewChoice([...modelPath, 'choices'], HIDEABLE_CHOICE_VALUE)}
              disabled={readOnly}>
              Add escape answer
            </Button>
          )}
          <Button
            color="primary"
            variant="outlined"
            title="Add choice"
            aria-label="Add"
            className={classes.addChoiceButton}
            onClick={onAddNewChoice([...modelPath, 'choices'])}
            disabled={readOnly}>
            Add Choice
          </Button>
        </div>
      )}
    </div>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    onTranslationChange: ({ translationPath, value }) => {
      dispatch(updateTranslation(translationPath, value));
    },
    onAddNewChoice: (itemPath, value) => () => {
      dispatch(addNewChoice(itemPath, value));
    },
    onDelete: (itemPath, translationPath) => {
      dispatch(deleteItem(itemPath, translationPath));
    },
  };
};

const StyledChoicesList = pipe(
  withStylesAsClasses(styles),
  ChoicesListPure
);

const mapStateToProps = () => ({});

export const ChoicesList = connect(
  mapStateToProps,
  mapDispatchToProps
)(StyledChoicesList);

ChoicesList.propTypes = {
  choicesTranslations: PropTypes.shape({}),
  item: PropTypes.shape({}),
  modelPath: PropTypes.array.isRequired,
  config: PropTypes.shape({
    addChoice: PropTypes.bool,
    deleteChoice: PropTypes.bool,
  }),
  readOnly: PropTypes.bool,
};
