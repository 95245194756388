import React from 'react';

import { IconButton, Menu } from '@material-ui/core';
import { ImportExport } from '@material-ui/icons';
import classNames from 'classnames';
import { pipe } from 'ramda';
import { withStylesAsClasses } from '../rehooks';
import DownloadCsv from './DownloadCsv';
import ImportCsv from './ImportCsv';

const styles = () => ({
  root: {},
});

const ImportExportMenu = ({ classes, ...others }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  return (
    <div {...others} className={classNames(classes.root, others.className)}>
      <IconButton color="inherit" aria-label="Import / Export" title="Import / Export" onClick={handleClick}>
        <ImportExport />
      </IconButton>
      <Menu id="fade-menu" disableAutoFocusItem anchorEl={anchorEl} open={open} onClose={handleClose}>
        <ImportCsv />
        <DownloadCsv />
      </Menu>
    </div>
  );
};

export default pipe(
  withStylesAsClasses(styles),
  ImportExportMenu
);
