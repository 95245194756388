import classNames from 'classnames';
import { pipe, withHandlers } from 'commity-rehook-fork';
import * as PropTypes from 'prop-types';
import { omit } from 'ramda';
import React from 'react';
import keycode from 'keycode';
import { withStylesAsClasses } from '../rehooks';
import { Creatable, Select } from './Autocomplete';

const styles = () => ({
  root: {},
});

const onCreateOption = val => {
  return `Value: ${val}`;
};

const ExpressionArgumentSelectPure = ({
  allowCreateValues,
  classes,
  disabled,
  onBlur,
  onCreate,
  onChange,
  onKeyDown,
  options,
  placeholder,
  value,
  ...others
}) => {
  const Autocomplete = allowCreateValues ? Creatable : Select;

  return (
    <div {...others} className={classNames(classes.root, others.className)}>
      <Autocomplete
        placeholder={placeholder}
        formatCreateLabel={onCreateOption}
        onBlur={onBlur}
        onBlurResetsInput={false}
        onCreateOption={onCreate}
        onChange={onChange}
        onKeyDown={onKeyDown}
        options={options}
        value={value}
        isDisabled={disabled}
      />
    </div>
  );
};

export const ExpressionArgumentSelect = pipe(
  withHandlers({
    onChange: ({ onSelect }) => value => onSelect(value),
    onBlur: ({ onSelect, allowCreateValues }) => ({ target }) => {
      return allowCreateValues && target.value
        ? onSelect({ label: `Value: ${target.value}`, value: target.value, type: 'value' })
        : undefined;
    },
    onCreate: ({ onSelect }) => value => {
      return onSelect({ label: `Value: ${value}`, value, type: 'value' });
    },
    onKeyDown: ({ onSelect }) => event => {
      if (keycode.isEventKey(event, 'backspace') || keycode.isEventKey(event, 'delete')) {
        onSelect({});
      }
    },
  }),
  withStylesAsClasses(styles),
  omit(['onSelect']),
  ExpressionArgumentSelectPure
);

export const valuePropType = PropTypes.shape({
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.oneOf(['variable', 'value']),
});

ExpressionArgumentSelect.propTypes = {
  options: PropTypes.arrayOf(valuePropType).isRequired,
  onSelect: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([valuePropType, PropTypes.bool]),
  allowCreateValues: PropTypes.bool,
};
ExpressionArgumentSelect.defaultProps = {
  value: false,
  allowCreateValues: true,
  disabled: false,
  placeholder: '',
};
