import classNames from 'classnames';
import { pipe, withHandlers } from 'commity-rehook-fork';
import * as PropTypes from 'prop-types';
import { applySpec, map, omit, prop, values } from 'ramda';
import React from 'react';
import Select from 'react-select';
import { localizationList } from 'xperience-model-management';
import { withStylesAsClasses } from '../rehooks';

const styles = theme => ({
  root: {
    fontFamily: theme.typography.fontFamily,
  },
});

const localization = map(
  applySpec({
    label: prop('localName'),
    value: prop('code'),
  })
)(localizationList);

const LocaleSelectPure = ({ classes, onChange, value, omitLocales, autoFocus, selectProps, ...others }) => {
  const getLocalization = pipe(
    omit(omitLocales),
    values
  );

  return (
    <div {...others} className={classNames(classes.root, others.className)}>
      <Select
        {...selectProps}
        autoFocus={autoFocus}
        options={getLocalization(localization)}
        value={value}
        onChange={onChange}
      />
    </div>
  );
};

export const LocaleSelect = pipe(
  withHandlers({
    onChange: ({ onSelect }) => event => onSelect(event.value),
  }),
  withStylesAsClasses(styles),
  omit(['onSelect']),
  LocaleSelectPure
);

LocaleSelect.propTypes = {
  onSelect: PropTypes.func.isRequired,
  omitLocales: PropTypes.array,
  selectProps: PropTypes.shape({
    placeholder: PropTypes.string,
  }),
  value: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }),
};

LocaleSelect.defaultProps = {
  selectProps: {
    placeholder: 'Select language',
  },
  omitLocales: [],
  autoFocus: false,
};
