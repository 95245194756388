import { allPass, any, is, mergeDeepRight, not, over, pathSatisfies, pipe, prop, propSatisfies, when } from 'ramda';
import {
  definitionLens,
  hasTempMetadataFallback,
  isVersion,
  moveTempMetadata,
  replaceSecondaryToVisualType,
  transformBeforeEscape,
} from 'xperience-model-management';
import { addHiddenFalseToHideableChoices } from './addHiddenFalseToHideableChoices';
import { expressionsToTexts } from './expressionsToTexts';
import { fixConditionOperationNames } from './fixConditionNames';
import { transformSurveyToV2 } from './transformChoices';
import { fixConditionValues } from './fixConditionValues';

const updateToVersionTwo = mergeDeepRight({ metadata: { version: 2, variant: 'EDITOR_MULTI_LANGUAGE' } });

const versionIsNotDefined = pathSatisfies(not, ['metadata', 'version']);

const hasSomeChoicesInV2Format = pipe(prop('definition'), any(propSatisfies(is(Array), 'choices')));

const shouldFixMetadataVersion = allPass([versionIsNotDefined, hasSomeChoicesInV2Format]);

export const surveyPreProcessing = pipe(
  fixConditionOperationNames,
  fixConditionValues,

  // DB contains some broken data (without metadata), this code try add V2 metadata, when choices format correspond to V2
  when(shouldFixMetadataVersion, updateToVersionTwo),

  expressionsToTexts,
  when(
    isVersion(1),
    pipe(
      transformSurveyToV2,
      updateToVersionTwo,
      when(hasTempMetadataFallback, moveTempMetadata),
      over(definitionLens, transformBeforeEscape)
    )
  ),
  addHiddenFalseToHideableChoices,
  replaceSecondaryToVisualType
);
