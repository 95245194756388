import { Checkbox, FormControlLabel, TextField } from '@material-ui/core';
import classNames from 'classnames';
import { pipe, withHandlers } from 'commity-rehook-fork';
import * as PropTypes from 'prop-types';
import { omit, propOr } from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { withStylesAsClasses } from '../../../rehooks';
import { getSelectedItemPath } from '../../../state/modules/editor';
import {
  getAutofocus,
  getTextFieldRowsValue,
  toggleAutofocus,
  updateTextFieldRowsValue,
} from '../../../state/modules/survey/overview';
import {
  getSelectedTranslationItem,
  getTranslationPathForItem,
  updateTranslation,
} from '../../../state/modules/survey/translations';
import { createTranslationOnChangeHandler } from '../../handlerHelper';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },

  rowsInputWithAutofocusContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },

  rowsInput: {
    width: 40,
  },
});

const TextQuestionOIPure = ({
  item,
  translationItem,
  onPlaceholderChange,
  modelPath,
  autofocus,
  readOnly,
  classes,
  onAutofocusToggle,
  rowsValue,
  onTextFieldRowsValueChange,
  ...others
}) => {
  return (
    <div {...others} className={classNames(classes.root, others.className)}>
      <TextField
        id="placeholder"
        label="Textfield Placeholder"
        placeholder="..."
        multiline
        value={propOr('', 'placeholder', translationItem)}
        onChange={onPlaceholderChange}
        margin="normal"
        disabled={readOnly}
      />
      <div className={classes.rowsInputWithAutofocusContainer}>
        <TextField
          id="row"
          label="Rows"
          type="number"
          placeholder="..."
          className={classes.rowsInput}
          value={rowsValue}
          onChange={onTextFieldRowsValueChange(modelPath)}
          margin="normal"
          InputProps={{ inputProps: { min: 0, max: 10 } }}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <FormControlLabel
          labelPlacement="top"
          control={
            <Checkbox checked={autofocus} color="primary" onChange={onAutofocusToggle(modelPath)} title="Autofocus" />
          }
          label="Autofocus"
        />
      </div>
    </div>
  );
};

const TextQuestionOIWithStyles = pipe(
  withHandlers({
    onPlaceholderChange: createTranslationOnChangeHandler(['placeholder']),
  }),
  withStylesAsClasses(styles),
  omit(['translationPath', 'onTranslationChange']),
  TextQuestionOIPure
);

const mapStateToProps = (state, { item }) => ({
  translationItem: getSelectedTranslationItem(state),
  translationPath: getTranslationPathForItem(state, item),
  modelPath: getSelectedItemPath(state),
  autofocus: getAutofocus(state),
  rowsValue: getTextFieldRowsValue(state),
});

const mapDispatchToProps = dispatch => {
  return {
    onTranslationChange: ({ translationPath, value }) => {
      dispatch(updateTranslation(translationPath, value));
    },
    onAutofocusToggle: modelPath => (event, checked) => {
      dispatch(toggleAutofocus(checked, modelPath));
    },
    onTextFieldRowsValueChange: modelPath => event => {
      dispatch(updateTextFieldRowsValue(event.target.value, modelPath));
    },
  };
};

export const TextQuestionOI = connect(
  mapStateToProps,
  mapDispatchToProps
)(TextQuestionOIWithStyles);

TextQuestionOI.propTypes = {
  readOnly: PropTypes.bool,
};
