import { ExpansionPanel, ExpansionPanelSummary, Typography } from '@material-ui/core';
import { ExpandMore, Warning } from '@material-ui/icons/index';
import classNames from 'classnames';
import { pipe, withStateHandlers } from 'commity-rehook-fork';
import * as PropTypes from 'prop-types';
import { isNil, prop, reject } from 'ramda';
import React from 'react';
import { memoizeWithEquals, withStylesAsClasses } from '../../../rehooks';
import { ValidationResultDetails } from './ValidationResultDetails';

const styles = theme => ({
  root: {
    marginBottom: theme.spacing.unit / 2,
  },

  height: {
    height: 48,
    alignItems: 'normal',
  },

  errorsHeaderTitle: {
    flex: 1,
  },

  errorCountContainer: {
    display: 'flex',
    alignItems: 'center',
  },

  errorCount: {
    paddingLeft: theme.spacing.unit / 2,
    fontWeight: 'bold',
    fontSize: 20,
  },
});
const ValidationResultPure = ({ classes, expand, validationResult, panelExpandToggle, isEditorView, ...others }) => {
  const translationErrors = reject(error => isNil(prop('language', error)), validationResult.errors);
  const validationErrorCount = isEditorView ? validationResult.errors.length : translationErrors.length;
  const headerTitle = `${validationResult.id}.`;
  return (
    <div {...others} className={classNames(classes.root, others.className)}>
      <ExpansionPanel expanded={expand} onChange={panelExpandToggle} square>
        <ExpansionPanelSummary className={classes.height} expandIcon={<ExpandMore />}>
          <Typography className={classes.errorsHeaderTitle} title={headerTitle}>
            {headerTitle}
          </Typography>
          <div title="Number of Errors" className={classes.errorCountContainer}>
            <Warning color="error" />
            <Typography className={classes.errorCount}>{validationErrorCount}</Typography>
          </div>
        </ExpansionPanelSummary>
        <ValidationResultDetails validationResult={validationResult} isEditorView={isEditorView} />
      </ExpansionPanel>
    </div>
  );
};

export const ValidationResult = pipe(
  withStateHandlers(({ expand }) => expand, {
    panelExpandToggle: ({ expand }) => () => ({
      expand: !expand,
    }),
  }),
  withStylesAsClasses(styles),
  memoizeWithEquals(ValidationResultPure)
);

ValidationResult.propTypes = {
  validationResult: PropTypes.object.isRequired,
  expand: PropTypes.bool.isRequired,
  isEditorView: PropTypes.bool,
};
