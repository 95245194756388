import {
  always,
  append,
  applySpec,
  assoc,
  cond,
  filter,
  lensPath,
  map,
  omit,
  over,
  path,
  pick,
  pipe,
  prop,
  propEq,
  propOr,
  props,
  when,
  __,
} from 'ramda';
import { ensureArray, isNotEmpty, isNotNil, mergeProps } from 'ramda-adjunct';
import { dataTypes } from 'xperience-model-management';

const isTypeValue = propEq('type', 'value');
const isOperationNot = propEq('operation', 'not');
const isOperationAnd = propEq('operation', 'and');
const isOperationOr = propEq('operation', 'or');
const isNegation = propEq('isNegation', 1);
const hasNegation = pipe(
  omit(['isNegation']),
  applySpec({
    type: always('function'),
    operation: always('not'),
    arguments: append(__, []),
  })
);

const createLabelByType = (item) => assoc('label', `Value${isTypeValue(item) ? '' : ' from'}: ${item.value}`, item);
const onlyDefined = filter((item) => item);

const isDataTypeString = propEq('dataType', dataTypes.string);
const isPossibleToTransform = (a, b) =>
  isTypeValue(a) &&
  b.dataType &&
  !isDataTypeString(b) &&
  !isNaN(a.value) &&
  Number(a.value).toString().length === a.value.length;
const transformValue = (propName, acc) => over(lensPath([propName, 'value']), Number, acc);

export const transformValueToInteger = (row) => {
  if (row.firstValue && row.secondValue) {
    if (isPossibleToTransform(row.firstValue, row.secondValue)) {
      return transformValue('firstValue', row);
    } else if (isPossibleToTransform(row.secondValue, row.firstValue)) {
      return transformValue('secondValue', row);
    }
  }

  return row;
};

export const mapArgsForCollector = (row) => {
  if (row.rows) {
    return {
      type: 'function',
      operation: row.operation,
      arguments: map(mapArgsForCollector, row.rows),
    };
  }

  return pipe(
    transformValueToInteger,
    applySpec({
      isNegation: prop('isNegation'),
      type: always('function'),
      operation: prop('condition'),
      arguments: pipe(props(['firstValue', 'secondValue']), onlyDefined, map(pick(['type', 'value']))),
    }),
    when(isNegation, hasNegation),
    omit(['isNegation'])
  )(row);
};
const mapArgsForEditor = applySpec({
  condition: prop('operation'),
  firstValue: pipe(path(['arguments', 0]), createLabelByType),
  secondValue: pipe(path(['arguments', 1]), when(isNotNil, createLabelByType)),
  isNegation: propOr(0, 'isNegation'),
});

const procedureByOperation = cond([
  [isOperationAnd, (item) => ({ operation: item.operation, rows: map(procedureByOperation, item.arguments) })],
  [isOperationOr, (item) => ({ operation: item.operation, rows: map(procedureByOperation, item.arguments) })],
  [isOperationNot, (item) => procedureByOperation(assoc('isNegation', 1, item.arguments[0]))],
  [always(true), mapArgsForEditor],
]);

// zatím nelze využít operation OR
export const transformForCollector = ({ type, rows, operation = 'and' }) => {
  if (rows.length === 1 && !rows[0].rows) {
    return { [type]: omit(['type'], mapArgsForCollector(rows[0])) };
  } else if (rows.length === 0) {
    return {};
  }
  return {
    [type]: {
      operation,
      arguments: map(mapArgsForCollector, rows),
    },
  };
};

export const transformRowsForEditor = (rows) => {
  return isNotEmpty(rows) ? procedureByOperation(rows) : { rows: [] };
};

export const transformForEditor = (item = {}) => {
  const mergedProps = mergeProps(['hideIf', 'visibleIf'], item);
  const transformedRowsForEditor = transformRowsForEditor(mergedProps);
  return {
    type: item.hideIf ? 'hideIf' : 'visibleIf',
    rows: transformedRowsForEditor.rows || ensureArray(transformedRowsForEditor),
  };
};
