import 'core-js';
import 'regenerator-runtime/runtime';

import { createMuiTheme } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/styles';
import * as PropTypes from 'prop-types';
import { filter, path, pipe } from 'ramda';
import * as React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Provider } from 'react-redux';
import { v4 as uuid } from 'uuid';
import { normalizePlaceholders } from 'xperience-model-management';
import { Editor } from './components/Editor';
import { EditorHandler } from './EditorHandler';
import configureStore from './state/configureStore';
import { initialize, initializeMaster, initializeSurvey } from './state/modules/survey/overview';
import { PATHS } from './state/modules/survey/paths';
import themeDefinition from './themeDefinition';
import { SurveyLanguageDialog } from './components/SurveyLanguageDialog';
import { ErrorBoundary } from './ErrorBoundary';
import { CrashErrorDialog } from './components/CrashErrorDialog';

class EditorAppPure extends React.Component {
  constructor(props) {
    super(props);
    const { masterDefinition, placeholders, surveyDefinition } = this.props;

    this.store = configureStore();
    this.theme = createMuiTheme(themeDefinition);
    this.editorGuid = uuid();
    this.state = { hasError: false, errorMsgHeader: null, errorMessage: null };
    try {
      this.init(surveyDefinition, masterDefinition, placeholders);
    } catch (error) {
      this.state = { hasError: true, errorMsgHeader: 'Data pre-processing error:', errorMessage: error.toString() };
    }
    this.handler = new EditorHandler(this.store);
  }

  init(surveyDefinition, masterDefinition, placeholders) {
    this.editorGuid = uuid();
    this.surveyLanguageDialogOpen = false;
    const normalizedPlaceholders = normalizePlaceholders(placeholders);

    if (surveyDefinition) {
      // otevření existujícího dotazníku (může být sám i master, pokud nemá masterDefinition)
      this.store.dispatch(initialize(surveyDefinition, masterDefinition, normalizedPlaceholders, this.editorGuid));
      return;
    }

    if (masterDefinition) {
      this.surveyLanguageDialogOpen = true;
      // vytváříme nový dotazník z masteru, je třeba vybrat jazyk, z masterDefinition kopii definition a do vybraného translation jazyka texty z masteru
      this.store.dispatch(
        initializeSurvey(
          { definition: [], translations: [] },
          {
            definition: [],
            translations: {},
          },
          [],
          this.editorGuid
        )
      );
      return;
    }

    // vytváříme zcela nový master, nastavit en_US jako výchozí jazyk a dotazník je prázdný
    this.store.dispatch(initializeMaster([], this.editorGuid));
  }

  initMasterWithLanguage = (language) => {
    const { masterDefinition, placeholders, surveyDefinition } = this.props;
    this.surveyLanguageDialogOpen = false;
    this.store.dispatch(initializeSurvey(surveyDefinition, masterDefinition, placeholders, this.editorGuid, language));
  };

  componentWillReceiveProps(nextProps, nextContext) {
    const guid = path(['surveyDefinition', 'metadata', 'temp', 'guid'], nextProps);

    if (!guid || this.editorGuid !== guid) {
      this.init(nextProps.surveyDefinition, nextProps.masterDefinition, nextProps.placeholders);
    }

    // TODO update state?
  }

  componentDidMount() {
    if (this.props.handlerProvider) {
      this.props.handlerProvider(this.handler);
    }

    if (this.props.onChange) {
      this.store.subscribe(() => {
        const state = this.store.getState();

        return pipe(path(PATHS.root), filter(Boolean), this.props.onChange)(state);
      });
    }
  }

  render() {
    return (
      <DndProvider backend={HTML5Backend}>
        <Provider store={this.store}>
          <ThemeProvider theme={this.theme}>
            {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
            <CssBaseline />
            <SurveyLanguageDialog
              open={this.surveyLanguageDialogOpen}
              onOkLanguageClick={this.initMasterWithLanguage}
            />
            <CrashErrorDialog
              open={this.state.hasError}
              errorMsgHeader={this.state.errorMsgHeader}
              errorMessage={this.state.errorMessage}
              onOkClick={() => this.setState({ hasError: false, errorMsgHeader: '', errorMessage: '' })}
            />
            <ErrorBoundary>
              <Editor fullscreen={this.props.fullscreen} onFullscreenChange={this.props.onFullscreenChange} />
            </ErrorBoundary>
          </ThemeProvider>
        </Provider>
      </DndProvider>
    );
  }
}

export const EditorApp = EditorAppPure;

EditorApp.propTypes = {
  surveyDefinition: PropTypes.object,
  masterDefinition: PropTypes.object,
  placeholders: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  handlerProvider: PropTypes.func.isRequired,
  onFullscreenChange: PropTypes.func,
  fullscreen: PropTypes.bool,
};
