import { AppBar, Badge, CircularProgress, IconButton, Tab, Tabs, Toolbar } from '@material-ui/core';
import { Check, Fullscreen, FullscreenExit, Redo, Settings, Undo, Warning } from '@material-ui/icons';
import classNames from 'classnames';
import { pipe, withStateHandlers } from 'commity-rehook-fork';
import * as PropTypes from 'prop-types';
import { length, reduce, values } from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { ActionCreators } from 'redux-undo';
import { memoize, withStylesAsClasses } from '../rehooks';
import {
  EDITOR_VIEW,
  getSelectedCurrentLanguage,
  getSelectedEditorView,
  selectCurrentLanguage,
  selectEditorView,
} from '../state/modules/editor';
import { hasRedo, hasUndo } from '../state/modules/root';
import { getSurveyLanguages } from '../state/modules/survey/translations';
import { VALIDATION_STATUS } from '../state/validations/validationHelper';
import { getValidationResults } from './../state/modules/validations/index';
import { LanguageMenu } from './LanguageMenu';
import { SurveySettingsDialog } from './SurveySettingsDialog';
import ImportExportMenu from './ImportExportMenu';

const styles = (theme) => ({
  root: {},

  grow: {
    flexGrow: 1,
  },

  alertContainer: {
    flexGrow: 1,
  },

  toolbarLanguageSelect: {
    color: theme.palette.background.primary,
    paddingRight: theme.spacing.unit * 4,
  },

  placeholderButton: {
    marginRight: theme.spacing.unit * 4,
  },

  toolbarIcons: {
    marginRight: theme.spacing.unit,
  },
});

const NOTIFICATION_TYPES = {
  [VALIDATION_STATUS.VALID]: {
    Icon: Check,
    iconConfig: {
      color: 'inherit',
      size: '30px',
      titleAccess: 'Survey is valid',
    },

    badgeConfig: {
      invisible: true,
      color: 'secondary',
    },
  },

  [VALIDATION_STATUS.INVALID]: {
    Icon: Warning,
    iconConfig: {
      color: 'inherit',
      size: '30px',
      titleAccess: 'Survey is invalid',
    },

    badgeConfig: {
      invisible: false,
      color: 'secondary',
    },
  },

  [VALIDATION_STATUS.PENDING]: {
    Icon: CircularProgress,
    iconConfig: {
      color: 'inherit',
      size: '24px',
      title: 'Validating survey..',
    },

    badgeConfig: {
      invisible: true,
      color: 'primary',
    },
  },
};

const ToolbarPure = ({
  classes,
  onTabChange,
  value,
  selectedEditorView,
  onUndo,
  onRedo,
  onBadgeClick,
  canUndo,
  canRedo,
  validationStatus,
  validationResults,
  surveyLanguages,
  onSelectCurrentLanguage,
  selectedCurrentLanguage,
  openSurveySettings,
  showSurveySettingsModal,
  closeSurveySettingsModal,
  fullscreen,
  onFullscreenChange,

  ...others
}) => {
  const validationsLength = reduce((acc, { errors }) => acc + length(errors), 0, validationResults.results);
  const { Icon, badgeConfig, iconConfig } = NOTIFICATION_TYPES[validationResults.status];
  const FullScreenButtonIcon = fullscreen ? FullscreenExit : Fullscreen;
  return (
    <div {...others} className={classNames(classes.root, others.className)}>
      <AppBar position="static">
        <Toolbar variant="dense">
          <Tabs value={selectedEditorView} onChange={onTabChange} className={classes.grow}>
            <Tab value={EDITOR_VIEW.SURVEY_EDITOR} title="Editor" label="Editor" data-test-id="editor-tab" />
            <Tab
              value={EDITOR_VIEW.TRANSLATION_EDITOR}
              title="Translation"
              label="Translation"
              data-test-id="translation-tab"
            />
          </Tabs>
          <div className={classes.grow}>
            <IconButton color="inherit" aria-label="Undo" title="Undo" onClick={onUndo} disabled={!canUndo}>
              <Undo />
            </IconButton>
            <IconButton color="inherit" aria-label="Redo" title="Redo" onClick={onRedo} disabled={!canRedo}>
              <Redo />
            </IconButton>
          </div>
          <ImportExportMenu />
          {onFullscreenChange && (
            <IconButton
              color="inherit"
              aria-label="Full screen toggle"
              title="Full screen toggle"
              onClick={onFullscreenChange}
              className={classes.toolbarIcons}>
              <FullScreenButtonIcon />
            </IconButton>
          )}
          <IconButton
            color="inherit"
            aria-label="Survey settings"
            title="Survey settings"
            onClick={showSurveySettingsModal}
            className={classes.toolbarIcons}>
            <Settings />
          </IconButton>
          <LanguageMenu
            className={classes.toolbarLanguageSelect}
            value={selectedCurrentLanguage}
            onSelect={onSelectCurrentLanguage}
            languages={surveyLanguages}
            indelibleLanguages={['en_US']}
          />
          <Badge badgeContent={validationsLength} {...badgeConfig} onClick={onBadgeClick}>
            <Icon {...iconConfig} />
          </Badge>
          <SurveySettingsDialog open={openSurveySettings} onClose={closeSurveySettingsModal} />
        </Toolbar>
      </AppBar>
    </div>
  );
};

const mapStateToProps = (state) => ({
  surveyLanguages: getSurveyLanguages(state),
  selectedEditorView: getSelectedEditorView(state),
  selectedCurrentLanguage: getSelectedCurrentLanguage(state),
  canUndo: hasUndo(state),
  canRedo: hasRedo(state),
  validationResults: getValidationResults(state),
});

const mapDispatchToProps = (dispatch) => ({
  onTabChange: (event, value) => dispatch(selectEditorView(value)),
  onSelectCurrentLanguage: (language) => {
    dispatch(selectCurrentLanguage(language));
  },
  onUndo: () => dispatch(ActionCreators.undo()),
  onRedo: () => dispatch(ActionCreators.redo()),
});

const StyledEditorToolbar = pipe(
  withStateHandlers(
    {
      openSurveySettings: false,
    },
    {
      showSurveySettingsModal: () => () => ({ openSurveySettings: true }),
      closeSurveySettingsModal: () => () => ({ openSurveySettings: false }),
    }
  ),
  withStylesAsClasses(styles),
  memoize(ToolbarPure)
);

export const EditorToolbar = connect(mapStateToProps, mapDispatchToProps)(StyledEditorToolbar);

EditorToolbar.propTypes = {
  validationStatus: PropTypes.oneOf(values(VALIDATION_STATUS)).isRequired,
  onFullscreenChange: PropTypes.func,
  fullscreen: PropTypes.bool,
};

EditorToolbar.defaultProps = {
  validationStatus: VALIDATION_STATUS.VALID,
};
