import { Divider, Drawer, IconButton, Typography } from '@material-ui/core';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import classNames from 'classnames';
import { pipe, withStateHandlers } from 'commity-rehook-fork';
import React from 'react';
import { memoize, withStylesAsClasses } from '../../rehooks';
import { QuestionDatabank } from './QuestionDatabank';

const drawerWidth = 300;

const styles = theme => ({
  root: {
    cursor: 'default',
  },

  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    minHeight: 30,
  },

  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },

  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },

  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),

    overflowX: 'hidden',
    width: theme.spacing.unit + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing.unit * 3 + 2,
    },
  },

  header: {
    flex: 1,
    paddingLeft: theme.spacing.unit,
  },

  drawerIcon: {
    padding: theme.spacing.unit / 4,
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.text.primary,
    },
  },
});

const QuestionsDatabankDrawerPure = ({ classes, openDatabasePanel, handleDrawerClick, ...others }) => {
  const drawerIconTitle = openDatabasePanel ? 'Collapse menu' : 'Show Menu';

  return (
    <div {...others} className={classNames(classes.root, others.className)}>
      <Drawer
        PaperProps={{ style: { position: 'static', paddingBottom: 1 } }}
        variant="permanent"
        className={classNames(classes.drawer, {
          [classes.drawerOpen]: openDatabasePanel,
          [classes.drawerClose]: !openDatabasePanel,
        })}
        classes={{
          paper: classNames({
            [classes.drawerOpen]: openDatabasePanel,
            [classes.drawerClose]: !openDatabasePanel,
          }),
        }}
        open={openDatabasePanel}>
        <div className={classes.toolbar}>
          {openDatabasePanel && (
            <Typography variant="subtitle2" className={classes.header}>
              Master Questions
            </Typography>
          )}
          <IconButton onClick={handleDrawerClick} title={drawerIconTitle} className={classes.drawerIcon}>
            {openDatabasePanel ? <ChevronLeft /> : <ChevronRight />}
          </IconButton>
        </div>
        <Divider />
        <QuestionDatabank openDatabasePanel={openDatabasePanel} />
      </Drawer>
    </div>
  );
};

export const QuestionsDatabankDrawer = pipe(
  withStateHandlers(
    { openDatabasePanel: true },
    {
      handleDrawerClick: ({ openDatabasePanel }) => () => ({ openDatabasePanel: !openDatabasePanel }),
    }
  ),
  withStylesAsClasses(styles),
  memoize(QuestionsDatabankDrawerPure)
);

QuestionsDatabankDrawer.propTypes = {};
