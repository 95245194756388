/* eslint no-template-curly-in-string: "off" */
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import classNames from 'classnames';
import * as PropTypes from 'prop-types';
import { pipe } from 'ramda';
import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { withStylesAsClasses } from '../rehooks';

const styles = theme => ({
  root: {},

  errorHeaderText: {
    cursor: 'default',
    paddingBottom: theme.spacing.unit,
    '& h6': {
      color: theme.palette.background.warning,
    },
  },

  msgHeader: {
    padding: `${theme.spacing.unit * 2}px 0 ${theme.spacing.unit / 2}px 0`,
  },

  error: {
    minHeight: theme.spacing.unit * 12,
    backgroundColor: theme.palette.background.highlight,
  },
});

const CrashErrorDialogPure = ({
  classes,
  open,
  errorMessage,
  errorMsgHeader,
  onOkClick,

  ...others
}) => {
  const errorMessageForReport = `${errorMsgHeader} \n\n ${errorMessage}`;
  return (
    <div {...others} className={classNames(classes.root, others.className)}>
      <Dialog aria-labelledby="crash-error-dialog-title" open={open} disableBackdropClick disableEscapeKeyDown>
        <DialogTitle className={classes.errorHeaderText} id="crash-error-dialog-title">
          Something went wrong.
        </DialogTitle>
        <DialogContent>
          <Typography>Take the following text and send it to your system vendor:</Typography>
          <Typography variant="subtitle2" color="error" className={classes.msgHeader}>
            {errorMsgHeader}
          </Typography>
          <Typography variant="subtitle2" className={classes.error}>
            {errorMessage}
          </Typography>
        </DialogContent>
        <DialogActions>
          <CopyToClipboard text={errorMessageForReport}>
            <Button variant="outlined" title="Copy error to clipboard and close" onClick={onOkClick} color="primary">
              Copy error to Clipboard
            </Button>
          </CopyToClipboard>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export const CrashErrorDialog = pipe(
  withStylesAsClasses(styles),
  CrashErrorDialogPure
);

CrashErrorDialogPure.propTypes = {
  open: PropTypes.bool.isRequired,
  onOkClick: PropTypes.func.isRequired,
  errorMsgHeader: PropTypes.string,
  errorMessage: PropTypes.string,
};

CrashErrorDialogPure.defaultProps = {
  open: false,
};
