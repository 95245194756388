import { pipe } from 'commity-rehook-fork';
import * as PropTypes from 'prop-types';
import { flatten, map, mergeRight, omit, prop, reduce } from 'ramda';
import { allEqualTo } from 'ramda-adjunct';
import { connect } from 'react-redux';
import { getSelectedItem, updateItem } from '../../../state/modules/survey/overview/index';
import { transformForCollector } from './conditionsFormat';
import { validateConditionsRow } from './ConditionsRow';
import { ConditionsModal } from './ConditionsModal';

const validateReducer = (item) => {
  if (item.rows) {
    return map(validateReducer, item.rows);
  }
  return validateConditionsRow(item);
};

const validate = pipe(
  prop('rows'),
  reduce((acc, item) => [...acc, validateReducer(item)], []),
  flatten,
  allEqualTo(true)
);

const mapDispatchToProps = (dispatch) => {
  return {
    onSave: (itemPath, item, conditions, onDialogClose, setValid) => () => {
      if (validate(conditions)) {
        const updatedConditions = pipe(
          transformForCollector,
          mergeRight(omit(['hideIf', 'visibleIf'], item))
        )(conditions);

        dispatch(updateItem(itemPath, updatedConditions));
        onDialogClose();
      } else {
        setValid(false);
      }
    },
  };
};
const mapStateToProps = (state) => ({
  item: getSelectedItem(state),
});

export const ConditionsModalForQuestion = connect(mapStateToProps, mapDispatchToProps)(ConditionsModal);

ConditionsModalForQuestion.propTypes = {
  conditionsRows: PropTypes.array,
  onDialogClose: PropTypes.func,
};
ConditionsModalForQuestion.defaultProps = {};
