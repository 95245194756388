import { flatQuestionTreeWithPaths } from 'xperience-model-management';
import { always, assoc, assocPath, cond, filter, head, map, path, pipe, T } from 'ramda';

const stringNumberToNumber = map(item => {
  // head(item.value) !== '0' je tu kvuli stringovým podmínkám např. 0500
  if (item.type === 'value' && !isNaN(item.value) && head(item.value) !== '0') {
    return assoc('value', Number(item.value), item);
  }
  if (item.arguments) {
    return assoc('arguments', stringNumberToNumber(item.arguments), item);
  }
  return item;
});
const getHideArguments = path(['hideIf', 'arguments']);
const getVisibleArguments = path(['visibleIf', 'arguments']);
const transformHideArguments = item => {
  return assocPath(['definition', ...item.path, 'hideIf', 'arguments'], stringNumberToNumber(getHideArguments(item)));
};
const transformVisibleArguments = item => {
  return assocPath(
    ['definition', ...item.path, 'visibleIf', 'arguments'],
    stringNumberToNumber(getVisibleArguments(item))
  );
};

export const fixConditionValues = props => {
  const setters = pipe(
    flatQuestionTreeWithPaths,
    filter(item => item.hideIf || item.visibleIf),
    map(
      cond([[getHideArguments, transformHideArguments], [getVisibleArguments, transformVisibleArguments], [T, always]])
    )
  )(props.definition);

  return setters.length ? pipe(...setters)(props) : props;
};
