import {
  append,
  assoc,
  chain,
  dissoc,
  fromPairs,
  map,
  mergeRight,
  omit,
  path,
  pick,
  reduce,
  toPairs,
  type,
  without,
} from 'ramda';
import { isPage, localizationList, translationPropsByElementTypes } from 'xperience-model-management';

export const translationPropertyLabels = {
  text: 'Text',
  instruction: 'Instruction',
  agreement: 'Agreement',
  optOut: 'Opt out',
  choices: 'Choices',
  linkText: 'Link Text',
  introduction: 'Introduction',
  description: 'Description',
  startButtonText: 'Start Button Text',
  thanksMessage: 'Thanks Message',
  continueButtonText: 'Continue Button Text',
  continueText: 'Continue Text',
  terminateButtonText: 'Terminate Button Text',
  terminateText: 'Terminate Text',
  saveDialogText: 'Save Dialog Text',
  saveButtonText: 'Save Button Text',
  placeholder: 'Textfield Placeholder',
  labelMin: 'Textfield Label min',
  labelMax: 'Textfield Label max',
  redirect: 'Redirect',
  conditionalTexts: 'Conditional texts',
  title: 'Title',
};

export const masterEnUSLocalization = {
  code: 'en_US-master',
  localName: 'English - Master',
  directionality: 'ltr',
};

export const completeLocalizationList = mergeRight(localizationList, {
  [masterEnUSLocalization.code]: masterEnUSLocalization,
});

export const flatToKeysWithDotsPath = (obj) => {
  const go = (obj_) =>
    chain(([k, v]) => {
      if (type(v) === 'Object' || type(v) === 'Array') {
        return map(([k_, v_]) => [`${k}.${k_}`, v_], go(v));
      }
      return [[k, v]];
    }, toPairs(obj_));

  return fromPairs(go(obj));
};

const getTranslationChoices = reduce((acc, choice) => assoc(choice.value, dissoc('value', choice), acc), {});

const getInlineTranslation = reduce((acc, item) => {
  const { elements } = item;
  if (elements) {
    return mergeRight(acc, getInlineTranslation(elements));
  }
  let translationItem = pick(translationPropsByElementTypes[item.type] || [], item);
  const { choices } = translationItem;
  if (choices) {
    translationItem = assoc('choices', getTranslationChoices(choices), translationItem);
  }
  return assoc(item.id, translationItem, acc);
}, {});

const getDefinitionChoices = reduce((acc, choice) => append(choice.value, acc), []);

const getOutlinedDefinition = map((item) => {
  const { elements } = item;
  if (elements) {
    return assoc('elements', getOutlinedDefinition(elements, item), item);
  }
  let itemDefinition = omit(without('choices', translationPropsByElementTypes[item.type] || []), item);
  const { choices } = itemDefinition;
  if (choices) {
    itemDefinition = assoc('choices', getDefinitionChoices(choices), itemDefinition);
  }
  return itemDefinition;
});

const TRANSLATION_NOT_FOUND = 'TRANSLATION NOT FOUND';

export const findTranslationItemInCurrentTranslation = (survey, item) => {
  if (!item || !item.id) {
    return { text: TRANSLATION_NOT_FOUND };
  }
  return path(['currentTranslation', item.id], survey);
};

export const getTranslationItemFromMasterTranslations = (masterTranslations, item) => {
  if (!item || !item.id || !masterTranslations || isPage(item) || !masterTranslations[item.id]) {
    return { text: TRANSLATION_NOT_FOUND };
  }
  return masterTranslations[item.id];
};
