import createReducer from 'create-reducer';
import { path, pathOr, without } from 'ramda';
import { combineEpics, ofType } from 'redux-observable';

import { ActionTypes } from 'redux-undo';
import { map, mapTo } from 'rxjs/operators';
import { INITIALIZE } from '../survey/overview';
import { DELETE_LANGUAGE, getSurveyLanguages } from '../survey/translations';
import {
  handlers,
  SELECT_CURRENT_LANGUAGE,
  SELECT_EDITOR_VIEW,
  SELECT_ITEM,
  SELECT_SOURCE_LANGUAGE,
  SELECT_TARGET_LANGUAGE,
} from './handlers';

export const EDITOR_VIEW = { SURVEY_EDITOR: 'surveyEditor', TRANSLATION_EDITOR: 'translationEditor' };

// --- ACTIONS ---

export const selectItem = itemPath => ({
  type: SELECT_ITEM,
  itemPath,
});

export const selectEditorView = editorView => ({
  type: SELECT_EDITOR_VIEW,
  editorView,
});

export const selectSourceLanguage = language => ({
  type: SELECT_SOURCE_LANGUAGE,
  language,
});
export const selectCurrentLanguage = language => ({
  type: SELECT_CURRENT_LANGUAGE,
  language,
});

export const selectTargetLanguage = language => ({
  type: SELECT_TARGET_LANGUAGE,
  language,
});

export const MASTER_LANGUAGE = 'en_US';

export const EDITOR_PATHS = {
  root: ['undoable', 'present', 'editor'],
  selectedItemPath: ['selectedItemPath'],
  selectedEditorView: ['selectedEditorView'],
  selectedTargetLanguage: ['selectedTargetLanguage'],
  selectedSourceLanguage: ['selectedSourceLanguage'],
  masterSurvey: ['masterSurvey'],
  flattenMasterQuestions: ['flattenMasterQuestions'],
  masterSurveyTranslation: ['masterSurvey', 'translations', MASTER_LANGUAGE],
  placeholders: ['placeholders'],
  isMaster: ['isMaster'],
  temp: ['temp'],
  currentLanguage: ['currentLanguage'],
};

export default createReducer(handlers, () => ({}));

const getDefaultLanguage = ({ language: deletedLanguage }, state) => {
  const surveyLanguages = getSurveyLanguages(state);
  const restLanguages = without(deletedLanguage, surveyLanguages);
  return restLanguages[0];
};

const deleteTargetLanguageEpic = (action$, state$) =>
  action$.pipe(
    ofType(DELETE_LANGUAGE),
    map(action => selectTargetLanguage(getDefaultLanguage(action, state$.value)))
  );

const deleteSourceLanguageEpic = (action$, state$) =>
  action$.pipe(
    ofType(DELETE_LANGUAGE),
    map(action => selectSourceLanguage(getDefaultLanguage(action, state$.value)))
  );

const initialEpic = action$ =>
  action$.pipe(
    ofType(INITIALIZE),
    mapTo({ type: ActionTypes.CLEAR_HISTORY })
  );

export const epics = combineEpics(deleteTargetLanguageEpic, deleteSourceLanguageEpic, initialEpic);

const getPathWithRoot = (innerPath = []) => [...EDITOR_PATHS.root, ...innerPath];

export const getMasterTranslation = state => {
  // todo - když tady vyhodím ten state, tak to při načítání aplikace padne s tím, že EDITOR_PATHS je undefined(!?) @milan
  return path(getPathWithRoot(EDITOR_PATHS.masterSurveyTranslation), state);
};

export const getSelectedItemPath = pathOr([], getPathWithRoot(EDITOR_PATHS.selectedItemPath));
export const getSelectedEditorView = pathOr(
  EDITOR_VIEW.SURVEY_EDITOR,
  getPathWithRoot(EDITOR_PATHS.selectedEditorView)
);

export const getSelectedSourceLanguage = path(getPathWithRoot(EDITOR_PATHS.selectedSourceLanguage));
export const getSelectedTargetLanguage = path(getPathWithRoot(EDITOR_PATHS.selectedTargetLanguage));
export const getSelectedCurrentLanguage = path(getPathWithRoot(EDITOR_PATHS.currentLanguage));
export const isMaster = path(getPathWithRoot(EDITOR_PATHS.isMaster));
export const getMasterSurvey = path(getPathWithRoot(EDITOR_PATHS.masterSurvey));
export const getPlaceholders = pathOr([], getPathWithRoot(EDITOR_PATHS.placeholders));
export const getFlattenMasterQuestions = path(getPathWithRoot(EDITOR_PATHS.flattenMasterQuestions));
