import { Button, Paper, Typography } from '@material-ui/core';
import classNames from 'classnames';
import { pipe, withStateHandlers } from 'commity-rehook-fork';
import * as PropTypes from 'prop-types';
import { anyPass, has } from 'ramda';
import React from 'react';
import { withStylesAsClasses } from '../../rehooks';
import { ConditionsAsString } from './conditions/ConditionsAsString';
import { transformForEditor } from './conditions/conditionsFormat';
import { ConditionsModalForQuestion } from './conditions/ConditionsModalForQuestion';

const styles = (theme) => ({
  root: {},

  conditionContainer: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit / 4}px`,
  },

  noConditionsLabel: {
    display: 'flex',
    alignSelf: 'center',
    justifyContent: 'center',
  },

  editButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: theme.spacing.unit / 4,
  },
  heading: {
    padding: `0 ${theme.spacing.unit}px`,
  },
});

const ConditionsPure = ({
  classes,
  openConditionModal,
  item,
  isConditionsModalOpen,
  closeConditionModal,
  readOnly,
  ...others
}) => {
  const hasCondition = anyPass([has('visibleIf'), has('hideIf')])(item);
  const conditionButtonLabel = hasCondition ? 'Edit Condition' : 'Add Condition';

  return (
    <div {...others} className={classNames(classes.root, others.className)}>
      <Paper square className={classes.conditionContainer}>
        <Typography variant="h5" component="h3" className={classes.heading}>
          Conditions
        </Typography>
        {hasCondition ? (
          <ConditionsAsString conditions={transformForEditor(item)} />
        ) : (
          <Typography className={classes.noConditionsLabel}>No conditions</Typography>
        )}
        <div className={classes.editButton}>
          <Button color="primary" variant="outlined" onClick={openConditionModal} disabled={readOnly}>
            {conditionButtonLabel}
          </Button>
        </div>
      </Paper>
      {isConditionsModalOpen && <ConditionsModalForQuestion onDialogClose={closeConditionModal} />}
    </div>
  );
};

export const Conditions = pipe(
  withStateHandlers(
    { isConditionsModalOpen: false }, // state initialization
    {
      openConditionModal: () => () => ({ isConditionsModalOpen: true }),
      closeConditionModal: () => () => ({ isConditionsModalOpen: false }),
    }
  ),
  withStylesAsClasses(styles),
  ConditionsPure
);

Conditions.propTypes = {
  selectedItemPath: PropTypes.array,
  readOnly: PropTypes.bool,
};
