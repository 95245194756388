import { Paper, Typography } from '@material-ui/core';
import classNames from 'classnames';
import { pipe } from 'commity-rehook-fork';
import * as PropTypes from 'prop-types';
import { anyPass, find, omit, path, prop, propEq } from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import {
  flatQuestionTree,
  isEscape,
  isPersonalAgreement,
  isStandardQuestion,
  isPage,
} from 'xperience-model-management';
import { memoize, withStylesAsClasses } from '../../rehooks';
import { EDITOR_PATHS } from '../../state/modules/editor';
import { VALIDATION_STATUS } from '../../state/validations/validationHelper';
import { isQuestionInSurvey, isQuestionMandatory } from '../questionHelper';
import { getValidationResults } from './../../state/modules/validations';
import { Conditions } from './Conditions';
import { ItemProperties } from './ItemProperties';
import { MasterSettings } from './MasterSettings';
import { Validations } from './validations/Validations';

const styles = (theme) => ({
  root: {
    cursor: 'default',
    height: '100%',
    overflow: 'auto',
  },

  panel: {
    marginTop: theme.spacing.unit / 2,
    paddingBottom: theme.spacing.unit / 8,
  },

  itemContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    marginLeft: theme.spacing.unit / 2,
    backgroundColor: theme.palette.background.editorPanels,
  },

  label: {
    marginTop: theme.spacing.unit,
    alignSelf: 'center',
  },
});

const ObjectInspectorPure = ({
  item,
  classes,
  onDependencySelect,
  isMaster,
  isEditorView,
  masterSurvey,
  survey,
  validationResults,
  masterItemDefinition,
  flatMaster,
  ...others
}) => {
  const readOnly = !isMaster && isQuestionInSurvey(item, flatQuestionTree(masterSurvey.definition));
  const readOnlyMandatory = !isMaster && isQuestionMandatory(masterItemDefinition, flatMaster);
  const showValidationPanel = validationResults.status !== VALIDATION_STATUS.VALID;
  const showConditions = anyPass([isStandardQuestion, isPersonalAgreement, isEscape]);

  return (
    <div {...others} className={classNames(classes.root, others.className)}>
      <Paper square className={classes.itemContainer}>
        {item ? (
          <>
            <ItemProperties readOnly={readOnly} item={item} />
            {showConditions(item) && !isPage(item) && (
              <Conditions item={item} readOnly={readOnlyMandatory} className={classes.panel} />
            )}
            {isMaster && <MasterSettings onSelect={onDependencySelect} item={item} className={classes.panel} />}
          </>
        ) : (
          <Typography className={classes.label}>Select survey item...</Typography>
        )}
        {showValidationPanel && <Validations item={item} className={classes.panel} isEditorView={isEditorView} />}
      </Paper>
    </div>
  );
};

const mapStateToProps = (state, { item }) => {
  const getFlattenMaster = path([...EDITOR_PATHS.root, 'flattenMasterQuestions']);
  const itemId = prop('id', item);
  const getMasterItemDefinition = pipe(getFlattenMaster, find(propEq('id', itemId)));

  return {
    validationResults: getValidationResults(state),
    masterItemDefinition: getMasterItemDefinition(state),
    flatMaster: getFlattenMaster(state),
  };
};

const StyledObjectInspector = pipe(withStylesAsClasses(styles), omit(['dispatch']), memoize(ObjectInspectorPure));

export const ObjectInspector = connect(mapStateToProps)(StyledObjectInspector);

ObjectInspector.propTypes = {
  item: PropTypes.shape({
    type: PropTypes.string,
  }),
  isMaster: PropTypes.bool,
  isEditorView: PropTypes.bool,
  masterSurvey: PropTypes.shape({
    definition: PropTypes.array.isRequired,
    translations: PropTypes.object.isRequired,
  }),
  validationResults: PropTypes.array,
};
