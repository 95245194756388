/* eslint no-param-reassign: "off" */

import { addIndex, assoc, concat, isEmpty, reduce } from 'ramda';
import { flatQuestionTreeWithPaths } from 'xperience-model-management';
import { isPage } from '../../components/questionHelper';
import { VALIDATION_STATUS } from './validationHelper';
import {
  cannotApplyBothVisibilityConditionsAtOnceValidator,
  emptyChoicesValidator,
  emptyConditionForConditionalTextValidator,
  redirectValidator,
} from './itemValidators';
import {
  cannotHaveDuplicateIdsValidator,
  questionIdsInConditionsMustExistAboveQuestionValidator,
  questionQZIsAfterPersonalAgreementValidator,
  questionQZIsAfterQEQuestionValidator,
  conditionDoesNotMatchQuestionDataTypeValidator,
} from './structuralValidators';
import {
  missingOptionalFieldsValidator,
  missingRequiredChoicesFieldsValidator,
  missingRequiredConditionalTextFieldsValidator,
  missingRequiredFieldsValidator,
  missingRequiredIntroSpecificFieldsValidator,
  missingRequiredOutroSpecificFieldsValidator,
  notExistentPlaceholderUsedValidator,
} from './translationValidators';
import { missingMasterQuestionsInSurvey } from './masterSpecficValidators';

const validateStructure = (validators, validatorProps) => {
  const { survey } = validatorProps;
  const flatSurveyDefinition = flatQuestionTreeWithPaths(survey.definition);
  const validatorPropsWithFlatSurvey = assoc('flatSurveyDefinition', flatSurveyDefinition, validatorProps);
  return reduce(
    (acc, { applyIf, validationFn }) => {
      if (applyIf(validatorPropsWithFlatSurvey)) {
        const results = validationFn(validatorPropsWithFlatSurvey);
        if (results.length) {
          return concat(acc, results);
        }
      }
      return acc;
    },
    [],
    validators
  );
};

const validateStructuralValidators = (validators, validatorProps) => validateStructure(validators, validatorProps);

const validateItem = (item, itemPath, validators, validatorProps) =>
  reduce(
    (acc, { applyIf, validationFn }) => {
      if (applyIf(item, itemPath)) {
        const results = validationFn(validatorProps, item, itemPath);
        if (results.length) {
          return concat(acc, results);
        }
      }
      return acc;
    },
    [],
    validators
  );

const indexedReduce = addIndex(reduce);

const validateItemValidators = (validators, validatorProps, path, items) =>
  indexedReduce(
    (acc, item, index) => {
      const itemPath = [...path, index];
      const results = validateItem(item, itemPath, validators, validatorProps);
      if (results.length) {
        acc = concat(acc, results);
      }
      if (isPage(item)) {
        const pageElementsResults = validateItemValidators(
          validators,
          validatorProps,
          [...itemPath, 'elements'],
          item.elements
        );
        acc = concat(acc, pageElementsResults);
      }
      return acc;
    },
    [],
    items
  );

const validateTranslationValidators = (validators, validatorProps, path, items) => {
  const flattenQuestions = flatQuestionTreeWithPaths(items);
  return reduce(
    (acc, item) => {
      const results = validateItem(item, item.path, validators, validatorProps);
      if (results.length) {
        acc = concat(acc, results);
      }
      return acc;
    },
    [],
    flattenQuestions
  );
};

const validateMasterSpecificValidators = (validators, validatorProps) => validateStructure(validators, validatorProps);

const concatArrays = reduce(concat, []);

export const createValidators = ({ structural = [], itemSpecific = [], translations = [], masterSpecific = [] }) => (
  validatorProps
) => {
  const structuralResults = validateStructuralValidators(structural, validatorProps);
  const masterSpecificResults = validateMasterSpecificValidators(masterSpecific, validatorProps);
  const { survey } = validatorProps;
  const itemSpecificResults = validateItemValidators(itemSpecific, validatorProps, [], survey.definition);
  const translationsResults = validateTranslationValidators(translations, validatorProps, [], survey.definition);
  const results = concatArrays([structuralResults, masterSpecificResults, itemSpecificResults, translationsResults]);
  return {
    status: isEmpty(results) ? VALIDATION_STATUS.VALID : VALIDATION_STATUS.INVALID,
    results,
  };
};

const VALIDATORS = {
  structural: [
    cannotHaveDuplicateIdsValidator,
    questionIdsInConditionsMustExistAboveQuestionValidator,
    questionQZIsAfterQEQuestionValidator,
    conditionDoesNotMatchQuestionDataTypeValidator,
    questionQZIsAfterPersonalAgreementValidator,
  ],
  itemSpecific: [
    emptyChoicesValidator,
    emptyConditionForConditionalTextValidator,
    cannotApplyBothVisibilityConditionsAtOnceValidator,
    redirectValidator,
  ],
  translations: [
    missingRequiredFieldsValidator,
    missingRequiredIntroSpecificFieldsValidator,
    missingRequiredChoicesFieldsValidator,
    missingOptionalFieldsValidator,
    notExistentPlaceholderUsedValidator,
    missingRequiredConditionalTextFieldsValidator,
    missingRequiredOutroSpecificFieldsValidator,
  ],
  masterSpecific: [missingMasterQuestionsInSurvey],
};

export const validatorContainer = createValidators(VALIDATORS);
