import { pipe } from 'ramda';
// import { isVersion, isVariant, generateDatamapBySurveyDefinition } from 'xperience-model-management';
import { generateDatamapBySurveyDefinition } from 'xperience-model-management';
import { surveyPostProcessing } from '../postProcessing';

export const exportDefinition = pipe(
  surveyPostProcessing,
  survey => {
    // if (isVersion(2, survey) && isVariant('EDITOR_MULTI_LANGUAGE', survey)) {
    return {
      survey,
      datamap: generateDatamapBySurveyDefinition(survey),
    };
    // }
    // return survey;
  }
);
