import classNames from 'classnames';
import { pipe } from 'commity-rehook-fork';
import * as PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { withStylesAsClasses } from '../../../../rehooks';
import { Grid } from '@material-ui/core';
import { ConditionsModalForOutro } from '../../../objectInspector/conditions/ConditionsModalForOutro';
import { transformForEditor } from '../../../objectInspector/conditions/conditionsFormat';
import { ConditionRow } from './outro/ConditionRow';
import { path } from 'ramda';

const styles = () => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  conditionRows: {
    flexWrap: 'noWrap',
  },
});

const OutroQuestionFormPure = ({
  item,
  classes,
  updateConditionalText,
  conditionalTextTranslate,
  moveConditionalText,
  translationItem,
  removeConditionalText,
  ...others
}) => {
  const [isConditionsModalOpen, setConditionsModalOpen] = useState(false);
  const [conditionsModalItem, setConditionsModalItem] = useState({});

  const openConditionModal = useCallback(
    (data = {}, index) => () => {
      setConditionsModalItem({
        ...data,
        id: 'outro',
        type: 'outro',
        index,
      });
      setConditionsModalOpen(true);
    },
    [setConditionsModalOpen, setConditionsModalItem]
  );

  return (
    <div {...others} className={classNames(classes.root, others.className)}>
      <Grid container direction="column" justify="center" className={classes.conditionRows}>
        {item.conditionalTexts &&
          item.conditionalTexts.map(({ condition }, key) => (
            <ConditionRow
              key={key}
              index={key}
              transformForEditor={transformForEditor}
              condition={condition}
              conditionalTextTranslate={conditionalTextTranslate}
              text={path(['conditionalTexts', key, 'text'], translationItem)}
              openConditionModal={openConditionModal}
              moveConditionalText={moveConditionalText}
              removeConditionalText={removeConditionalText}
            />
          ))}
      </Grid>
      {isConditionsModalOpen && (
        <ConditionsModalForOutro
          updateConditionalText={updateConditionalText}
          item={conditionsModalItem}
          onDialogClose={() => setConditionsModalOpen(false)}
        />
      )}
    </div>
  );
};

export const OutroQuestionForm = pipe(withStylesAsClasses(styles), OutroQuestionFormPure);

OutroQuestionForm.propTypes = {
  item: PropTypes.shape({}),
  translationItem: PropTypes.shape({}),
  updateConditionalText: PropTypes.func,
  moveConditionalText: PropTypes.func,
  removeConditionalText: PropTypes.func,
};
