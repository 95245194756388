import React from 'react';

import { Button } from '@material-ui/core';
import { GetApp } from '@material-ui/icons';
import fileSaver from 'file-saver';
import classNames from 'classnames';
import { pipe } from 'commity-rehook-fork';
import { connect } from 'react-redux';
import { omit } from 'ramda';

import { format } from 'date-fns';
import { withStylesAsClasses } from '../rehooks';
import { getSurvey } from '../state/modules/survey/overview';
import { getMasterSurvey } from '../state/modules/editor';
import { exportToCsv } from '../surveyModel/exportImportCsv';

const styles = () => ({
  root: {},
  menuItem: {
    textTransform: 'none',
    paddingRight: '12px',
    '&:hover': {
      borderRadius: 0,
    },
  },
});

function formatTimestampForCsvExport() {
  return format(new Date(), 'yyyymmdd_HHmmss');
}

const DownloadCsvPure = ({ classes, survey, masterSurvey, ...others }) => {
  function handleChange() {
    const blob = new Blob([String.fromCharCode(0xfeff), exportToCsv(masterSurvey, survey)], {
      type: 'text/csv;charset=utf-8',
    });
    fileSaver.saveAs(blob, `translations_export_${formatTimestampForCsvExport()}.csv`, { autoBom: false });
  }

  return (
    <div {...others} className={classNames(classes.root, others.className)}>
      <Button
        color="inherit"
        aria-label="Download csv"
        title="Download csv"
        fullWidth
        onClick={handleChange}
        className={classes.menuItem}>
        <GetApp style={{ marginRight: '8px' }} /> Export translations
      </Button>
    </div>
  );
};

const mapStateToProps = state => ({
  survey: getSurvey(state),
  masterSurvey: getMasterSurvey(state),
});

const EditorWithStyles = pipe(
  withStylesAsClasses(styles),
  omit(['dispatch']),
  DownloadCsvPure
);

export default connect(mapStateToProps)(EditorWithStyles);
