export const dummySurvey = {
  metadata: {
    version: 2,
  },
  definition: [
    {
      id: 'intro',
      type: 'intro',
      imagePath: {
        type: 'variable',
        value: 'carImage',
      },
      optional: true,
    },
    {
      type: 'questionGender',
      id: 'S5',
      choices: [{ value: 1 }, { value: 2 }, { value: 999, hidden: false }],
    },
    {
      id: 'Q1',
      type: 'questionStarRating',
      choices: [{ value: 999 }],
    },
    {
      id: 'Q2',
      type: 'questionSquareRating',
      choices: [{ value: 999 }],
    },
    {
      type: 'questionSingleChoice',
      id: 'Q5',
      choices: [{ value: 1 }, { value: 7 }],
    },
    {
      id: 'Q102',
      type: 'questionMultiChoice',
      choices: [{ value: 5 }, { value: 4 }, { value: 3 }, { value: 2 }, { value: 1 }],
    },
    {
      type: 'page',
      elements: [
        {
          id: 'Q1001',
          type: 'questionStarRating',
          choices: [{ value: 999 }],
        },
        {
          id: 'Q1001Comment',
          type: 'questionText',
          secondary: true,
          visibleIf: {
            operation: 'lessThanOrEqual',
            arguments: [
              {
                type: 'variable',
                value: 'Q1',
              },
              {
                type: 'value',
                value: 3,
              },
            ],
          },
        },
        {
          id: 'Q1001Comment2',
          type: 'questionText',
          secondary: true,
          visibleIf: {
            operation: 'greaterThanOrEqual',
            arguments: [
              {
                type: 'variable',
                value: 'Q1',
              },
              {
                type: 'value',
                value: 4,
              },
            ],
          },
        },
      ],
    },
    {
      type: 'page',
      elements: [
        {
          id: 'Q3',
          type: 'questionStarRating',
          choices: [{ value: 999 }],
        },
        {
          id: 'Q3Comment',
          type: 'questionText',
          secondary: true,
          visibleIf: {
            operation: 'lessThanOrEqual',
            arguments: [
              {
                type: 'variable',
                value: 'Q1',
              },
              {
                type: 'value',
                value: 3,
              },
            ],
          },
        },
        {
          id: 'Q3Comment2',
          type: 'questionText',
          secondary: true,
          visibleIf: {
            operation: 'greaterThanOrEqual',
            arguments: [
              {
                type: 'variable',
                value: 'Q1',
              },
              {
                type: 'value',
                value: 4,
              },
            ],
          },
        },
      ],
    },
    {
      id: 'QE',
      type: 'escape',
    },
    {
      id: 'QP',
      type: 'personalAgreement',
    },
    {
      id: 'outro',
      type: 'outro',
    },
  ],
  translations: {
    en_US: {
      intro: {
        text: 'Dear Customer, we would like to thank you for buying the new...',
        agreement: {
          text: 'I agree with terms',
          errorMessage: "You can't start without agreement.",
          instruction: ['Do something', { type: 'variable', value: 'serviceDate' }, ' awesome'],
        },
        introduction:
          'High quality of our services is very important to us. Therefore, we would like to ask you to complete a short survey concerning your satisfaction with your purchase experience by ',
        startButtonText: 'START!',
      },
      S5: {
        text: 'Gender Question',
        instruction: 'Instrukce',
        choices: {
          1: {
            text: 'Muž',
          },
          2: {
            text: 'Žena',
          },
          999: {
            text: 'Nechci odpovídat',
          },
        },
      },
      Q1: {
        text: 'Star rating question',
        choices: {
          999: {
            text: 'Nechci odpovídat',
          },
        },
      },
      Q2: {
        text: 'Question Text Form',
        instruction: 'Instrukce',
      },
      Q5: {
        text: 'Single Choice Question',
        instruction: 'Instrukce',
        choices: {
          1: {
            text: 'Možnost 1',
          },
          7: {
            text: 'Možnost 2',
          },
        },
      },
      Q102: {
        text: 'Multi Choice Question',
        instruction: 'Instrukce',
        choices: {
          1: {
            text: 'Definitely not',
          },
          2: {
            text: 'Probably not',
          },
          3: {
            text: 'Fairly likely',
          },
          4: {
            text: 'Probably yes',
          },
          5: {
            text: 'Definitely yes',
          },
        },
      },
      Q1001: {
        text: 'Star rating question',
        choices: {
          999: {
            text: 'Nechci odpovídat',
          },
        },
      },
      Q1001Comment: { text: 'Star rating 1. comment' },
      Q1001Comment2: { text: 'Star rating 2. comment' },
      Q3: {
        text: 'Square rating',
        choices: {
          999: {
            text: 'Nechci odpovídat',
          },
        },
      },
      Q3Comment: { text: 'Square rating 1. comment' },
      Q3Comment2: { text: 'Square rating 2. comment' },
      QE: {
        thanksMessage: 'Thank you! Your answers help us improve our services.',
        continueButtonText: 'Next question »',
        continueText: 'We would like to ask you several more questions.',
        terminateButtonText: 'Ukončit',
        terminateText: 'We would like to ask you several more questions.',
        saveDialogText:
          'Thank you. Your answers have been saved. You can return to filling the questionnaire any time, until the questionnaire expires.',
        saveButtonText: 'Save and continue later',
      },
      QP: {
        thanksMessage: 'We appreciate that you shared your experience with us!\nWilling to share some more?',
        continueText:
          'We would like to ask you few more specific questions for marketing purposes. Based on your answers you may be contacted with additional offers',
        text: 'We appreciate that you shared your experience with us!\nWilling to share some more?',
        continueButtonText: 'Next question »',
        terminateButtonText: 'End',
        agreement: {
          text: 'I agree to the processing of my personal data for the purpose of the marketing offer',
          errorMessage: 'Consent is required for continuation',
        },
      },
      outro: {
        text: 'Blessing of Akatosh upon ye!',
      },
      common: {
        elements: {
          squareRating: {
            labelMin: 'Most Unlikely',
            labelMax: 'Most Likely',
          },
        },
        ui: {
          surveyNavigation: {
            goForward: 'Go forward',
            goBack: 'Go back',
          },
          languageSelect: {
            language: 'Language',
          },
        },
        validation: {
          required: {
            general: 'Please answer this question',
            singleChoice: 'Please select answer',
            multiChoice: 'Please select answers',
            open: 'Please fill out the answer',
          },
        },
      },
    },
    uk_UA: {
      intro: {
        text: 'Шановний клієнте, ми б хотіли подякувати Вам за придбання нової ŠKODA в автосалоні.',
        introduction: 'Заповнення анкети не займе більше ніж 4 хвилини.',
        startButtonText: 'START!',
      },
      S5: {
        text: 'Ви…?',
        choices: {
          1: {
            text: 'Mužik',
          },
          2: {
            text: 'Ženja',
          },
          999: {
            text: 'Я не буду відповідати',
          },
        },
      },
      Q1: {
        text: 'Для статистичних цілей, чи можемо ми запитати, скільки Вам років?',
        choices: {
          999: {
            text: 'Я не буду відповідати',
          },
        },
      },
      QE: {
        thanksMessage: 'Ми б хотіли поставити Вам ще декілька запитань.',
        continueButtonText: 'Наступни запитання',
        continueText: 'Ми б хотіли поставити Вам ще декілька запитань.',
        terminateButtonText: 'Кінець',
        terminateText: 'Я не хочу продовжувати',
      },
      QP: {
        thanksMessage: 'We appreciate that you shared your experience with us!\nWilling to share some more?',
        continueText:
          'We would like to ask you few more specific questions for marketing purposes. Based on your answers you may be contacted with additional offers',
        continueButtonText: 'Next question »',
        terminateButtonText: 'End',
        agreement: {
          text: 'I agree to the processing of my personal data for the purpose of the marketing offer',
          errorMessage: 'Consent is required for continuation',
        },
      },
      outro: {
        text: 'Дякуємо Вам!',
      },
      common: {
        elements: {
          squareRating: {
            labelMin: 'Найнеймовірніше',
            labelMax: 'Найімовірніше',
          },
        },
        ui: {
          surveyNavigation: {
            goForward: 'OK',
            goBack: 'Назад',
          },
          languageSelect: {
            language: 'Мова',
          },
        },
        validation: {
          required: {
            general: 'Необхідно дати відповідь на це запитання.',
            singleChoice: 'Необхідно дати відповідь на це запитання.',
            multiChoice: 'Необхідно дати відповідь на це запитання.',
            open: 'До цього питання потрібен коментар.',
          },
        },
      },
    },
  },
};

export const dummySurveyMaster = {
  metadata: {
    version: 2,
  },
  definition: [
    {
      id: 'intro',
      type: 'intro',
      imagePath: {
        type: 'variable',
        value: 'carImage',
      },
      optional: true,
    },
    {
      id: 'Q1',
      type: 'questionStarRating',
      choices: [
        {
          value: 999,
          hidden: false,
        },
      ],
    },
    {
      id: 'Q2',
      type: 'questionSquareRating',
      choices: [
        {
          value: 999,
          hidden: false,
        },
      ],
    },
    {
      type: 'questionSingleChoice',
      id: 'Q5',
      choices: [
        {
          value: 1,
        },
        {
          value: 7,
        },
      ],
    },
    {
      id: 'Q102',
      type: 'questionMultiChoice',
      choices: [
        {
          value: 5,
        },
        {
          value: 4,
        },
        {
          value: 3,
        },
        {
          value: 2,
        },
        {
          value: 1,
        },
      ],
    },
    {
      type: 'page',
      elements: [
        {
          id: 'Q1001',
          type: 'questionStarRating',
          choices: [
            {
              value: 999,
              hidden: false,
            },
          ],
        },
        {
          id: 'Q1001Comment',
          type: 'questionText',
          secondary: true,
          visibleIf: {
            operation: 'lessThanOrEqual',
            arguments: [
              {
                type: 'variable',
                value: 'Q1',
              },
              {
                type: 'value',
                value: 3,
              },
            ],
          },
        },
        {
          id: 'Q1001Comment2',
          type: 'questionText',
          secondary: true,
          visibleIf: {
            operation: 'greaterThanOrEqual',
            arguments: [
              {
                type: 'variable',
                value: 'Q1',
              },
              {
                type: 'value',
                value: 4,
              },
            ],
          },
        },
      ],
    },
    {
      type: 'page',
      elements: [
        {
          id: 'Q3',
          type: 'questionStarRating',
          choices: [
            {
              value: 999,
              hidden: false,
            },
          ],
        },
        {
          id: 'Q3Comment',
          type: 'questionText',
          secondary: true,
          visibleIf: {
            operation: 'lessThanOrEqual',
            arguments: [
              {
                type: 'variable',
                value: 'Q1',
              },
              {
                type: 'value',
                value: 3,
              },
            ],
          },
        },
        {
          id: 'Q3Comment2',
          type: 'questionText',
          secondary: true,
          visibleIf: {
            operation: 'greaterThanOrEqual',
            arguments: [
              {
                type: 'variable',
                value: 'Q1',
              },
              {
                type: 'value',
                value: 4,
              },
            ],
          },
        },
      ],
    },
    {
      id: 'QE',
      type: 'escape',
    },
    {
      id: 'QP',
      type: 'personalAgreement',
    },
    {
      id: 'outro',
      type: 'outro',
    },
  ],
  translations: {
    en_US: {
      intro: {
        text: 'Dear Customer, we would like to thank you for buying the new...',
        agreement: {
          text: 'I agree with terms',
          errorMessage: "You can't start without agreement.",
        },
        introduction:
          'High quality of our services is very important to us. Therefore, we would like to ask you to complete a short survey concerning your satisfaction with your purchase experience by ',
        startButtonText: 'START!',
      },
      Q1: {
        text: 'Star rating question',
        choices: {
          999: {
            text: 'Nechci odpovídat',
          },
        },
      },
      Q2: {
        text: 'Question Text Form',
        instruction: 'Instrukce',
      },
      Q5: {
        text: 'Single Choice Question',
        instruction: 'Instrukce',
        choices: {
          1: {
            text: 'Možnost 1',
          },
          7: {
            text: 'Možnost 2',
          },
        },
      },
      Q102: {
        text: 'Multi Choice Question',
        instruction: 'Instrukce',
        choices: {
          1: {
            text: 'Definitely not',
          },
          2: {
            text: 'Probably not',
          },
          3: {
            text: 'Fairly likely',
          },
          4: {
            text: 'Probably yes',
          },
          5: {
            text: 'Definitely yes',
          },
        },
      },
      Q1001: {
        text: 'Star rating question',
        choices: {
          999: {
            text: 'Nechci odpovídat',
          },
        },
      },
      Q1001Comment: {
        text: 'Star rating 1. comment',
      },
      Q1001Comment2: {
        text: 'Star rating 2. comment',
      },
      Q3: {
        text: 'Square rating',
        choices: {
          999: 'Nechci odpovídat',
        },
      },
      Q3Comment: {
        text: 'Square rating 1. comment',
      },
      Q3Comment2: {
        text: 'Square rating 2. comment',
      },
      QE: {
        thanksMessage: 'Thank you! Your answers help us improve our services.',
        continueButtonText: 'Next question »',
        continueText: 'We would like to ask you several more questions.',
        terminateButtonText: 'Ukončit',
        terminateText: 'We would like to ask you several more questions.',
      },
      QP: {
        thanksMessage: 'Master - We appreciate that you shared your experience with us!\nWilling to share some more?',
        continueText:
          'Master - We would like to ask you few more specific questions for marketing purposes. Based on your answers you may be contacted with additional offers',
        continueButtonText: 'Master - Next question »',
        terminateButtonText: 'Master - End',
        agreement: {
          text: 'Master - I agree to the processing of my personal data for the purpose of the marketing offer',
          errorMessage: 'Master - Consent is required for continuation',
        },
      },
      outro: {
        text: 'Blessing of Akatosh upon ye!',
      },
      common: {
        elements: {
          squareRating: {
            labelMin: 'Most Unlikely',
            labelMax: 'Most Likely',
          },
        },
        ui: {
          surveyNavigation: {
            goForward: 'Go forward',
            goBack: 'Go back',
          },
          languageSelect: {
            language: 'Language',
          },
        },
        validation: {
          required: {
            general: 'Please answer this question',
            singleChoice: 'Please select answer',
            multiChoice: 'Please select answers',
            open: 'Please fill out the answer',
          },
        },
      },
    },
  },
};
