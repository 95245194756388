import { Typography } from '@material-ui/core';
import classNames from 'classnames';
import { pipe } from 'commity-rehook-fork';
import * as PropTypes from 'prop-types';
import React from 'react';
import { memoize, withStylesAsClasses } from '../../../rehooks';
import { TranslationProperty } from './TranslationProperty';

const styles = theme => ({
  root: {
    border: theme.primaryBorder.border,
    borderRadius: theme.primaryBorder.borderRadius,
    margin: theme.spacing.unit,
    padding: theme.spacing.unit,
    display: 'flex',
    flexDirection: 'column',
  },
});

/*
agreement: {
    text: "I agree with terms", // text
    errorMessage: "You can't start without agreement." // text
  },
*/

const TranslationAgreementPure = ({
  propertyLabel,
  translationPath,
  sourcePropertyValue: sourceAgreement = {},
  targetPropertyValue: targetAgreement = {},
  classes,
  ...others
}) => {
  return (
    <div {...others} className={classNames(classes.root, others.className)}>
      <Typography variant="subtitle1">{propertyLabel}</Typography>
      <TranslationProperty
        propertyLabel="Agreement Text"
        translationPath={[...translationPath, 'text']}
        sourcePropertyValue={sourceAgreement.text}
        targetPropertyValue={targetAgreement.text}
      />
      {sourceAgreement.instruction && (
        <TranslationProperty
          propertyLabel="Instruction"
          translationPath={[...translationPath, 'instruction']}
          sourcePropertyValue={sourceAgreement.instruction}
          targetPropertyValue={targetAgreement.instruction}
          showPlaceholderButton
        />
      )}
      <TranslationProperty
        propertyLabel="Error Message"
        translationPath={[...translationPath, 'errorMessage']}
        sourcePropertyValue={sourceAgreement.errorMessage}
        targetPropertyValue={targetAgreement.errorMessage}
      />
    </div>
  );
};

export const TranslationAgreement = pipe(
  withStylesAsClasses(styles),
  memoize(TranslationAgreementPure)
);

TranslationAgreement.propTypes = {
  propertyLabel: PropTypes.string.isRequired,
  translationPath: PropTypes.array.isRequired,
  sourcePropertyValue: PropTypes.object,
  targetPropertyValue: PropTypes.object,
};
