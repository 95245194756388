import { allPass, always, anyPass, defaultTo, filter, find, has, isEmpty, path, pipe, pluck, prop, test } from 'ramda';
import { isGender, isMulti, isSingle, isSquare, isStar, isOutro, isDynamicSquare } from 'xperience-model-management';
import { createError, ERROR_MESSAGES } from './errors';
import {
  anyValueInRange,
  SQUARE_RATING_RANGE,
  STAR_RATING_RANGE,
  DYNAMIC_SQUARE_RATING_RANGE,
} from './validationHelper';
import { MAXSECOND, MINSECOND } from '../../components/objectInspector/itemProperties/OutroOI';

export const alwaysApply = always(true);

export const emptyChoicesValidator = {
  applyIf: (item) => anyPass([isSingle, isMulti, isGender])(item),
  validationFn: ({ surveyDefinition, ...others }, item, itemPath) => {
    const choices = prop('choices', item);
    if (!choices || isEmpty(choices)) {
      return [
        createError({
          message: ERROR_MESSAGES.CHOICES_ARE_EMPTY,
          validator: 'emptyChoicesValidator',
          itemPath,
        }),
      ];
    }
    return [];
  },
};

export const emptyConditionForConditionalTextValidator = {
  applyIf: (item) => anyPass([isOutro])(item),
  validationFn: ({ surveyDefinition, ...others }, item, itemPath) => {
    const conditionalTexts = prop('conditionalTexts', item);
    if (conditionalTexts) {
      const emptyCondition = !!find(({ condition }) => !condition, conditionalTexts);
      if (emptyCondition) {
        return [
          createError({
            message: ERROR_MESSAGES.CONDITION_ARE_EMPTY,
            validator: 'emptyConditionForConditionalTextValidator',
            itemPath,
          }),
        ];
      }
    }
    return [];
  },
};

export const cannotApplyBothVisibilityConditionsAtOnceValidator = {
  applyIf: allPass([has('hideIf'), has('visibleIf')]),
  validationFn: ({ ...others }, item, itemPath) => [
    createError({
      message: ERROR_MESSAGES.CANNOT_APPLY_BOTH_VISIBILITY_CONDITIONS_AT_ONCE,
      validator: 'cannotApplyBothVisibilityConditionsAtOnceValidator',
      itemPath,
    }),
  ],
};

export const choiceValueInStarSquareChoicesValidator = {
  applyIf: anyPass([isStar, isSquare, isDynamicSquare]),
  validationFn: ({ ...others }, item, itemPath) => {
    let error;
    const choicesValues = pipe(defaultTo([]), pluck('value'), filter(Boolean))(item.choices);
    if (isStar(item) && anyValueInRange(choicesValues, STAR_RATING_RANGE)) {
      error = ERROR_MESSAGES.CHOICE_VALUE_IN_STAR_RANGE;
    } else if (isSquare(item) && anyValueInRange(choicesValues, SQUARE_RATING_RANGE)) {
      error = ERROR_MESSAGES.CHOICE_VALUE_IN_SQUARE_RANGE;
    } else if (isDynamicSquare(item) && anyValueInRange(choicesValues, DYNAMIC_SQUARE_RATING_RANGE)) {
      error = ERROR_MESSAGES.CHOICE_VALUE_IN_DYNAMIC_SQUARE_RANGE;
    }
    if (error) {
      return [createError({ message: error, validator: 'choiceValueInStarSquareChoicesValidator', itemPath })];
    }
    return [];
  },
};

export const redirectValidator = {
  applyIf: (item) => anyPass([isOutro])(item),
  validationFn: ({ surveyDefinition, ...others }, item, itemPath) => {
    const isRedirect = path(['componentProps', 'isRedirect'], item);
    let errors = [];
    if (isRedirect) {
      const { redirectLink, redirectSeconds } = item.componentProps;
      if (!redirectSeconds || redirectSeconds < MINSECOND || redirectSeconds > MAXSECOND) {
        errors.push(
          createError({
            message: ERROR_MESSAGES.REDIRECT_SECONDS_IS_BAD,
            validator: 'redirectValidator',
            itemPath,
          })
        );
      }
      if (!redirectLink) {
        errors.push(
          createError({
            message: ERROR_MESSAGES.REDIRECT_LINK_IS_EMPTY,
            validator: 'redirectValidator',
            itemPath,
          })
        );
      }
      if (redirectLink && !test(/http/, redirectLink)) {
        errors.push(
          createError({
            message: ERROR_MESSAGES.REDIRECT_LINK_WITHOUT_HTTP,
            validator: 'redirectValidator',
            itemPath,
          })
        );
      }
    }
    return errors;
  },
};

export const alwaysValidValidator = {
  applyIf: alwaysApply,
  validationFn: always([]),
};

export const alwaysInvalidValidator = {
  applyIf: alwaysApply,
  validationFn: always([createError({ message: ERROR_MESSAGES.ALWAYS_INVALID, validator: 'alwaysInvalidValidator' })]),
};
