import { Divider, Switch, TextField, Typography } from '@material-ui/core';
import classNames from 'classnames';
import { pipe, withHandlers } from 'commity-rehook-fork';
import * as PropTypes from 'prop-types';
import { has, omit, pathOr, propOr } from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { withStylesAsClasses } from '../../../rehooks';
import {
  getSelectedTranslationItem,
  getTranslationPathForItem,
  toggleAgreement,
  updateTranslation,
} from '../../../state/modules/survey/translations';
import { CopyPlaceholderButton } from '../../CopyPlaceholderButton';
import { createTranslationOnChangeHandler } from '../../handlerHelper';
import { toggleOptOut } from '../../../state/modules/survey/translations/index';
import { getSelectedItemPath } from '../../../state/modules/editor';
import { getHiddenIntro, toggleHiddenIntro } from '../../../state/modules/survey/overview/index';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    flexGrow: 1,
  },
  switchHeader: {
    display: 'flex',
    alignItems: 'center',
  },
  switchText: {
    flex: 1,
  },
  instruction: {
    display: 'flex',
    alignItems: 'baseline',
  },
  instructionTextField: {
    flex: 1,
  },
});

/*
const model = {
  id: "intro", // povinný unikátní identifikátor
  type: "intro",
  imagePath: { type: "variable", value: "carImage" }, // cesta k obrázku - expression //todo
  optional: true, // hardcodovaná hodnota - vyžaduje dotazovač, není možné menit
  text: "krátký nadpis", // text s placeholdery
  introduction: "dlouhá intrukce", // text s placeholdery,
  agreement: {
    text: "I agree with terms", // text
    errorMessage: "You can't start without agreement." // text
  },
  optOut: {
    text: "pokud se chcetet odhlásit", // text
    linkText: "klikněte zde", // text
    unsubscribedText: "byl jste odhlášen" // text
    modalInfo: "opravdu se chcete odhlásit" // text
    modalOk: "ano" // text
    modalCancel: "zrušit" // text
  },
  startButtonText: "text tlačítka start" // text
};
 */

const IntroOIPure = ({
  item,
  translationItem,
  translationPath,
  onAgreementToggle,
  onAgreementTextChange,
  onAgreementErrorMessageChange,
  onAgreementInstructionChange,
  onOptOutToggle,
  onStartButtonTextChange,
  onOptOutTextChange,
  onOptOutLinkTextChange,
  onOptOutUnsubscribedTextChange,
  onOptOutModalInfoChange,
  onOptOutModalOkChange,
  onOptOutModalCancelChange,
  hiddenIntro,
  onHiddenIntroToggle,
  modelPath,
  classes,
  ...others
}) => {
  const showAgreement = has('agreement', translationItem);
  const showOptOut = has('optOut', translationItem);
  const onAgreementSwitchClick = () => onAgreementToggle(translationItem, translationPath);
  const onOptOutSwitchClick = () => onOptOutToggle(translationItem, translationPath);

  return (
    <div {...others} className={classNames(classes.root, others.className)}>
      <>
        <div className={classes.switchHeader}>
          <Typography variant="h6" className={classes.switchText}>
            Hidden
          </Typography>
          <Switch checked={hiddenIntro} onChange={onHiddenIntroToggle(modelPath)} />
        </div>
        <div className={classes.switchHeader}>
          <Typography variant="h6" className={classes.switchText}>
            Agreement
          </Typography>
          <Switch checked={showAgreement} onChange={onAgreementSwitchClick} />
        </div>
        {showAgreement && (
          <>
            <TextField
              id="agreementText"
              label="Checkbox Label"
              placeholder="... insert label for the checkbox"
              multiline
              value={pathOr('', ['agreement', 'text'], translationItem)}
              onChange={onAgreementTextChange}
              margin="normal"
            />
            <div className={classes.instruction}>
              <TextField
                id="instruction"
                label="Instruction"
                placeholder="... insert Instruction (not mandatory)"
                multiline
                value={pathOr('', ['agreement', 'instruction'], translationItem)}
                onChange={onAgreementInstructionChange}
                className={classes.instructionTextField}
                margin="normal"
              />
              <CopyPlaceholderButton />
            </div>
            <TextField
              id="errorMessage"
              label="Error Message"
              placeholder="... insert error message"
              multiline
              value={pathOr('', ['agreement', 'errorMessage'], translationItem)}
              onChange={onAgreementErrorMessageChange}
              margin="normal"
            />
          </>
        )}
        <div className={classes.switchHeader}>
          <Typography variant="h6" className={classes.switchText}>
            Opt out
          </Typography>
          <Switch checked={showOptOut} onChange={onOptOutSwitchClick} />
        </div>
        {showOptOut && (
          <>
            <div className={classes.instruction}>
              <TextField
                id="optOutText"
                label="Text"
                placeholder="... insert text for opt out"
                multiline
                value={pathOr('', ['optOut', 'text'], translationItem)}
                onChange={onOptOutTextChange}
                className={classes.instructionTextField}
                margin="normal"
              />
              <CopyPlaceholderButton
                customPlaceholder={[{ name: 'linkText', type: 'STRING', description: 'Link text' }]}
              />
            </div>
            <TextField
              id="optOutLinkText"
              label="Link text"
              placeholder="... insert text for opt out link"
              multiline
              value={pathOr('', ['optOut', 'linkText'], translationItem)}
              onChange={onOptOutLinkTextChange}
              margin="normal"
            />
            <TextField
              id="optOutUnsubscribedText"
              label="Unsubscribed"
              placeholder="... insert unsubscribed text for opt out"
              multiline
              value={pathOr('', ['optOut', 'unsubscribedText'], translationItem)}
              onChange={onOptOutUnsubscribedTextChange}
              margin="normal"
            />
            <TextField
              id="optOutModalInfo"
              label="Modal info"
              placeholder="... insert modal info for opt out"
              multiline
              value={pathOr('', ['optOut', 'modalInfo'], translationItem)}
              onChange={onOptOutModalInfoChange}
              margin="normal"
            />
            <TextField
              id="optOutModalOk"
              label="Modal ok"
              placeholder="... insert text for modal ok button"
              multiline
              value={pathOr('', ['optOut', 'modalOk'], translationItem)}
              onChange={onOptOutModalOkChange}
              margin="normal"
            />
            <TextField
              id="optOutModalCancel"
              label="Modal cancel"
              placeholder="... insert text for modal cancel button"
              multiline
              value={pathOr('', ['optOut', 'modalCancel'], translationItem)}
              onChange={onOptOutModalCancelChange}
              margin="normal"
            />
          </>
        )}
      </>
      <Divider variant="middle" />
      <TextField
        id="startButtonText"
        label="Start Button Text"
        placeholder="... insert start button text"
        multiline
        value={propOr('', 'startButtonText', translationItem)}
        onChange={onStartButtonTextChange}
        className={classes.textField}
        margin="normal"
      />
    </div>
  );
};

const IntroOIWithStyles = pipe(
  withHandlers({
    onAgreementTextChange: createTranslationOnChangeHandler(['agreement', 'text']),
    onAgreementErrorMessageChange: createTranslationOnChangeHandler(['agreement', 'errorMessage']),
    onAgreementInstructionChange: createTranslationOnChangeHandler(['agreement', 'instruction']),
    onStartButtonTextChange: createTranslationOnChangeHandler(['startButtonText']),
    onOptOutTextChange: createTranslationOnChangeHandler(['optOut', 'text']),
    onOptOutLinkTextChange: createTranslationOnChangeHandler(['optOut', 'linkText']),
    onOptOutUnsubscribedTextChange: createTranslationOnChangeHandler(['optOut', 'unsubscribedText']),
    onOptOutModalInfoChange: createTranslationOnChangeHandler(['optOut', 'modalInfo']),
    onOptOutModalOkChange: createTranslationOnChangeHandler(['optOut', 'modalOk']),
    onOptOutModalCancelChange: createTranslationOnChangeHandler(['optOut', 'modalCancel']),
  }),
  omit(['onTranslationChange']),
  withStylesAsClasses(styles),
  IntroOIPure
);

const mapStateToProps = (state, { item }) => {
  return {
    translationItem: getSelectedTranslationItem(state),
    translationPath: getTranslationPathForItem(state, item),
    modelPath: getSelectedItemPath(state),
    hiddenIntro: getHiddenIntro(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onTranslationChange: ({ translationPath, value }) => {
      dispatch(updateTranslation(translationPath, value));
    },
    onAgreementToggle: (item, translationPath) => {
      dispatch(toggleAgreement(item, translationPath));
    },
    onOptOutToggle: (item, translationPath) => {
      dispatch(toggleOptOut(item, translationPath));
    },
    onHiddenIntroToggle: (modelPath) => (event, checked) => {
      dispatch(toggleHiddenIntro(checked, modelPath));
    },
  };
};

export const IntroOI = connect(mapStateToProps, mapDispatchToProps)(IntroOIWithStyles);

IntroOI.propTypes = {
  item: PropTypes.shape({
    agreement: PropTypes.shape({
      text: PropTypes.string,
      errorMessage: PropTypes.string,
    }),
  }),
};
