export const createOnChangeHandler = (path) => ({ modelPath, onChange }) => (event) => {
  return onChange({
    itemPath: [...modelPath, ...path],
    value: event.target ? event.target.value : event,
  });
};

export const createOnDeleteHandler = ({ translationPath, modelPath, onDelete, parentPageModelPath }) => (event) => {
  event.preventDefault();
  event.stopPropagation();
  onDelete(modelPath, translationPath, parentPageModelPath);
};

export const translationOnChangeHandler = ({ translationPath, onChange }) => (event) => {
  return onChange({
    translationPath,
    value: event.target.value,
  });
};

export const createTranslationOnChangeHandler = (path) => ({ translationPath, onTranslationChange }) => (event) => {
  return onTranslationChange({
    translationPath: [...translationPath, ...path],
    value: event.target.value,
  });
};

export const createRedirectOnChangeHandler = (path) => ({ modelPath, onRedirectChange }) => (event) => {
  return onRedirectChange({
    redirectPath: [...modelPath, ...path],
    value: event.target.value,
  });
};
