import { IconButton } from '@material-ui/core';
import classNames from 'classnames';
import { withHandlers, withState, withStateHandlers } from 'commity-rehook-fork';
import { AlphaPcircleOutline } from 'mdi-material-ui';
import * as PropTypes from 'prop-types';
import { omit, pipe } from 'ramda';
import React from 'react';
import { withStylesAsClasses } from '../rehooks';
import { AlertSnackbar } from './AlertSnackbar';
import { PlaceholderModal } from './PlaceholderModal';

const styles = () => ({
  root: {},
});

const CopyPlaceholderButtonPure = ({
  classes,
  showPlaceholderModal,
  openPlaceholderModal,
  onClosePlaceholderModal,
  snackbarOpen,
  onCopyToClipboard,
  setSnackbarOpen,
  openSnackBar,
  closeSnackBar,
  readOnly,
  customPlaceholder,

  ...others
}) => {
  return (
    <div {...others} className={classNames(classes.root, others.className)}>
      <IconButton
        color="inherit"
        aria-label="Placeholders"
        title="Placeholders"
        onClick={showPlaceholderModal}
        disabled={readOnly}
        className={classes.placeholderButton}>
        <AlphaPcircleOutline />
      </IconButton>
      <PlaceholderModal
        open={openPlaceholderModal}
        onClose={onClosePlaceholderModal}
        onCopyToClipboard={openSnackBar}
        customPlaceholder={customPlaceholder}
      />
      <AlertSnackbar
        snackbarOpen={snackbarOpen}
        closeSnackbar={closeSnackBar}
        variant="success"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        message="Placeholder has been copied to the clipboard"
      />
    </div>
  );
};

export const CopyPlaceholderButton = pipe(
  withState('snackbarOpen', 'setSnackbarOpen', false),
  withHandlers({
    closeSnackBar: ({ setSnackbarOpen }) => () => setSnackbarOpen(false),
    openSnackBar: ({ setSnackbarOpen }) => () => setSnackbarOpen(true),
  }),
  withStateHandlers(
    {
      openPlaceholderModal: false,
    },
    {
      showPlaceholderModal: () => () => ({ openPlaceholderModal: true }),
      onClosePlaceholderModal: () => () => ({ openPlaceholderModal: false }),
    }
  ),
  withStylesAsClasses(styles),
  omit(['onNoClick', 'onYesClick']),
  CopyPlaceholderButtonPure
);

CopyPlaceholderButton.propTypes = {
  readOnly: PropTypes.bool,
  customPlaceholder: PropTypes.array,
};
