import { addIndex, filter, forEach, has, keys, map, reduce } from 'ramda';
import { isPage } from 'xperience-model-management';
import { generateUniqQuestionId } from '../../components/questionHelper';
import { updateItemIdHandler } from '../../state/modules/survey/overview/handlers';
import { updateTranslationHandler } from '../../state/modules/survey/translations/handlers';

const mapIndexed = addIndex(map);

export const addPagesId = (survey) => {
  const definitionWithPath = mapIndexed((item, index) => ({ ...item, itemPath: [index] }), survey.definition);
  const pagesWithoutId = filter((item) => isPage(item) && !has('id', item), definitionWithPath);
  const langs = keys(survey.translations);
  const transformedSurvey = reduce(
    (acc, page) => {
      const id = generateUniqQuestionId(acc.definition, 'T');
      let state = updateItemIdHandler(acc, { itemPath: page.itemPath, newValue: id });
      forEach((lang) => {
        state = updateTranslationHandler(state, [lang, id, 'title'], '');
      }, langs);
      return state;
    },
    survey,
    pagesWithoutId
  );
  return transformedSurvey;
};
