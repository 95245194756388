import { Paper, Typography } from '@material-ui/core';
import classNames from 'classnames';
import { pipe } from 'commity-rehook-fork';
import * as PropTypes from 'prop-types';
import { find, omit, pathOr, prepend, propEq, propOr, reject } from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { memoize, withStylesAsClasses } from '../../../rehooks';
import { getValidationResults } from '../../../state/modules/validations';
import { ValidationResult } from './ValidationResult';

const styles = theme => ({
  root: {},

  body: {
    padding: theme.spacing.unit,
  },

  validationHeader: {
    padding: theme.spacing.unit,
  },
});

const getResults = pathOr([], ['results']);

const findSelectedResult = (item, results) => {
  if (!item) {
    return null;
  }
  return find(propEq('id', item.id), results);
};

const ValidationsPure = ({ classes, validationResults, item, isEditorView, ...others }) => {
  const results = getResults(validationResults);
  const selectedResult = findSelectedResult(item, results);
  const validationTextHeader = isEditorView ? 'Survey errors' : 'Translations errors';

  const resultsWithSelectedItemAtFirstPosition = selectedResult
    ? pipe(
        reject(propEq('id', item.id)),
        prepend(selectedResult)
      )(results)
    : results;

  return (
    <div {...others} className={classNames(classes.root, others.className)}>
      <Paper square className={classes.body}>
        <Typography variant="h5" className={classes.validationHeader}>
          {validationTextHeader}
        </Typography>
        {resultsWithSelectedItemAtFirstPosition.map((validationResult, index) => {
          return (
            <ValidationResult
              expand={validationResult.id === propOr(-1, 'id', item)}
              validationResult={validationResult}
              key={validationResult.id + index}
              isEditorView={isEditorView}
            />
          );
        })}
      </Paper>
    </div>
  );
};

const StyledValidations = pipe(
  withStylesAsClasses(styles),
  omit(['dispatch', 'setExpand']),
  memoize(ValidationsPure)
);

const mapStateToProps = state => {
  return {
    validationResults: getValidationResults(state),
  };
};

export const Validations = connect(mapStateToProps)(StyledValidations);

Validations.propTypes = {
  selectedItemPath: PropTypes.array,
  validationResults: PropTypes.array,
  item: PropTypes.shape({}),
  isEditorView: PropTypes.bool,
};
