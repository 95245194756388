/* eslint no-template-curly-in-string: "off" */
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import classNames from 'classnames';
import { withHandlers } from 'commity-rehook-fork';
import * as PropTypes from 'prop-types';
import { omit, pipe, propEq, reject } from 'ramda';
import React, { useMemo } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { connect } from 'react-redux';
import { dataTypes } from 'xperience-model-management';
import { withStylesAsClasses } from '../rehooks';
import { getPlaceholders } from '../state/modules/editor';

const styles = theme => ({
  root: {
    cursor: 'pointer',
  },

  placeholderButtonContainer: {
    display: 'flex',
    padding: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
    cursor: 'pointer',
    justifyContent: 'flex-start',
    textTransform: 'none',
  },

  placeholderText: {
    alignSelf: 'center',
    marginBottom: theme.spacing.unit,
  },

  placeholderDescription: {
    paddingLeft: theme.spacing.unit / 2,
  },
});

const isBoolPlaceholder = propEq('type', dataTypes.boolean);

const PlaceholderModalPure = ({
  classes,
  open,
  handleCloseClick,
  onClose,
  handleSelectClick,
  placeholders,
  customPlaceholder = [],
  ...others
}) => {
  const placeHolderValueString = name => `\${${name}}`;
  const placeholdersArray = customPlaceholder.length > 0 ? customPlaceholder : placeholders;
  const nonBoolPlaceholders = useMemo(() => reject(isBoolPlaceholder, placeholdersArray), [placeholdersArray]);

  return (
    <div {...others} className={classNames(classes.root, others.className)}>
      <Dialog aria-labelledby="placeholder-dialog-title" open={open} onClose={onClose}>
        <DialogTitle id="placeholder-dialog-title">Select Placeholder to copy to Clipboard</DialogTitle>
        <DialogContent>
          {nonBoolPlaceholders.map(({ name, description }) => {
            return (
              <CopyToClipboard text={placeHolderValueString(name)} key={name}>
                <Button
                  variant="outlined"
                  id="placeholder-dialog-container"
                  color="primary"
                  fullWidth
                  title="Click to copy to clipboard"
                  className={classes.placeholderButtonContainer}
                  onClick={handleSelectClick}>
                  <Typography id="placeholder-value">{placeHolderValueString(name)}</Typography>
                  <Typography id="placeholder-dialog-description" className={classes.placeholderDescription}>
                    - {description}
                  </Typography>
                </Button>
              </CopyToClipboard>
            );
          })}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseClick} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const StyledPlaceholderModal = pipe(
  withHandlers({
    handleCloseClick: ({ onClose }) => () => {
      onClose();
    },
    handleSelectClick: ({ onClose, onCopyToClipboard }) => () => {
      onCopyToClipboard();
      onClose();
    },
  }),
  withStylesAsClasses(styles),
  omit(['dispatch', 'onSelectClick', 'onCopyToClipboard']),
  PlaceholderModalPure
);

const mapStateToProps = state => ({
  placeholders: getPlaceholders(state),
});

export const PlaceholderModal = connect(mapStateToProps)(StyledPlaceholderModal);

PlaceholderModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onCopyToClipboard: PropTypes.func.isRequired,
  customPlaceholder: PropTypes.array,
};

PlaceholderModal.defaultProps = {
  open: false,
};
