import { TextField } from '@material-ui/core';
import classNames from 'classnames';
import { pipe, withHandlers } from 'commity-rehook-fork';
import React from 'react';
import { connect } from 'react-redux';
import { withStylesAsClasses } from '../../../rehooks';
import { getSelectedItemPath } from '../../../state/modules/editor';
import { getDynamicSquareCountValue, updateDynamicSquareCountValue } from '../../../state/modules/survey/overview';
import { createTranslationOnChangeHandler } from '../../handlerHelper';
import {
  getSelectedTranslationItem,
  updateTranslation,
  getTranslationPathForItem,
} from '../../../state/modules/survey/translations';
import { propOr, omit } from 'ramda';
import * as PropTypes from 'prop-types';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },

  rowsInputWithAutofocusContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },

  rowsInput: {
    width: 40,
  },
});

const TextQuestionOIPure = ({
  item,
  modelPath,
  classes,
  countValue,
  onDynamicSquareCountValueChange,
  translationItem,
  onLabelMinChange,
  onLabelMaxChange,
  readOnly,
  ...others
}) => {
  return (
    <div {...others} className={classNames(classes.root, others.className)}>
      <div className={classes.rowsInputWithAutofocusContainer}>
        <TextField
          id="labelMin"
          label="Label min"
          placeholder="..."
          multiline
          value={propOr('', 'labelMin', translationItem)}
          onChange={onLabelMinChange}
          margin="normal"
        />
        <TextField
          id="labelMax"
          label="Label max"
          placeholder="..."
          multiline
          value={propOr('', 'labelMax', translationItem)}
          onChange={onLabelMaxChange}
          margin="normal"
        />
        <TextField
          id="row"
          label="Count"
          type="number"
          placeholder="..."
          className={classes.rowsInput}
          value={countValue}
          onChange={onDynamicSquareCountValueChange(modelPath)}
          margin="normal"
          InputProps={{ inputProps: { min: 2, max: 10 } }}
          InputLabelProps={{
            shrink: true,
          }}
          disabled={readOnly}
        />
      </div>
    </div>
  );
};

const TextQuestionOIWithStyles = pipe(
  withHandlers({
    onLabelMinChange: createTranslationOnChangeHandler(['labelMin']),
    onLabelMaxChange: createTranslationOnChangeHandler(['labelMax']),
  }),
  withStylesAsClasses(styles),
  omit(['translationPath', 'onTranslationChange']),
  TextQuestionOIPure
);

const mapStateToProps = (state, { item }) => ({
  translationItem: getSelectedTranslationItem(state),
  translationPath: getTranslationPathForItem(state, item),
  modelPath: getSelectedItemPath(state),
  countValue: getDynamicSquareCountValue(state),
});

const mapDispatchToProps = (dispatch) => {
  return {
    onTranslationChange: ({ translationPath, value }) => {
      dispatch(updateTranslation(translationPath, value));
    },
    onDynamicSquareCountValueChange: (modelPath) => (event) => {
      dispatch(updateDynamicSquareCountValue(event.target.value, modelPath));
    },
  };
};

export const DynamicSquareQuestionOI = connect(mapStateToProps, mapDispatchToProps)(TextQuestionOIWithStyles);

DynamicSquareQuestionOI.propTypes = {
  readOnly: PropTypes.bool,
};
