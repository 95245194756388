import { Button, IconButton, MenuItem, Select } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import classNames from 'classnames';
import { pipe, withHandlers, withProps } from 'commity-rehook-fork';
import * as PropTypes from 'prop-types';
import { any, equals, filter, find, lensProp, omit, path, prop, propEq, reject, set } from 'ramda';
import React from 'react';
import { conditionsType } from '../../../conditionsType';
import { withStylesAsClasses } from '../../../rehooks';
import { ExpressionArgumentSelect, valuePropType } from '../../ExpressionArgumentSelect';

const styles = (theme) => ({
  root: {
    display: 'flex',
    alignItems: 'flex-end',
  },

  selectContainer: {
    minWidth: 280,
    flex: 1,
    padding: `0 ${theme.spacing.unit}px ${theme.spacing.unit}px ${theme.spacing.unit}px`,
    '&:first-child': {
      maxWidth: 350,
    },
  },

  conditionContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    padding: `0 ${theme.spacing.unit}px ${theme.spacing.unit}px ${theme.spacing.unit}px`,
  },

  addOrButtonContainer: {
    alignSelf: 'center',
  },

  orButton: {
    color: theme.palette.orange,
    borderColor: '#C66F3660',
    '&:hover': {
      borderColor: theme.palette.orange,
      backgroundColor: '#C66F3614',
    },
  },

  deleteButton: {
    marginRight: theme.spacing.unit / 4,
  },
});

const ConditionsRowPure = ({
  classes,
  flatSurveyAndSampleDataOptions,
  item,
  onDeleteClick,
  operationName,
  setData,
  setNewRow,
  firstValueId,
  firstValue,
  ...others
}) => {
  const optionsWithoutFirstValue = reject(propEq('value', firstValueId), flatSurveyAndSampleDataOptions);
  let possibleConditions = conditionsType;
  if (firstValue) {
    const filterByDataType = pipe(prop('for'), any(equals(firstValue.dataType)));
    possibleConditions = filter(filterByDataType, conditionsType);
  }

  if (!prop(item.condition, possibleConditions) && item.condition !== 'responded') {
    setData('condition')('responded');
  }

  return (
    <div {...others} className={classNames(classes.root, others.className)}>
      <div className={classes.selectContainer}>
        <ExpressionArgumentSelect
          onSelect={setData('firstValue')}
          value={item.firstValue}
          options={flatSurveyAndSampleDataOptions}
        />
      </div>
      {firstValueId && (
        <>
          <div className={classes.conditionContainer}>
            <Select onChange={setData('isNegation')} value={item.isNegation}>
              <MenuItem value={0}>IS</MenuItem>
              <MenuItem value={1}>NOT</MenuItem>
            </Select>
          </div>
          <div className={classes.conditionContainer}>
            <Select onChange={setData('condition')} value={item.condition}>
              {Object.keys(possibleConditions).map((val) => (
                <MenuItem key={val} value={val}>
                  {conditionsType[val].name}
                </MenuItem>
              ))}
            </Select>
          </div>
          <div className={classes.selectContainer}>
            {['responded', 'isTrue', 'isFalse'].indexOf(item.condition) === -1 && (
              <ExpressionArgumentSelect
                onSelect={setData('secondValue')}
                value={item.secondValue}
                options={optionsWithoutFirstValue}
              />
            )}
          </div>
          <div className={classes.addOrButtonContainer}>
            {operationName === 'or' ? (
              <Button
                color="primary"
                variant="outlined"
                title="Add AND condition"
                aria-label="Add"
                onClick={setNewRow('and')}>
                AND
              </Button>
            ) : (
              <Button
                color="primary"
                variant="outlined"
                title="Add OR condition"
                aria-label="Add"
                onClick={setNewRow('or')}
                className={classes.orButton}>
                OR
              </Button>
            )}
          </div>
        </>
      )}
      <div className={classes.deleteButton}>
        <IconButton onClick={onDeleteClick} aria-label="Delete" title="Condition Delete">
          <DeleteIcon />
        </IconButton>
      </div>
    </div>
  );
};

const checkFirstValue = path(['firstValue', 'value']);
const checkSecondValue = (row) => {
  if (['responded', 'isTrue', 'isFalse'].indexOf(row.condition) !== -1) {
    return true;
  }
  return path(['secondValue', 'value'], row) !== undefined;
};
export const validateConditionsRow = (row) => {
  return checkFirstValue(row) !== undefined && checkSecondValue(row);
};

export const ConditionsRow = pipe(
  withProps(({ item, flatSurveyAndSampleDataOptions }) => {
    const firstValueId = path(['firstValue', 'value'], item);
    const firstValue = find(propEq('value', firstValueId), flatSurveyAndSampleDataOptions);
    return {
      firstValueId,
      firstValue,
    };
  }),
  withHandlers({
    setData: ({ item, onChange }) => (key) => (event) => {
      const newValue = event.target ? event.target.value : event;

      return onChange(set(lensProp(key), newValue, item));
    },
  }),
  withStylesAsClasses(styles),
  omit(['onChange']),
  ConditionsRowPure
);

ConditionsRow.propTypes = {
  item: PropTypes.shape({
    firstValue: valuePropType,
    isNegation: PropTypes.number,
    condition: PropTypes.string,
    secondValue: valuePropType,
  }),
  flatSurveyAndSampleDataOptions: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  setNewRow: PropTypes.func.isRequired,
  operationName: PropTypes.string.isRequired,
};
ConditionsRow.defaultProps = {};
