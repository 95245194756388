/* eslint no-template-curly-in-string: "off" */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  Switch,
  Typography,
} from '@material-ui/core';
import classNames from 'classnames';
import { withHandlers } from 'commity-rehook-fork';
import * as PropTypes from 'prop-types';
import { pipe } from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { withStylesAsClasses } from '../rehooks';
import { getAutoNextNavigation, toggleAutoNextNavigation } from '../state/modules/survey/overview';
import { changeShowLanguageSwitch, getShowLanguageSwitch } from '../state/modules/survey/overview/index';

const styles = () => ({
  root: {},

  surveySettingsHeaderText: {
    cursor: 'default',
  },

  autoNavigationContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  showLanguageSelect: {
    width: 125,
  },
});

const SurveySettingsDialogPure = ({
  classes,
  open,
  onClose,
  onAutoNextNavigationToggle,
  onShowLanguageSwitchChange,
  handleCloseClick,
  navigationAutoNext,
  showLanguageSwitch = 'intro',
  ...others
}) => {
  return (
    <div {...others} className={classNames(classes.root, others.className)}>
      <Dialog fullWidth maxWidth="xs" aria-labelledby="survey-settings-dialog-title" open={open} onClose={onClose}>
        <DialogTitle className={classes.surveySettingsHeaderText} id="survey-settings-dialog-title">
          Survey Settings
        </DialogTitle>
        <DialogContent>
          <div className={classes.autoNavigationContainer}>
            <Typography>Auto Next Navigation</Typography>
            <Switch checked={navigationAutoNext} onChange={onAutoNextNavigationToggle} />
          </div>
          <div className={classes.autoNavigationContainer}>
            <Typography>Show Language switch</Typography>
            <Select
              onChange={onShowLanguageSwitchChange}
              value={showLanguageSwitch}
              className={classes.showLanguageSelect}>
              <MenuItem value={'intro'}>Intro</MenuItem>
              <MenuItem value={'allItems'}>All items</MenuItem>
            </Select>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseClick} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const StyledSurveySettingsDialog = pipe(
  withHandlers({
    handleCloseClick: ({ onClose }) => () => {
      onClose();
    },
  }),
  withStylesAsClasses(styles),
  SurveySettingsDialogPure
);

const mapStateToProps = (state) => {
  return {
    navigationAutoNext: getAutoNextNavigation(state),
    showLanguageSwitch: getShowLanguageSwitch(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAutoNextNavigationToggle: (event, checked) => {
      dispatch(toggleAutoNextNavigation(checked));
    },
    onShowLanguageSwitchChange: (event) => {
      dispatch(changeShowLanguageSwitch(event.target.value));
    },
  };
};

export const SurveySettingsDialog = connect(mapStateToProps, mapDispatchToProps)(StyledSurveySettingsDialog);

SurveySettingsDialogPure.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

SurveySettingsDialogPure.defaultProps = {
  open: false,
};
