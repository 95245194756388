import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import classNames from 'classnames';
import { lifecycle, pipe, withHandlers } from 'commity-rehook-fork';
import * as PropTypes from 'prop-types';
import { omit, pluck } from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { flatQuestionTree, ITEM_TYPES, questionTypes } from 'xperience-model-management';
import { withStylesAsClasses } from '../../../rehooks';
import { getSelectedCurrentLanguage, isMaster } from '../../../state/modules/editor';
import {
  addNewQuestionToPage,
  changeQuestionType,
  getSurveyDefinition,
  updateItem,
  updateItemId,
} from '../../../state/modules/survey/overview';
import { getTranslationPathForItem, updateTranslation } from '../../../state/modules/survey/translations';
import { CopyPlaceholderButton } from '../../CopyPlaceholderButton';
import { createTranslationOnChangeHandler } from '../../handlerHelper';
import { InputWithState } from '../../InputWithState';
import { scrollToOpenQuestion } from '../../questionHelper';
import { GenderQuestionForm } from './subforms/GenderQuestionForm';
import { MultiChoiceQuestionForm } from './subforms/MultiChoiceQuestionForm';
import { SingleChoiceQuestionForm } from './subforms/SingleChoiceQuestionForm';
import { SquareRatingForm } from './subforms/SquareRatingForm';
import { StarRatingForm } from './subforms/StarRatingForm';
import { TextQuestionForm } from './subforms/TextQuestionForm';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing.unit,
  },

  questionContainer: {
    display: 'flex',
  },

  questionText: {
    flex: 1,
  },

  idTextField: {
    paddingRight: theme.spacing.unit,
    width: 150,
  },

  textTextField: {},

  formControl: {},
  buttonPanel: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
});

const QUESTION_CONFIG = {
  [ITEM_TYPES.SINGLE_CHOICE]: {
    label: 'Single Choice Question',
    QuestionForm: SingleChoiceQuestionForm,
    config: { addChoice: true, deleteChoice: true },
  },
  [ITEM_TYPES.MULTI_CHOICE]: {
    QuestionForm: MultiChoiceQuestionForm,
    label: 'Multi Choice Question',
    config: { addChoice: true, deleteChoice: true },
  },
  [ITEM_TYPES.TEXT]: {
    QuestionForm: TextQuestionForm,
    label: 'Text Question',
    config: { addChoice: true, deleteChoice: true },
  },
  [ITEM_TYPES.STAR_RATING]: {
    QuestionForm: StarRatingForm,
    label: 'Star Rating Question',
    config: { addChoice: true, deleteChoice: true },
  },
  [ITEM_TYPES.SQUARE_RATING]: {
    QuestionForm: SquareRatingForm,
    label: 'Square Rating Question',
    config: { addChoice: true, deleteChoice: true },
  },
  [ITEM_TYPES.DYNAMIC_SQUARE_RATING]: {
    QuestionForm: SquareRatingForm,
    label: 'Dynamic Square Rating Question',
    config: { addChoice: true, deleteChoice: true },
  },
  [ITEM_TYPES.GENDER]: {
    QuestionForm: GenderQuestionForm,
    label: 'Gender Question',
    config: { addChoice: false, deleteChoice: false },
  },
  [ITEM_TYPES.ESCAPE]: {
    QuestionForm: null,
    label: 'Escape Question',
    config: { addChoice: false, deleteChoice: false },
  },
};

export const getQuestionFormConfig = (questionType) => QUESTION_CONFIG[questionType] || '';

const BasicQuestionFormPure = ({
  item,
  translationItem,
  translationPath,
  currentLanguage,
  onIdChange,
  onTextChange,
  onTypeChange,
  classes,
  onChange,
  onAddNew,
  onInstructionChange,
  modelPath,
  addQuestionToPage,
  parentPageModelPath,
  questionIds,
  readOnly,
  isMasterSurvey,

  ...others
}) => {
  const { QuestionForm, config } = getQuestionFormConfig(item.type);
  return (
    <div {...others} className={classNames(classes.root, others.className)}>
      <div className={classes.questionContainer}>
        <InputWithState
          id="id"
          label="ID"
          className={classes.idTextField}
          value={item.id}
          invalidValues={questionIds}
          onBlur={onIdChange}
          margin="normal"
          readOnly={readOnly}
          isMasterSurvey={isMasterSurvey}
        />
        <TextField
          id="text"
          label="Question text"
          multiline
          className={classes.questionText}
          value={translationItem && translationItem.text}
          onChange={onTextChange}
          margin="normal"
          disabled={readOnly}
        />
        <CopyPlaceholderButton readOnly={readOnly} />
      </div>
      <TextField
        id="instruction"
        label="Instruction"
        className={classes.textTextField}
        multiline
        value={translationItem && translationItem.instruction}
        onChange={onInstructionChange}
        margin="normal"
        disabled={readOnly}
      />
      <FormControl className={classes.formControl} margin="normal">
        <InputLabel htmlFor="type">Question type</InputLabel>
        <Select
          disabled={readOnly}
          value={item.type || false}
          onChange={onTypeChange}
          inputProps={{
            name: 'type',
            id: 'type',
          }}>
          {questionTypes.map((questionType, index) => (
            <MenuItem key={index} value={questionType}>
              {QUESTION_CONFIG[questionType].label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {QuestionForm && (
        <QuestionForm
          config={config}
          item={item}
          translationItem={translationItem}
          translationPath={translationPath}
          modelPath={modelPath}
          readOnly={readOnly}
        />
      )}
      <div className={classes.buttonPanel}>
        <Button
          variant="contained"
          title="Add Question to Page"
          color="primary"
          className={classes.button}
          onClick={addQuestionToPage(modelPath, parentPageModelPath, isMasterSurvey)}
          disabled={readOnly}>
          Add Question to Page
        </Button>
      </div>
    </div>
  );
};

const StyledBasicQuestionForm = pipe(
  withHandlers({
    onIdChange: ({ modelPath, onItemIdChange }) => (newValue) => {
      return onItemIdChange({
        itemPath: [...modelPath],
        newValue,
      });
    },
    onTextChange: createTranslationOnChangeHandler(['text']),
    onTypeChange: ({ modelPath, onQuestionTypeChange, currentLanguage }) => (event) => {
      onQuestionTypeChange({
        itemPath: modelPath,
        value: event.target.value,
        currentLanguage,
      });
    },
    onInstructionChange: createTranslationOnChangeHandler(['instruction']),
  }),
  omit(['onQuestionTypeChange', 'onTranslationChange', 'onItemIdChange']),
  withStylesAsClasses(styles),
  lifecycle({
    componentDidMount() {
      scrollToOpenQuestion();
    },
  }),
  BasicQuestionFormPure
);

const mapDispatchToProps = (dispatch) => {
  return {
    onChange: ({ itemPath, value }) => dispatch(updateItem(itemPath, value)),
    onItemIdChange: ({ itemPath, newValue }) => dispatch(updateItemId(itemPath, newValue)),
    onTranslationChange: ({ translationPath, value }) => dispatch(updateTranslation(translationPath, value)),
    onQuestionTypeChange: ({ itemPath, value, currentLanguage }) => {
      dispatch(changeQuestionType(itemPath, value, currentLanguage));
    },
    addQuestionToPage: (itemPath, parentPageQuestionPath, isMasterSurvey) => () => {
      dispatch(addNewQuestionToPage(itemPath, parentPageQuestionPath, isMasterSurvey));
    },
  };
};

const mapStateToProps = (state, { item }) => ({
  translationPath: getTranslationPathForItem(state, item),
  questionIds: pluck('id', flatQuestionTree(getSurveyDefinition(state))),
  currentLanguage: getSelectedCurrentLanguage(state),
  isMasterSurvey: isMaster(state),
});

export const BasicQuestionForm = connect(mapStateToProps, mapDispatchToProps)(StyledBasicQuestionForm);

BasicQuestionForm.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string,
    text: PropTypes.string,
    instruction: PropTypes.string,
    type: PropTypes.string,
  }),
  modelPath: PropTypes.array.isRequired,
  parentPageModelPath: PropTypes.array,
  readOnly: PropTypes.bool,
};
