import { Typography } from '@material-ui/core';
import classNames from 'classnames';
import { pipe } from 'commity-rehook-fork';
import * as PropTypes from 'prop-types';
import React from 'react';
import { memoize, withStylesAsClasses } from '../../../rehooks';
import { TranslationProperty } from './TranslationProperty';

const styles = theme => ({
  root: {
    border: theme.primaryBorder.border,
    borderRadius: theme.primaryBorder.borderRadius,
    margin: theme.spacing.unit,
    padding: theme.spacing.unit,
    display: 'flex',
    flexDirection: 'column',
  },
});

/*
optOut: {
  text: "pokud se chcetet odhlásit", // text
  linkText: "klikněte zde", // text
  unsubscribedText: "byl jste odhlášen" // text
  modalInfo: "opravdu se chcete odhlásit" // text
  modalOk: "ano" // text
  modalCancel: "zrušit" // text
}
*/

const TranslationOptOutPure = ({
  propertyLabel,
  translationPath,
  sourcePropertyValue: sourceAgreement = {},
  targetPropertyValue: targetAgreement = {},
  classes,
  ...others
}) => {
  return (
    <div {...others} className={classNames(classes.root, others.className)}>
      <Typography variant="subtitle1">{propertyLabel}</Typography>
      <TranslationProperty
        propertyLabel="Text"
        translationPath={[...translationPath, 'text']}
        sourcePropertyValue={sourceAgreement.text}
        targetPropertyValue={targetAgreement.text}
        showPlaceholderButton
        customPlaceholder={[{ name: 'linkText', type: 'STRING', description: 'Link text' }]}
      />
      <TranslationProperty
        propertyLabel="Link text"
        translationPath={[...translationPath, 'linkText']}
        sourcePropertyValue={sourceAgreement.linkText}
        targetPropertyValue={targetAgreement.linkText}
      />
      <TranslationProperty
        propertyLabel="Unsubscribed"
        translationPath={[...translationPath, 'unsubscribedText']}
        sourcePropertyValue={sourceAgreement.unsubscribedText}
        targetPropertyValue={targetAgreement.unsubscribedText}
      />
      <TranslationProperty
        propertyLabel="Modal info"
        translationPath={[...translationPath, 'modalInfo']}
        sourcePropertyValue={sourceAgreement.modalInfo}
        targetPropertyValue={targetAgreement.modalInfo}
      />
      <TranslationProperty
        propertyLabel="Modal ok"
        translationPath={[...translationPath, 'modalOk']}
        sourcePropertyValue={sourceAgreement.modalOk}
        targetPropertyValue={targetAgreement.modalOk}
      />
      <TranslationProperty
        propertyLabel="Modal cancel"
        translationPath={[...translationPath, 'modalCancel']}
        sourcePropertyValue={sourceAgreement.modalCancel}
        targetPropertyValue={targetAgreement.modalCancel}
      />
    </div>
  );
};

export const TranslationOptOut = pipe(
  withStylesAsClasses(styles),
  memoize(TranslationOptOutPure)
);

TranslationOptOut.propTypes = {
  propertyLabel: PropTypes.string.isRequired,
  translationPath: PropTypes.array.isRequired,
  sourcePropertyValue: PropTypes.object,
  targetPropertyValue: PropTypes.object,
};
