import { IconButton, MenuItem, Select } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import classNames from 'classnames';
import { pipe, withHandlers, withState } from 'commity-rehook-fork';
import * as PropTypes from 'prop-types';
import { omit } from 'ramda';
import React from 'react';
import { withStylesAsClasses } from '../rehooks';
import { completeLocalizationList } from './translations/translationHelper';

const styles = theme => ({
  root: {},

  menuItem: {
    display: 'flex',
    paddingRight: 0,
  },

  select: {
    width: 'calc(100% - 32px)!important',
    paddingRight: '32px!important',
  },

  languageName: {
    flex: '1 auto',
    paddingRight: theme.spacing.unit * 2,
  },

  deleteButton: {
    padding: theme.spacing.unit,
    margin: theme.spacing.unit,
  },
});

const LanguageMenuPure = ({
  classes,
  indelibleLanguages,
  onClose,
  onDeleteClick,
  onChange,
  onOpen,
  open,
  languages,
  value,
  onDelete,
  ...others
}) => {
  const hideMenu = !languages.length;
  return (
    <div {...others} className={classNames(classes.root, others.className)}>
      {!hideMenu ? (
        <Select
          classes={{ select: classNames(classes.select, others.className) }}
          open={open}
          onClose={onClose}
          onOpen={onOpen}
          value={value}
          onChange={onChange}
          renderValue={selected => completeLocalizationList[selected].localName}>
          {languages.map((languageCode, index) => (
            <MenuItem value={languageCode} key={index} className={classes.menuItem}>
              <div className={classes.languageName}>{completeLocalizationList[languageCode].localName}</div>
              {indelibleLanguages.indexOf(languageCode) === -1 && onDelete && (
                <IconButton
                  onClick={onDeleteClick(languageCode)}
                  title="Delete"
                  aria-label="Delete"
                  className={classes.deleteButton}>
                  <Delete fontSize="small" />
                </IconButton>
              )}
            </MenuItem>
          ))}
        </Select>
      ) : null}
    </div>
  );
};

export const LanguageMenu = pipe(
  withState('open', 'setOpen', false),
  withHandlers({
    onChange: ({ onSelect }) => event => {
      return onSelect(event.target.value);
    },
    onDeleteClick: ({ onDelete }) => languageCode => event => {
      event.preventDefault();
      event.stopPropagation();
      return onDelete(languageCode);
    },
    onOpen: ({ setOpen }) => () => setOpen(true),
    onClose: ({ setOpen }) => () => setOpen(false),
  }),
  withStylesAsClasses(styles),
  omit(['onSelect', 'setOpen']),
  LanguageMenuPure
);

LanguageMenu.propTypes = {
  indelibleLanguages: PropTypes.array,
  onDelete: PropTypes.func,
  onSelect: PropTypes.func.isRequired,
  languages: PropTypes.array,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
};

LanguageMenu.defaultProps = {
  indelibleLanguages: ['en_US'],
  languages: [],
  value: false,
};
