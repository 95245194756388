import { Paper } from '@material-ui/core';
import classNames from 'classnames';
import { pipe } from 'commity-rehook-fork';
import * as PropTypes from 'prop-types';
import React from 'react';
import { memoizeWithEquals, withStylesAsClasses } from '../../rehooks';
import {
  isDeletableItem,
  isIntro,
  isModelPathSelected,
  isOutro,
  isQuestionInSurvey,
  ITEM_TYPES,
} from '../questionHelper';
import { BasicQuestionForm } from './itemForms/BasicQuestionForm';
import { EscapeQuestionForm } from './itemForms/EscapeQuestionForm';
import { IntroForm } from './itemForms/IntroForm';
import { OutroForm } from './itemForms/OutroForm';
import { DraggableItemHeader } from './ItemHeader';
import { PersonalAgreementForm } from './itemForms/PersonalAgreementForm';
import { QuestionFromMasterNote } from './QuestionFromMasterNote';

const styles = (theme) => ({
  root: {},

  accent: {
    backgroundColor: theme.palette.background.selected,
  },
});

const baseForms = {
  [ITEM_TYPES.INTRO]: IntroForm,
  [ITEM_TYPES.OUTRO]: OutroForm,
  [ITEM_TYPES.ESCAPE]: EscapeQuestionForm,
  [ITEM_TYPES.PERSONAL_AGREEMENT]: PersonalAgreementForm,
};

const ItemWrapperPure = ({
  item,
  translationItem,
  flattenMasterQuestions,
  classes,
  selectedItemPath,
  modelPath,
  iconPanel,
  parentPageModelPath,
  dndRef,
  dragHandleProps,
  dndIsOver,
  dndCanDrop,
  draggableRoot,
  index,
  ...others
}) => {
  const iconPanelConfig = {
    showDeleteIcon: isDeletableItem(item),
  };

  const fromMaster = isQuestionInSurvey(item, flattenMasterQuestions);
  const readOnly = fromMaster;
  const readOnlyItem = readOnly && !isIntro(item) && !isOutro(item);

  const isSelected = isModelPathSelected(modelPath, selectedItemPath);
  const itemHeaderClass = isSelected ? classes.accent : '';
  const BaseItemForm = baseForms[item.type] || BasicQuestionForm;

  const itemFormProps = {
    item,
    readOnly,
    translationItem,
  };
  const baseItemFormProps = {
    ...itemFormProps,
    parentPageModelPath,
    modelPath,
  };

  return (
    <div {...others} ref={dndRef} className={classNames(classes.root, others.className)}>
      <DraggableItemHeader
        readOnly={readOnly}
        fromMaster={fromMaster}
        draggableRoot={draggableRoot}
        dragAndDropEnabled={!isSelected && !isIntro(item) && !isOutro(item)}
        isSelected={isSelected}
        className={itemHeaderClass}
        item={item}
        index={index}
        translationItem={translationItem}
        modelPath={modelPath}
        iconPanel={iconPanelConfig}
        parentPageModelPath={parentPageModelPath}
      />
      {isSelected && (
        <Paper square className="open-item-wrapper">
          <BaseItemForm {...baseItemFormProps} />
          {readOnlyItem && <QuestionFromMasterNote />}
        </Paper>
      )}
    </div>
  );
};

export const ItemWrapper = pipe(withStylesAsClasses(styles), memoizeWithEquals(ItemWrapperPure));

ItemWrapper.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string,
    text: PropTypes.string,
  }).isRequired,
  translationItem: PropTypes.shape({}),
  selectedItemPath: PropTypes.array,
  modelPath: PropTypes.array.isRequired,
  iconPanel: PropTypes.shape({
    showDeleteIcon: PropTypes.bool,
  }),
  flattenMasterQuestions: PropTypes.array,
  draggableRoot: PropTypes.array,
  parentPageModelPath: PropTypes.array,
  dndIsOver: PropTypes.bool,
  dndCanDrop: PropTypes.bool,
  index: PropTypes.number,
};
