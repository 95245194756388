import { ExpansionPanelDetails, Typography } from '@material-ui/core';
import classNames from 'classnames';
import { pipe } from 'commity-rehook-fork';
import * as PropTypes from 'prop-types';
import { difference, isNil, prop, reject } from 'ramda';
import React from 'react';
import { localizationList } from 'xperience-model-management';
import { withStylesAsClasses } from '../../../rehooks';
import { processTranslationErrors } from '../../../state/validations/validationHelper';

const styles = theme => ({
  root: {},

  mainErrorContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
  },

  panelDetailContainer: {
    margin: `0 ${theme.spacing.unit / 2}px ${theme.spacing.unit / 2}px ${theme.spacing.unit / 2}px`,
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px ${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    fontFamily: theme.defaultFont,
  },

  errorsHeaderTitle: {
    flex: 1,
  },

  languageName: {
    fontWeight: 'bold',
    marginBottom: theme.spacing.unit / 2,
  },
});

const getLanguageName = languageCode => localizationList[languageCode].localName;
const ValidationResultDetailsPure = ({ classes, validationResult, isEditorView, ...others }) => {
  const errorMessage = message => `- ${message}`;
  const translationErrors = reject(error => isNil(prop('language', error)), validationResult.errors);
  const processedTranslationErrors = processTranslationErrors(translationErrors);
  const notTranslationErrors = difference(validationResult.errors, translationErrors);
  return (
    <div {...others} className={classNames(classes.root, others.className)}>
      <ExpansionPanelDetails className={classes.mainErrorContainer}>
        {isEditorView && (
          <>
            {notTranslationErrors.map(({ message }, index) => {
              return (
                <fieldset key={index} className={classes.panelDetailContainer}>
                  <legend>Errors:</legend>
                  <Typography variant="body2" title={message}>
                    {errorMessage(message)}
                  </Typography>
                </fieldset>
              );
            })}
          </>
        )}
        {processedTranslationErrors.length ? (
          <fieldset className={classes.panelDetailContainer}>
            <legend>Translation Errors:</legend>
            {processedTranslationErrors.map((result, mainIndex) => {
              return (
                <div key={mainIndex}>
                  <Typography className={classes.languageName}>{getLanguageName(result.language)}:</Typography>
                  {result.messages.map((message, index) => {
                    return (
                      <Typography variant="body2" key={index} title={message}>
                        {errorMessage(message)}
                      </Typography>
                    );
                  })}
                </div>
              );
            })}
          </fieldset>
        ) : null}
      </ExpansionPanelDetails>
    </div>
  );
};

export const ValidationResultDetails = pipe(
  withStylesAsClasses(styles),
  ValidationResultDetailsPure
);

ValidationResultDetails.propTypes = {
  validationResult: PropTypes.object.isRequired,
  isEditorView: PropTypes.bool,
};
