/* eslint no-empty-function: "off" */
import { Checkbox, FormControlLabel, IconButton, TextField } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import classNames from 'classnames';
import { pipe, withHandlers } from 'commity-rehook-fork';
import * as PropTypes from 'prop-types';
import { dropLast, has, omit, values } from 'ramda';
import React from 'react';
import { DragSource, DropTarget } from 'react-dnd';
import { connect } from 'react-redux';
import { ITEM_TYPES } from 'xperience-model-management';
import { withStylesAsClasses } from '../../../../rehooks';
import { moveChoice, updateChoiceValue, updateItem } from '../../../../state/modules/survey/overview';
import { CopyPlaceholderButton } from '../../../CopyPlaceholderButton';
import { DraggableTypes, getDroppableClassName } from '../../../dndHelper';
import { createOnDeleteHandler, createTranslationOnChangeHandler } from '../../../handlerHelper';
import { InputWithState } from '../../../InputWithState';

const styles = (theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },

  text: {
    flex: 1,
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },

  value: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },

  dragHandleContainer: {
    marginLeft: theme.spacing.unit / 2,
  },

  canDrop: {
    backgroundColor: theme.palette.background.dndCanDrop,
  },
  cannotDrop: {
    backgroundColor: theme.palette.background.dndCannotDrop,
  },
  dragHandleIcon: {
    cursor: 'move',
  },
});

const ChoiceFormPure = ({
  choiceTranslation,
  choicesValues,
  choice,
  item,
  onChoiceTextChange,
  onChoiceValueChange,
  onDeleteClick,
  onDelete,
  classes,
  config,
  isOver,
  canDrop,
  connectDragSource,
  connectDropTarget,
  index,
  onHideChoiceChange,
  readOnly,

  ...others
}) => {
  const dropTargetClassName = getDroppableClassName(classes, isOver, canDrop);
  const showCheckBox = has('hidden', choice);

  return connectDropTarget(
    <div {...others} className={classNames(classes.root, others.className, dropTargetClassName)}>
      {connectDragSource(
        <div className={classNames(classes.dragHandleContainer)}>
          {!readOnly && <span className={classes.dragHandleIcon}>:::</span>}
        </div>
      )}
      <InputWithState
        id="value"
        label="Value"
        className={classes.value}
        type="number"
        value={choice.value}
        invalidValues={choicesValues}
        onBlur={onChoiceValueChange}
        margin="normal"
        readOnly={readOnly}
      />
      <TextField
        id="text"
        label="Text"
        multiline
        className={classes.text}
        value={choiceTranslation && choiceTranslation.text}
        onChange={onChoiceTextChange}
        margin="normal"
        disabled={readOnly}
      />
      <CopyPlaceholderButton />
      {showCheckBox && (
        <FormControlLabel
          labelPlacement="top"
          control={
            <Checkbox checked={choice.hidden} color="primary" onChange={onHideChoiceChange} title="Hidden choice" />
          }
          label="Hidden"
        />
      )}
      {config.deleteChoice && (
        <IconButton
          aria-label="Delete"
          title="Delete Choice"
          onClick={onDeleteClick}
          disabled={readOnly}
          className={classes.margin}>
          <DeleteIcon fontSize="small" />
        </IconButton>
      )}
    </div>
  );
};

const StyledChoiceForm = pipe(
  withHandlers({
    onChoiceValueChange: ({ modelPath, item, onValueChange }) => (newValue) => {
      return onValueChange({
        modelPath,
        itemId: item.id,
        newValue,
      });
    },
    onHideChoiceChange: ({ modelPath, onHideChoiceToggle }) => (event) => {
      return onHideChoiceToggle({
        itemPath: [...modelPath, 'hidden'],
        value: event.target.checked,
      });
    },
    onChoiceTextChange: createTranslationOnChangeHandler(['text']),
    onDeleteClick: createOnDeleteHandler,
  }),
  withStylesAsClasses(styles),
  omit([
    'onChange',
    'onDelete',
    'modelPath',
    'translationPath',
    'onTranslationChange',
    'parentPageModelPath',
    'isDragging',
    'onValueChange',
    'onHideChoiceToggle',
  ]),
  ChoiceFormPure
);

const getDraggableRoot = dropLast(1);

const droppableTarget = {
  drop: (props, monitor) => {
    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.index;

    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      return;
    }
    props.onDrop({
      destination: {
        index: hoverIndex,
        draggableRoot: getDraggableRoot(props.modelPath),
      },
      source: {
        index: dragIndex,
        draggableRoot: monitor.getItem().draggableRoot,
      },
    });
  },
};

const droppableCollect = (dndConnect, monitor) => {
  return {
    connectDropTarget: dndConnect.dropTarget(),
    canDrop: monitor.canDrop(),
    isOver: monitor.isOver(),
  };
};

const draggableSource = {
  canDrag: ({ readOnly }) => !readOnly,
  beginDrag({ index, modelPath, item }) {
    return {
      index,
      draggableRoot: getDraggableRoot(modelPath),
      item,
    };
  },
};

const draggableCollect = (dndConnect, monitor) => {
  return {
    connectDragSource: dndConnect.dragSource(),
    isDragging: monitor.isDragging(),
  };
};

const DroppableChoiceForm = DropTarget(
  DraggableTypes.CHOICE,
  droppableTarget,
  droppableCollect
)(DragSource(DraggableTypes.CHOICE, draggableSource, draggableCollect)(StyledChoiceForm));

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  onValueChange: ({ modelPath, itemId, newValue }) => dispatch(updateChoiceValue(modelPath, itemId, newValue)),
  onHideChoiceToggle: ({ itemPath, value }) => dispatch(updateItem(itemPath, value)),
  onDrop: (result) => {
    if (!result.destination) {
      // dropped outside the list
      return;
    }
    dispatch(moveChoice(result));
  },
});

export const DraggableChoiceForm = connect(mapStateToProps, mapDispatchToProps)(DroppableChoiceForm);

DraggableChoiceForm.propTypes = {
  choiceTranslation: PropTypes.shape({
    text: PropTypes.string.isRequired,
  }),
  item: PropTypes.shape({
    type: PropTypes.oneOf(values(ITEM_TYPES)),
  }),
  choicesValues: PropTypes.array,
  choice: PropTypes.shape({
    value: PropTypes.number.isRequired,
  }).isRequired,
  modelPath: PropTypes.array.isRequired,
  onChange: PropTypes.func,
  onTranslationChange: PropTypes.func,
  onDelete: PropTypes.func,
  config: PropTypes.shape({
    addChoice: PropTypes.bool,
    deleteChoice: PropTypes.bool,
  }),
  readOnly: PropTypes.bool,
};
