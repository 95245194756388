import { Button, IconButton, Paper, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import classNames from 'classnames';
import { pipe, withHandlers, withStateHandlers } from 'commity-rehook-fork';
import { append, dissoc } from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { memoize, withStylesAsClasses } from '../../rehooks';
import {
  getSelectedSourceLanguage,
  getSelectedTargetLanguage,
  isMaster,
  selectSourceLanguage,
  selectTargetLanguage,
} from '../../state/modules/editor';
import { addNewLanguage, deleteLanguage, getSurveyLanguages } from '../../state/modules/survey/translations';
import { ConfirmDialog } from '../dialogs/ConfirmDialog';
import { LanguageMenu } from '../LanguageMenu';
import { LocaleSelect } from '../LocaleSelect';
import { masterEnUSLocalization } from './translationHelper';

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    minHeight: '52px',
    cursor: 'default',
    marginBottom: theme.spacing.unit / 4,
  },

  toolbarItem: {
    margin: theme.spacing.unit,
  },

  divider: {
    width: theme.spacing.unit * 4,
  },

  localeSelect: {
    minWidth: 180,
    maxWidth: 300,
    flex: 1,
    marginLeft: theme.spacing.unit,
  },

  closeButton: {
    padding: theme.spacing.unit,
    margin: theme.spacing.unit,
  },
});

const getIndelibleSourceLanguage = (hasMasterSurvey, sourceLanguages) => {
  let indelibleLanguages = [];
  if (hasMasterSurvey) {
    indelibleLanguages = append(masterEnUSLocalization.code, indelibleLanguages);
  }
  if (sourceLanguages.length === 1) {
    indelibleLanguages = append(sourceLanguages[0], indelibleLanguages);
  }
  return indelibleLanguages;
};

const getSourceLanguages = (hasMasterSurvey, sourceLanguages) => {
  return hasMasterSurvey ? [masterEnUSLocalization.code, ...sourceLanguages] : sourceLanguages;
};

const getIndelibleTargetLanguage = surveyLanguages => (surveyLanguages.length === 1 ? surveyLanguages : []);

const TranslationToolbarPure = ({
  classes,
  surveyLanguages,
  selectedSourceLanguage,
  selectedTargetLanguage,
  onSelectSourceLanguage,
  onSelectTargetLanguage,
  onDeleteLanguage,
  deleteStateLanguage,
  addingNewLocale,
  onAddNewLanguage,
  openNewLocale,
  closeNewLocale,
  onNewLocaleSelect,
  openConfirmDialog,
  onCloseConfirmDialog,
  showConfirmDialog,
  clearDeleteLanguage,
  hasMasterSurvey,
  ...others
}) => {
  const ConfirmDeleteDialog = () => {
    const dialogTitleText = 'Do you really want delete language?';
    const dialogContentText = 'All translations in this language will been deleted!';

    return (
      <ConfirmDialog
        onYesClick={onDeleteLanguage(deleteStateLanguage, clearDeleteLanguage)}
        dialogOpen={openConfirmDialog}
        onDialogClose={onCloseConfirmDialog}
        dialogTitle={dialogTitleText}
        dialogContent={dialogContentText}
      />
    );
  };
  return (
    <Paper {...others} className={classNames(classes.root, others.className)}>
      <Typography variant="subtitle2" className={classes.toolbarItem}>
        Source Language:
      </Typography>
      <LanguageMenu
        languages={getSourceLanguages(hasMasterSurvey, surveyLanguages)}
        indelibleLanguages={getIndelibleSourceLanguage(hasMasterSurvey, surveyLanguages)}
        value={selectedSourceLanguage}
        onSelect={onSelectSourceLanguage}
        onDelete={showConfirmDialog}
      />
      <div className={classes.divider} />
      <Typography variant="subtitle2" className={classes.toolbarItem}>
        Target Language:
      </Typography>
      <LanguageMenu
        languages={surveyLanguages}
        indelibleLanguages={getIndelibleTargetLanguage(surveyLanguages)}
        value={selectedTargetLanguage}
        onSelect={onSelectTargetLanguage}
        onDelete={showConfirmDialog}
      />
      <ConfirmDeleteDialog />
      <div className={classes.divider} />
      {hasMasterSurvey && !addingNewLocale && (
        <Button
          variant="outlined"
          size="small"
          color="primary"
          title="Add Translation"
          className={classes.toolbarItem}
          onClick={openNewLocale}>
          Add Translation
        </Button>
      )}
      {addingNewLocale && (
        <>
          <Typography variant="subtitle2" className={classes.toolbarItem}>
            Add Translation:
          </Typography>
          <LocaleSelect
            className={classes.localeSelect}
            autoFocus
            onSelect={onNewLocaleSelect}
            omitLocales={surveyLanguages}
          />
          <IconButton onClick={closeNewLocale} title="Close" className={classes.closeButton}>
            <Close fontSize="small" />
          </IconButton>
        </>
      )}
    </Paper>
  );
};

const TranslationToolbarWithStyles = pipe(
  withStateHandlers(
    { addingNewLocale: false, openConfirmDialog: false },
    {
      openNewLocale: () => () => ({ addingNewLocale: true }),
      closeNewLocale: () => () => ({ addingNewLocale: false }),
      showConfirmDialog: () => deleteStateLanguage => ({ openConfirmDialog: true, deleteStateLanguage }),
      onCloseConfirmDialog: () => () => ({ openConfirmDialog: false }),
      clearDeleteLanguage: state => () => dissoc('deleteStateLanguage', state),
    }
  ),
  withHandlers({
    onNewLocaleSelect: ({ closeNewLocale, onAddNewLanguage }) => selectedLanguage => {
      onAddNewLanguage(selectedLanguage);
      closeNewLocale();
    },
  }),
  withStylesAsClasses(styles),
  memoize(TranslationToolbarPure)
);

const mapStateToProps = state => ({
  hasMasterSurvey: !isMaster(state),
  surveyLanguages: getSurveyLanguages(state),
  selectedSourceLanguage: getSelectedSourceLanguage(state),
  selectedTargetLanguage: getSelectedTargetLanguage(state),
});

const mapDispatchToProps = dispatch => {
  return {
    onAddNewLanguage: newLanguage => {
      dispatch(addNewLanguage(newLanguage));
    },
    onSelectSourceLanguage: language => {
      dispatch(selectSourceLanguage(language));
    },
    onSelectTargetLanguage: language => {
      dispatch(selectTargetLanguage(language));
    },
    onDeleteLanguage: (language, clearDeleteLanguage) => () => {
      dispatch(deleteLanguage(language));
      clearDeleteLanguage();
    },
  };
};

export const TranslationToolbar = connect(
  mapStateToProps,
  mapDispatchToProps
)(TranslationToolbarWithStyles);

TranslationToolbar.propTypes = {};
