import {
  always,
  applySpec,
  has,
  hasPath,
  lensPath,
  lensProp,
  map,
  match,
  over,
  pipe,
  prop,
  replace,
  split,
  trim,
  when,
} from 'ramda';
import { isNotString, reduceIndexed } from 'ramda-adjunct';

const getTypeFunction = applySpec({
  type: always('function'),
  operation: pipe(prop('operation'), trim),
  args: prop('args'),
});

const getTypeVariable = applySpec({
  type: always('variable'),
  value: trim,
});

const transformToExpressions = reduceIndexed((acc, val, idx) => {
  if (idx === 0) {
    return getTypeVariable(val);
  }
  return getTypeFunction({
    operation: val,
    args: [acc],
  });
}, {});

const EXPRESSION_REGEX = /(\$\{[^}]*\})/gm;

export const getExpressionsFromString = (string) => match(EXPRESSION_REGEX, string);

const removeUnnecessaryCharacters = replace('${', '');
const splitIntoFunctions = split('|');

export const transformExpression = pipe(
  match(/\${[^}]*/g),
  map(pipe(removeUnnecessaryCharacters, splitIntoFunctions, transformToExpressions))
);

export const textToExpressions = (string) => {
  if (isNotString(string)) {
    return '';
  }
  const expressions = getExpressionsFromString(string);
  if (expressions.length === 0) {
    return string;
  }
  const splitIntoExpression = split(EXPRESSION_REGEX);
  const transformedExpressions = transformExpression(string);

  const transformIfIsExpression = (item) => {
    const index = expressions.indexOf(item);
    if (index !== -1) {
      return transformedExpressions[index];
    }
    return item;
  };

  return map(transformIfIsExpression, splitIntoExpression(string));
};

export const EXPRESSIONS_PROP_PATHS = {
  TEXT: ['text'],
  INTRODUCTION: ['introduction'],
  AGREEMENT_INSTRUCTION: ['agreement', 'instruction'],
};

export const transformSurveyTextsToExpressions = (survey) => {
  const lensText = lensProp('text');
  const lensIntroduction = lensProp('introduction');
  const lensTranslations = lensProp('translations');
  const lensChoices = lensProp('choices');
  const lensInstruction = lensPath(['agreement', 'instruction']);
  const hasText = has('text');
  const hasChoices = has('choices');
  const hasIntroduction = has('introduction');
  const hasInstruction = hasPath(EXPRESSIONS_PROP_PATHS.AGREEMENT_INSTRUCTION);
  const replaceTextToExpression = pipe(
    when(hasText, over(lensText, textToExpressions)),
    when(hasChoices, over(lensChoices, map(over(lensText, textToExpressions)))),
    when(hasIntroduction, over(lensIntroduction, textToExpressions)),
    when(hasInstruction, over(lensInstruction, textToExpressions))
  );
  const transformAllQuestions = map(replaceTextToExpression);
  const transformAllTranslations = map(transformAllQuestions);
  const transformationOverTranslations = over(lensTranslations, transformAllTranslations);

  return transformationOverTranslations(survey);
};
