import { Button } from '@material-ui/core';
import { TouchApp } from '@material-ui/icons';
import classNames from 'classnames';
import { pipe, withHandlers } from 'commity-rehook-fork';
import * as PropTypes from 'prop-types';
import { omit, prop } from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { withStylesAsClasses } from '../../rehooks';
import { selectItem } from '../../state/modules/editor';
import { getSurveyWithSelectedTranslations } from '../../state/modules/survey/overview/index';
import { isModelPathSelected } from '../questionHelper';
import { findTranslationItemInCurrentTranslation } from '../translations/translationHelper';

const styles = ({ palette, spacing }) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: palette.background.pageHeader,
    opacity: 0.8,
    borderRadius: 0.2,
    color: palette.primary.dark,
    padding: '0 34px',
    width: '100%',
    minHeight: 42,
    borderTop: '1px solid black',

    '&:hover': {
      backgroundColor: palette.background.pageHeader,
      opacity: 0.7,
    },
  },

  selectedHeader: {
    backgroundColor: palette.background.selected,

    '&:hover': {
      backgroundColor: palette.background.selected,
      opacity: 0.7,
    },
  },

  headerName: {
    textTransform: 'none',
  },
});

const PageSelectablePartPure = ({
  onClick,
  classes,
  modelPath,
  selectedItemPath,
  translationItem,
  item,
  ...others
}) => {
  const isSelected = isModelPathSelected(modelPath, selectedItemPath);
  const pageTitle = prop('title', translationItem);

  return (
    <Button
      onClick={onClick}
      {...others}
      className={classNames(classes.root, isSelected && classes.selectedHeader, others.className)}>
      <span className={classes.headerName}>
        {item.id && `${item.id}. `}
        {pageTitle}
      </span>
      <TouchApp />
    </Button>
  );
};

const mapStateToProps = (state, { item }) => ({
  translationItem: findTranslationItemInCurrentTranslation(getSurveyWithSelectedTranslations(state), item),
});
const mapDispatchToProps = (dispatch) => ({
  onSelect: (itemPath) => {
    dispatch(selectItem(itemPath));
  },
});

const Styled = pipe(
  withHandlers({
    onClick: ({ modelPath, onSelect, selectedItemPath }) => () => {
      const isSelected = isModelPathSelected(modelPath, selectedItemPath);

      onSelect(isSelected ? null : modelPath);
    },
  }),
  withStylesAsClasses(styles),
  omit(['onSelect']),
  PageSelectablePartPure
);

export const PageSelectablePart = connect(mapStateToProps, mapDispatchToProps)(Styled);

PageSelectablePart.propTypes = {
  modelPath: PropTypes.array.isRequired,
  selectedItemPath: PropTypes.array,
  item: PropTypes.shape({
    id: PropTypes.string,
  }),
};
