import classNames from 'classnames';
import { pipe, withProps } from 'commity-rehook-fork';
import * as PropTypes from 'prop-types';
import { has } from 'ramda';
import React from 'react';
import { translationPropsByElementTypes } from 'xperience-model-management';
import { memoizeWithEquals, withStylesAsClasses } from '../../rehooks';
import { TranslationAgreement } from './propertyComponents/TranslationAgreement';
import { TranslationChoices } from './propertyComponents/TranslationChoices';
import { TranslationProperty } from './propertyComponents/TranslationProperty';
import { translationPropertyLabels } from './translationHelper';
import { TranslationOptOut } from './propertyComponents/TranslationOptOut';
import { TranslationConditionalTexts } from './propertyComponents/TranslationConditionalTexts';
import { TranslationRedirect } from './propertyComponents/TranslationRedirect';

const styles = (theme) => ({
  root: { paddingBottom: theme.spacing.unit * 2 },
});

const propertyComponents = {
  agreement: TranslationAgreement,
  optOut: TranslationOptOut,
  choices: TranslationChoices,
  conditionalTexts: TranslationConditionalTexts,
  redirect: TranslationRedirect,
};

const getPropertyComponent = (propertyName) => propertyComponents[propertyName] || TranslationProperty;

const TranslationItemPure = ({
  inlineItem,
  translationPath,
  sourceTranslationItem,
  targetTranslationItem,
  propertiesToTranslate,
  propertyLabels,
  classes,
  ...others
}) => {
  return (
    <div {...others} className={classNames(classes.root, others.className)}>
      {propertiesToTranslate &&
        propertiesToTranslate.map((propertyName, index) => {
          const PropertyComponent = getPropertyComponent(propertyName);
          const propertyProps = {
            key: index,
            propertyLabel: propertyLabels[propertyName],
            translationPath: [...translationPath, propertyName],
            sourcePropertyValue: sourceTranslationItem && sourceTranslationItem[propertyName],
            targetPropertyValue: targetTranslationItem && targetTranslationItem[propertyName],
          };
          if (propertyName === 'conditionalTexts') {
            propertyProps.conditionalTextsDefinition = inlineItem.conditionalTexts;
          }
          if (propertyName === 'choices') {
            propertyProps.choicesDefinition = inlineItem.choices;
          }
          if (propertyName === 'text') {
            propertyProps.showPlaceholderButton = true;
          }
          return has(propertyName, sourceTranslationItem) && <PropertyComponent {...propertyProps} />;
        })}
    </div>
  );
};

export const TranslationItem = pipe(
  withProps(({ inlineItem }) => {
    return {
      propertiesToTranslate: translationPropsByElementTypes[inlineItem.type],
      propertyLabels: translationPropertyLabels,
    };
  }),
  withStylesAsClasses(styles),
  memoizeWithEquals(TranslationItemPure)
);

TranslationItem.propTypes = {
  inlineItem: PropTypes.object,
  translationPath: PropTypes.array,
  sourceTranslationItem: PropTypes.object,
  targetTranslationItem: PropTypes.object,
  propertiesToTranslate: PropTypes.array,
};
