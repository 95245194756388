import * as PropTypes from 'prop-types';
import React from 'react';
import { ExpressionArgumentSelect, valuePropType } from './ExpressionArgumentSelect';

export const QuestionSelect = props => <ExpressionArgumentSelect allowCreateValues={false} {...props} />;

QuestionSelect.propTypes = {
  options: PropTypes.arrayOf(valuePropType).isRequired,
  onSelect: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([valuePropType, PropTypes.bool]),
};
QuestionSelect.defaultProps = {
  value: false,
  disabled: false,
};
