import {
  any,
  complement,
  equals,
  filter,
  groupBy,
  gte,
  has,
  hasPath,
  head,
  isEmpty,
  last,
  lte,
  map,
  path,
  pick,
  pipe,
  pluck,
  prop,
  sortBy,
  takeWhile,
  values,
} from 'ramda';
import isNil from 'ramda/src/isNil';
import { createError } from './errors';

export const VALIDATION_STATUS = {
  VALID: 'valid',
  INVALID: 'invalid',
  PENDING: 'pending',
};

export const STAR_RATING_RANGE = [1, 5];
export const SQUARE_RATING_RANGE = [0, 10];
export const DYNAMIC_SQUARE_RATING_RANGE = [1, 10];

const defaultErrorMsgFun = (property) => `required field "${property}" is not filled.`;

export const checkRequiredPropertiesOnObject = ({
  item,
  itemPath,
  requiredProps,
  validatorName,
  language,
  errorMsgFun = defaultErrorMsgFun,
}) => {
  return pipe(
    map((property) => {
      if (!item || isUndefinedOrEmpty(item, property)) {
        return createError({
          message: errorMsgFun(property),
          validator: validatorName,
          itemPath,
          language,
        });
      }
      return null;
    }),
    filter(Boolean)
  )(requiredProps);
};

export const isUndefinedOrEmpty = (item, property) => {
  return !has(property, item) || isEmpty(item[property]) || isNil(item[property]);
};

export const isPathUndefinedOrEmpty = (item, propPath) => {
  const value = path(propPath, item);
  return !hasPath(propPath, item) || isEmpty(value) || isNil(value);
};

export const createValidatorProps = ({
  surveyDefinition = [],
  placeholders = [],
  translations = {},
  masterDefinition = [],
}) => ({
  survey: {
    definition: surveyDefinition,
  },
  placeholders,
  translations,
  masterSurvey: {
    definition: masterDefinition,
  },
});

export const isValueInRange = (range) => (value) => gte(value, head(range)) && lte(value, last(range));

export const anyValueInRange = (vals, range) => any(isValueInRange(range))(vals);

export const getResultsItemPaths = pluck('itemPath');

const getErrors = pluck('error');

const getIdFromItemPath = (result, surveyDefinition) => {
  const itemPathOnly = takeWhile(complement(equals('choices')), result.itemPath);
  return path(itemPathOnly, surveyDefinition).id;
};

export const processValidationResults = (validationResults, surveyDefinition) => {
  return pipe(
    prop('results'),
    map((result) => {
      return {
        id: result.itemPath ? getIdFromItemPath(result, surveyDefinition) : result.itemId,
        error: pick(['message', 'validatorName', 'language'], result),
      };
    }),
    groupBy(prop('id')),
    values,
    map((result) => {
      return {
        id: head(result).id,
        errors: getErrors(result),
      };
    })
  )(validationResults);
};

export const processTranslationErrors = (translationErrors) => {
  return pipe(
    sortBy(prop('language')),
    groupBy(prop('language')),
    values,
    map((result) => {
      return {
        language: head(result).language,
        messages: pluck('message', result),
      };
    })
  )(translationErrors);
};
