import { assoc, lensPath, over, pipe } from 'ramda';

const introLens = lensPath(['definition', 0]);

const processIntroDefinition = pipe(
  assoc('optional', true),
  assoc('imagePath', { type: 'variable', value: 'carImage' })
);

export const introSpecifics = survey => over(introLens, processIntroDefinition, survey);
