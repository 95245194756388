import { always, applySpec, concat, map, pipe, prop } from 'ramda';

const createLabelForSelect = pipe(
  prop('name'),
  concat('Value from: ')
);

export const transformPlaceholderForSelect = map(
  applySpec({
    value: prop('name'),
    type: always('variable'),
    dataType: prop('type'),
    label: createLabelForSelect,
  })
);
