import { TextField } from '@material-ui/core';
import classNames from 'classnames';
import { pipe, withHandlers } from 'commity-rehook-fork';
import * as PropTypes from 'prop-types';
import { omit } from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { memoize, withStylesAsClasses } from '../../../rehooks';
import { getSelectedSourceLanguage, getSelectedTargetLanguage } from '../../../state/modules/editor';
import { updateTranslation } from '../../../state/modules/survey/translations';
import { CopyPlaceholderButton } from '../../CopyPlaceholderButton';
import { translationOnChangeHandler } from '../../handlerHelper';
import { completeLocalizationList } from '../translationHelper';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    margin: `0 ${theme.spacing.unit}px ${theme.spacing.unit * 2}px ${theme.spacing.unit}px`,
    padding: theme.spacing.unit,
  },
  textField: {
    marginBottom: theme.spacing.unit,
  },
  targetTextField: {
    flex: 1,
  },
  disabledTextColor: {
    color: theme.palette.primary.A800,
  },

  disabledLabelColor: {
    color: `${theme.palette.primary.A900}!important`,
  },
  targetContainer: {
    display: 'flex',
  },
});

const TranslationPropertyPure = ({
  propertyLabel,
  translationPath,
  sourceLanguage,
  targetLanguage,
  sourcePropertyValue,
  targetPropertyValue,
  onPropertyChange,
  showPlaceholderButton,
  customPlaceholder,
  classes,
  ...others
}) => {
  return (
    <div {...others} className={classNames(classes.root, others.className)}>
      <TextField
        dir={completeLocalizationList[sourceLanguage].directionality}
        disabled
        InputLabelProps={{
          classes: {
            disabled: classes.disabledLabelColor,
          },
        }}
        InputProps={{
          disableUnderline: true,
          classes: {
            input: classes.disabledTextColor,
          },
        }}
        className={classes.textField}
        label={propertyLabel}
        value={sourcePropertyValue}
      />
      <div className={classes.targetContainer}>
        <TextField
          dir={completeLocalizationList[targetLanguage].directionality}
          className={classNames(classes.textField, classes.targetTextField)}
          label="Translation"
          onChange={onPropertyChange}
          value={targetPropertyValue || ''}
        />
        {showPlaceholderButton && <CopyPlaceholderButton customPlaceholder={customPlaceholder} />}
      </div>
    </div>
  );
};

const TranslationPropertyWithStyles = pipe(
  withHandlers({
    onPropertyChange: translationOnChangeHandler,
  }),
  omit(['onChange']),
  withStylesAsClasses(styles),
  memoize(TranslationPropertyPure)
);

const mapStateToProps = state => {
  return {
    sourceLanguage: getSelectedSourceLanguage(state),
    targetLanguage: getSelectedTargetLanguage(state),
  };
};

const mapDispatchToProps = dispatch => ({
  onChange: ({ translationPath, value }) => {
    dispatch(updateTranslation(translationPath, value));
  },
});

export const TranslationProperty = connect(
  mapStateToProps,
  mapDispatchToProps
)(TranslationPropertyWithStyles);

TranslationProperty.propTypes = {
  propertyLabel: PropTypes.string.isRequired,
  translationPath: PropTypes.array.isRequired,
  sourcePropertyValue: PropTypes.string,
  targetPropertyValue: PropTypes.string,
  showPlaceholderButton: PropTypes.bool,
  customPlaceholder: PropTypes.array,
};
