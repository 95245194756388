import { Paper, Typography } from '@material-ui/core';
import classNames from 'classnames';
import { pipe } from 'commity-rehook-fork';
import * as PropTypes from 'prop-types';
import React from 'react';
import { isQuestion, isPage } from 'xperience-model-management';
import { withStylesAsClasses } from '../../rehooks';
import { ITEM_TYPES } from '../questionHelper';
import { EscapeQuestionOI } from './itemProperties/EscapeQuestionOI';
import { IntroOI } from './itemProperties/IntroOI';
import { TextQuestionOI } from './itemProperties/TextQuestionOI';
import { PersonalAgreementOI } from './itemProperties/PersonalAgreementOI';
import { QuestionTypesOI } from './itemProperties/QuestionTypesOI';
import { DynamicSquareQuestionOI } from './itemProperties/DynamicSquareQuestionOI';
import { OutroOI } from './itemProperties/OutroOI';
import { PageTypesOI } from './itemProperties/PageTypesOI';

const styles = (theme) => ({
  root: {},
  heading: {
    padding: theme.spacing.unit,
  },
});

const propertiesForms = {
  [ITEM_TYPES.INTRO]: IntroOI,
  [ITEM_TYPES.ESCAPE]: EscapeQuestionOI,
  [ITEM_TYPES.PERSONAL_AGREEMENT]: PersonalAgreementOI,
  [ITEM_TYPES.TEXT]: TextQuestionOI,
  [ITEM_TYPES.DYNAMIC_SQUARE_RATING]: DynamicSquareQuestionOI,
  [ITEM_TYPES.OUTRO]: OutroOI,
};

const getItemForm = (questionType) => propertiesForms[questionType] || false;

const ItemPropertiesPure = ({ item, classes, readOnly, ...others }) => {
  const ItemForm = getItemForm(item.type);
  const QuestionForm = isQuestion(item) ? QuestionTypesOI : false;
  const PageForm = isPage(item) ? PageTypesOI : false;
  const showPropertiesPanel = ItemForm || QuestionForm || PageForm;

  return (
    showPropertiesPanel && (
      <div {...others} className={classNames(classes.root, others.className)}>
        <Paper square>
          <Typography variant="h5" component="h3" className={classes.heading}>
            Item Properties
          </Typography>
          {ItemForm && <ItemForm item={item} readOnly={readOnly} />}
          {QuestionForm && <QuestionForm item={item} readOnly={readOnly} />}
          {PageForm && <PageForm item={item} readOnly={readOnly} />}
        </Paper>
      </div>
    )
  );
};

export const ItemProperties = pipe(withStylesAsClasses(styles), ItemPropertiesPure);

ItemProperties.propTypes = {
  item: PropTypes.shape({
    type: PropTypes.string,
  }),
  readOnly: PropTypes.bool,
};
