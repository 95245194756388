import React from 'react';
import { CrashErrorDialog } from './components/CrashErrorDialog';

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, errorMsgHeader: null, errorMessage: null };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true, errorMsgHeader: error.toString(), errorMessage: info.componentStack });
  }

  render() {
    if (this.state.hasError) {
      return (
        <CrashErrorDialog
          open={this.state.hasError}
          errorMsgHeader={this.state.errorMsgHeader}
          errorMessage={this.state.errorMessage}
          onOkClick={() => this.setState({ hasError: false, errorMsgHeader: null, errorMessage: null })}
        />
      );
    }
    return this.props.children;
  }
}
