import { makeStyles } from '@material-ui/styles';
import { equals } from 'ramda';
import React from 'react';

/**
 * convert styles to props classes - object with c
 * @param {object | function} styles
 * @returns {function}
 */
export const withStylesAsClasses = styles => {
  const getClasses = makeStyles(styles);

  return props => ({
    ...props,
    classes: getClasses(),
  });
};

export const memoize = Component => {
  const MemoizedComponent = React.memo(Component);

  return props => <MemoizedComponent {...props} />;
};

export const memoizeWithEquals = Component => {
  const MemoizedComponent = React.memo(Component, equals);

  return props => <MemoizedComponent {...props} />;
};
