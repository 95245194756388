import { TextField } from '@material-ui/core';
import classNames from 'classnames';
import { pipe, withHandlers } from 'commity-rehook-fork';
import * as PropTypes from 'prop-types';
import { omit } from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { memoizeWithEquals, withStylesAsClasses } from '../../../rehooks';
import { getTranslationPathForItem, updateTranslation } from '../../../state/modules/survey/translations';
import { createTranslationOnChangeHandler } from '../../handlerHelper';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing.unit,
  },
  textField: {},
});

/**
 * const model = {
  id: "QE", // povinný unikátní identifikátor
  type: "escape",
  thanksMessage: "Thank you! Your answers help us improve our services.", // text

  continueText: "We would like to ask you several more questions.", // text
  terminateText: "We would like to ask you several more questions.", // text
  continueButtonText: "Next question »", // text
  terminateButtonText: "Ukončit" // text
};
 */

const EscapeQuestionFormPure = ({ item, translationItem, onThanksMessageChange, classes, readOnly, ...others }) => {
  return (
    <div {...others} className={classNames(classes.root, others.className)}>
      <TextField
        id="thanksMessage"
        label="Thanks Message"
        placeholder="... insert text"
        multiline
        value={translationItem && translationItem.thanksMessage}
        onChange={onThanksMessageChange}
        className={classes.textField}
        margin="normal"
        disabled={readOnly}
      />
    </div>
  );
};

const StyledEscapeQuestionForm = pipe(
  withHandlers({
    onThanksMessageChange: createTranslationOnChangeHandler(['thanksMessage']),
  }),
  withStylesAsClasses(styles),
  omit(['parentPageModelPath', 'modelPath', 'onTranslationChange', 'translationPath']),
  memoizeWithEquals(EscapeQuestionFormPure)
);

const mapStateToProps = (state, { item }) => ({
  translationPath: getTranslationPathForItem(state, item),
});

const mapDispatchToProps = (dispatch) => {
  return {
    onTranslationChange: ({ translationPath, value }) => {
      dispatch(updateTranslation(translationPath, value));
    },
  };
};

export const EscapeQuestionForm = connect(mapStateToProps, mapDispatchToProps)(StyledEscapeQuestionForm);

EscapeQuestionForm.propTypes = {
  item: PropTypes.shape({}),
  readOnly: PropTypes.bool,
};
