/* eslint no-shadow: "off" */
import classNames from 'classnames';
import * as PropTypes from 'prop-types';
import { any, flatten, omit, pathOr, pipe, pluck, prop } from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { withStylesAsClasses } from '../rehooks';
import {
  EDITOR_VIEW,
  getMasterSurvey,
  getSelectedEditorView,
  getSelectedItemPath,
  isMaster,
} from '../state/modules/editor';
import { getSelectedItem, getSurveyWithSelectedTranslations } from '../state/modules/survey/overview';
import { getValidationResults } from '../state/modules/validations';
import { QuestionsDatabankDrawer } from './databank/QuestionsDatabankDrawer';
import { EditorToolbar } from './EditorToolbar';
import { ObjectInspector } from './objectInspector/ObjectInspector';
import { SurveyOverview } from './surveyOverview/SurveyOverview';
import { TranslationEditor } from './TranslationEditor';
import { TranslationRightPanel } from './translations/TranslationRightPanel';

const styles = (theme) => ({
  root: {
    height: '100vh',
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'column',
    overflow: 'auto',
  },

  toolbar: {
    zIndex: theme.zIndex.drawer + 1,
  },

  componentContainer: {
    display: 'flex',
    flexDirection: 'row',
    overflow: 'auto',
  },

  centerPanel: {
    flex: '0.75',
    alignSelf: 'flex-start',
    height: '100%',
    overflow: 'auto',
  },

  centerPanelWithoutRightPanel: {
    display: 'flex',
    flex: 1,
  },

  rightPanel: {
    flex: '0.25',
  },

  surveyOverview: {
    flex: 1,
    width: '100%',
  },

  flex: {
    flex: 1,
  },

  translationEditor: {
    display: 'flex',
    overflow: 'auto',
  },
});

const getResults = pathOr([], ['results']);
const showTranslationValidationPanel = (validationResults) =>
  pipe(getResults, pluck('errors'), flatten, any(prop('language')))(validationResults);

const EditorPure = ({
  classes,
  survey,
  selectedItemPath,
  selectedItem,
  selectedEditorView,
  hasMasterSurvey,
  masterSurvey,
  validationResults,
  onFullscreenChange,
  fullscreen,

  ...others
}) => {
  const isEditorView = selectedEditorView === EDITOR_VIEW.SURVEY_EDITOR;
  const translationClass = showTranslationValidationPanel(validationResults)
    ? classes.centerPanel
    : classes.centerPanelWithoutRightPanel;

  return (
    <div {...others} className={classNames(classes.root, others.className)}>
      <EditorToolbar onFullscreenChange={onFullscreenChange} fullscreen={fullscreen} className={classes.toolbar} />
      <div className={classes.componentContainer}>
        {isEditorView ? (
          <>
            {hasMasterSurvey && (
              <div>
                <QuestionsDatabankDrawer open={hasMasterSurvey} />
              </div>
            )}
            <div className={classes.centerPanel}>
              <SurveyOverview className={classes.surveyOverview} survey={survey} selectedItemPath={selectedItemPath} />
            </div>
            <div className={classes.rightPanel}>
              <ObjectInspector
                className={classes.flex}
                isMaster={!hasMasterSurvey}
                masterSurvey={masterSurvey}
                item={selectedItem}
                isEditorView={isEditorView}
              />
            </div>
          </>
        ) : (
          <>
            <div className={translationClass}>
              <TranslationEditor className={classes.translationEditor} />
            </div>
            {showTranslationValidationPanel(validationResults) && (
              <div className={classes.rightPanel}>
                <TranslationRightPanel className={classes.flex} item={selectedItem} isEditorView={isEditorView} />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  survey: getSurveyWithSelectedTranslations(state),
  selectedItemPath: getSelectedItemPath(state),
  selectedItem: getSelectedItem(state),
  selectedEditorView: getSelectedEditorView(state),
  hasMasterSurvey: !isMaster(state), // detekce existence master dotazníku - mohla by jít i přes Bool(getMasterSurvey(state))... ale když už máme tu isMaster property v reduxu ;) ... najednou mi to isMaster přijde lehce zbytečné ;)
  masterSurvey: getMasterSurvey(state),
  validationResults: getValidationResults(state),
});

const EditorWithStyles = pipe(withStylesAsClasses(styles), omit(['dispatch']), EditorPure);

export const Editor = connect(mapStateToProps)(EditorWithStyles);

Editor.propTypes = {
  onFullscreenChange: PropTypes.func,
  fullscreen: PropTypes.bool,
};
